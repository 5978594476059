import { Routes } from '@angular/router';
import { BandEnum } from './shared/enums/band.enum';
import { RouterEnum } from './shared/enums/router.enum';
import { AuthGuard } from './core/services/guards/auth-guard.service';

export const routes: Routes = [
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/user/pages/login/login.page').then((m) => m.LoginPage),
  },
  {
    path: 'forgot-password',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/user/pages/forgot-password/forgot-password.page').then(
        (m) => m.ForgotPasswordPage
      ),
  },
  {
    path: RouterEnum.resetPassword,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/user/pages/reset-password/reset-password.page').then(
        (m) => m.ResetPasswordPage
      ),
  },
  {
    path: RouterEnum.dashboard,
    loadComponent: () =>
      import('./features/dashboard/dashboard.page').then(
        (m) => m.DashboardPage
      ),
  },
  {
    path: 'wearer-list',
    loadComponent: () =>
      import(
        './features/wearer-management/pages/wearer-list/wearer-list.page'
      ).then((m) => m.WearerListPage),
  },
  {
    path: 'wearer-details',
    loadComponent: () =>
      import(
        './features/wearer-management/pages/wearer-details/wearer-details.page'
      ).then((m) => m.WearerDetailsPage),
  },
  {
    path: 'wearer-details/:id/:mode',
    loadComponent: () =>
      import(
        './features/wearer-management/pages/wearer-details/wearer-details.page'
      ).then((m) => m.WearerDetailsPage),
  },
  {
    path: 'corporate-account',
    loadComponent: () =>
      import(
        './features/settings/corporate-account/corporate-account.page'
      ).then((m) => m.CorporateAccountPage),
  },
  {
    path: 'facility-management',
    loadComponent: () =>
      import(
        './features/settings/facility-management/facility-management.page'
      ).then((m) => m.FacilityManagementPage),
  },
  {
    path: 'facility-floors/:facilityId',
    loadComponent: () =>
      import(
        './features/settings/facility-management/facility-floors/floor-list/floor-list.page'
      ).then((m) => m.FloorListPage),
  },
  {
    path: 'add-facility',
    loadComponent: () =>
      import(
        './features/settings/facility-management/add-facility/add-facility.page'
      ).then((m) => m.AddFacilityPage),
  },
  {
    path: 'add-facility/edit/:id',
    loadComponent: () =>
      import(
        './features/settings/facility-management/add-facility/add-facility.page'
      ).then((m) => m.AddFacilityPage),
  },
  {
    path: 'add/floor/:id',
    loadComponent: () =>
      import(
        './features/settings/facility-management/facility-floors/floor/floor.page'
      ).then((m) => m.FloorPage),
  },
  {
    path: 'floor/edit/:id/:floorID',
    loadComponent: () =>
      import(
        './features/settings/facility-management/facility-floors/floor/floor.page'
      ).then((m) => m.FloorPage),
  },
  {
    path: RouterEnum.userAccounts,
    loadComponent: () =>
      import('./features/settings/user-accounts/user-accounts.page').then(
        (m) => m.UserAccountsPage
      ),
  },
  {
    path: RouterEnum.addAccount,
    loadComponent: () =>
      import('./features/settings/user-accounts/add-account/add-account.page').then(
        (m) => m.AddAccountPage
      ),
  },
  {
    path: `${RouterEnum.addAccount}/:userId/:mode`,
    loadComponent: () =>
      import('./features/settings/user-accounts/add-account/add-account.page').then(
        (m) => m.AddAccountPage
      ),
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/settings/about/about.page').then((m) => m.AboutPage),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/help/help.page').then((m) => m.HelpPage),
  },
  {
    path: 'room-list',
    loadComponent: () =>
      import(
        './features/settings/room-management/room-list/room-list.page'
      ).then((m) => m.RoomListPage),
  },
  {
    path: 'add-room',
    loadComponent: () =>
      import(
        './features/settings/room-management/create-room/create-room.component'
      ).then((m) => m.CreateRoomComponent),
  },
  {
    path: 'room-details/:id',
    loadComponent: () =>
      import(
        './features/settings/room-management/room-details/room-details.page'
      ).then((m) => m.RoomDetailsPage),
  },
  {
    path: 'edit-room/:id',
    loadComponent: () =>
      import(
        './features/settings/room-management/create-room/create-room.component'
      ).then((m) => m.CreateRoomComponent),
  },
  {
    path: 'room-details/floor-plan-locator',
    loadComponent: () =>
      import(
        './features/settings/room-management/floor-plan-locator/floor-plan-locator.page'
      ).then((m) => m.FloorPlanLocatorPage),
  },
  {
    path: `${RouterEnum.roomPlacement}`,
    loadComponent: () =>
      import(
        './features/settings/room-management/room-placement/room-placement.page'
      ).then((m) => m.RoomPlacementPage),
  },
  {
    path: 'assigned-residents/:id',
    loadComponent: () =>
      import(
        './features/settings/room-management/assigned-residents/assigned-residents.component'
      ).then((m) => m.AssignedResidentsComponent),
  },
  {
    path: 'residents-list/:id',
    loadComponent: () =>
      import(
        './features/settings/room-management/residents-list/residents-list.component'
      ).then((m) => m.ResidentsListComponent),
  },
  {
    path: 'remove-residents/:id',
    loadComponent: () =>
      import(
        './features/settings/room-management/remove-assigned-residents/remove-assigned-residents.component'
      ).then((m) => m.RemoveAssignedResidentsComponent),
  },
  {
    path: 'select-room',
    loadComponent: () =>
      import(
        './features/wearer-management/pages/select-room/select-room.page'
      ).then((m) => m.SelectRoomPage),
  },
  {
    path: 'select-room/:selectedRoomId',
    loadComponent: () =>
      import(
        './features/wearer-management/pages/select-room/select-room.page'
      ).then((m) => m.SelectRoomPage),
  },
  {
    path: 'select-bed/:roomId/:wearerId',
    loadComponent: () =>
      import(
        './features/bed-management/pages/select-bed/select-bed.component'
      ).then((m) => m.SelectBedComponent),
  },
  {
    path: 'assign-device',
    loadComponent: () =>
      import('./features/band/pages/assign-device/assign-device.page').then(
        (m) => m.AssignDevicePage
      ),
  },
  {
    path: RouterEnum.bandIntro,
    loadComponent: () =>
      import('./features/band/pages/band-intro/band-intro.page').then(
        (m) => m.BandIntroPage
      ),
  },
  {
    path: RouterEnum.bandScan,
    loadComponent: () =>
      import('./features/band/pages/band-scan/band-scan.page').then(
        (m) => m.BandScanPage
      ),
  },
  {
    path: `${RouterEnum.bandCompletion}/:bandId`,
    loadComponent: () =>
      import('./features/band/pages/band-completion/band-completion.page').then(
        (m) => m.BandCompletionPage
      ),
  },
  {
    path: `${RouterEnum.residentDetails}/:residentID`,
    loadComponent: () =>
      import(
        './features/settings/room-management/ressidents-details/ressidents-details.component'
      ).then((m) => m.RessidentsDetailsComponent),
  },
  {
    path: 'simulate/:entity',
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/simulator/simulator.component').then(
        (m) => m.SimulatorComponent
      ),
  },
  {
    path: RouterEnum.bedSimulator,
    canActivate: [AuthGuard],
    loadComponent: () =>
      import('./features/simulator/bed-simulator/bed-simulator.component').then(
         (m) => m.BedSimulatorComponent
      ),
  },
  {
    path: RouterEnum.assetList,
    loadComponent: () =>
      import(
        './features/asset-management/pages/asset-list/asset-list.page'
      ).then((m) => m.AssetListPage),
  },
  {
    path: `${RouterEnum.assetDetails}`,
    loadComponent: () =>
      import(
        './features/asset-management/pages/asset-details/asset-details.page'
      ).then((m) => m.AssetDetailsPage),
  },
  {
    path: `${RouterEnum.assetDetails}/:assetId/:mode`,
    loadComponent: () =>
      import(
        './features/asset-management/pages/asset-details/asset-details.page'
      ).then((m) => m.AssetDetailsPage),
  },
  {
    path: RouterEnum.bedList,
    loadComponent: () =>
      import('./features/bed-management/pages/bed-list/bed-list.page').then(
        (m) => m.BedListPage
      ),
  },
  {
    path: `${RouterEnum.bedDetails}`,
    loadComponent: () =>
      import(
        './features/bed-management/pages/bed-details/bed-details.page'
      ).then((m) => m.BedDetailsPage),
  },
  {
    path: `${RouterEnum.bedDetails}/:bedId/:mode`,
    loadComponent: () =>
      import(
        './features/bed-management/pages/bed-details/bed-details.page'
      ).then((m) => m.BedDetailsPage),
  },
  {
    path: `${RouterEnum.assignItems}/:entityId/:entityType`,
    loadComponent: () =>
      import(
        './features/settings/assign-items/assign-list/assign-list.page'
      ).then((m) => m.AssignListPage),
  },
  {
    path: `${RouterEnum.removeAssignedItems}/:entityId/:entityType`,
    loadComponent: () =>
      import(
        './features/settings/assign-items/remove-list/remove-list.page'
      ).then((m) => m.RemoveListPage),
  },
  {
    path: `${RouterEnum.unassignedItems}/:entityId/:entityType`,
    loadComponent: () =>
      import(
        './features/settings/assign-items/unassign-list/unassign-list.page'
      ).then((m) => m.UnassignListPage),
  },
  {
    path: RouterEnum.bridgeList,
    loadComponent: () =>
      import('./features/bridge-management/pages/bridge-list/bridge-list.page').then(
        (m) => m.BridgeListPage
      ),
  },
  {
    path: RouterEnum.switchCorpAccount,
    loadComponent: () =>
      import('./features/corporate-list/corporate-list.page').then(
        (m) => m.CorporateListPage
      ),
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },

];
