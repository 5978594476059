import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonNav, IonicModule, NavParams } from '@ionic/angular';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { SettingsService } from 'src/app/features/settings/services/settings.service';
import { Observable } from 'rxjs';

export class RoomListModel {
  id = '';
  floorId = '';
  floorName = '';
  roomTypeName = '';
  subTypeName = '';
  roomName = '';
  startDate = '';
  endDate = '';
  createdBy = '';
  createDate = '';
  updateDate = '';
  updatedBy = '';
}

export interface RouteState {
  roomId: string;
  wearerId: string;
}

@Component({
  selector: 'app-select-room',
  templateUrl: './select-room.page.html',
  styleUrls: ['./select-room.page.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
})
export class SelectRoomPage implements OnInit {
  isSaveEnabled = false;
  roomList: RoomListModel[] = [];
  searchRoomListData: RoomListModel[] = [];
  state: RouteState = { roomId: '', wearerId: '' };
  selectRoomControl = new FormControl('');
  queryParams: RouteState = { roomId: '', wearerId: '' };

  constructor(
    private settingService: SettingsService,
    private router: Router,
    private uiUtilityService: UiUtilityService,
    public activatedRoute: ActivatedRoute,
    private ionNav: IonNav,
    private navParams: NavParams
  ) {
    //this.state = this.router.getCurrentNavigation()?.extras.state as RouteState;
    // this.activatedRoute.queryParams.subscribe((res) => {
    //   this.queryParams = res as RouteState;
    // });
  }

  ngOnInit() {
    // this.selectRoomControl.patchValue(this.state.roomId || '');
    this.queryParams = this.navParams.data as RouteState;
    this.selectRoomControl.patchValue(this.queryParams.roomId || '');
    this.getDetails();
  }

  async getDetails() {
    await this.uiUtilityService.showLoader();
    const noneRoom = new RoomListModel();
    noneRoom.roomName = 'None';
    noneRoom.id = '';
    this.settingService.getBedRoomList().subscribe((response) => {
      if (response.success && response.data) {
        this.searchRoomListData = response.data;
        this.searchRoomListData.unshift(noneRoom);
        this.roomList = [...this.searchRoomListData];
      }
    });
  }

  handleChange(event: any) {
    const searchQuery = event.target.value;
    if (searchQuery && searchQuery.trim() !== '') {
      this.roomList = this.searchRoomListData.filter((term: any) => {
        return (
          term.roomName.toLowerCase().indexOf(
            searchQuery.trim().toLowerCase()
          ) > -1
        );
      });
    } else if (searchQuery === '') {
      this.roomList = this.searchRoomListData;
    }
  }

  async associateRoomToResident() {
    await this.uiUtilityService.showLoader();
    let roomAssociation$: Observable<any>;
    // const url = `${environment.apiUrl.roomManagement}${FACILITY_ID_URL}/${EndPointsEnum.addResidentToRoom}/${this.selectRoomControl.value}`;
    if (this.selectRoomControl.value) {
      // roomAssociation$ = this.settingService.associatesResidentsToRoom(
      //   [this.state.wearerId],
      //   this.selectRoomControl.value
      // );
      roomAssociation$ = this.settingService.associatesResidentsToRoom(
        [this.queryParams.wearerId],
        this.selectRoomControl.value
      );
    } else {
      // roomAssociation$ = this.settingService.removeRoomToResident(
      //   this.state.roomId,
      //   [this.state.wearerId]
      // );
      roomAssociation$ = this.settingService.removeRoomToResident(
        this.queryParams.roomId,
        [this.queryParams.wearerId]
      );
    }
    roomAssociation$.subscribe(() => {
      this.ionNav.pop();
    });
  }

  goBack() {
    this.ionNav.pop();
  }
}
