<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <!-- *ngIf="!this.prevPage" -->
      <ion-back-button (click)="navigateBack()"></ion-back-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="done-btn" (click)="saveData()"> Save </ion-button>
      <!-- [disabled]="alertThresholdControl.invalid || alertThresholdControl.pristine" -->
    </ion-buttons>
    <ion-title mode="ios">{{pageTitle}}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="bandDetailsForm">
  <form [formGroup]="bandDetailsForm">
    <div>
      <ion-item class="first-item-border">
        <ion-label>{{isTag ? 'ONDO Tag' : 'ONDO Band'}}</ion-label>
        <div slot="end" class="band-id">
          <ion-text> {{bandId}}</ion-text>
        </div>
      </ion-item>
      <ion-note class="replace-note">Bands/Tags can only be replaced or added through the mobile app.</ion-note>
    </div>
    <div>
      <div class="section-label">{{isAsset ? 'Asset Settings' : 'Wearer Settings'}}</div>
      <ion-item *ngIf="!isAsset && !isTag" class="first-item-border">
        <ion-label>Temperature Alert Threshold
          <ion-icon src="assets/icon/icons_Hint.svg" (click)="showLocationInfo('alert', $event);">
          </ion-icon>
        </ion-label>
        <ion-item slot="end">
          <ion-select slot="end" class="ion-select-custom" formControlName="alertThreshold" interface="popover">
            <ion-select-option *ngFor="let option of alertThresholdOption | keyvalue" [value]="option.value">
              {{ option.key }} &#176;{{tempUnit[tempUnitId]}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-item>
    </div>
    <div>
      <ion-item class="first-item-border">
        <ion-label>Latest Location (beta)
          <ion-icon src="assets/icon/icons_Hint.svg" (click)="showLocationInfo('location', $event)">
          </ion-icon>
        </ion-label>
        <ion-toggle mode="ios" color="controls" slot="end" formControlName="location"
          (ionChange)="onLatestLocation()"></ion-toggle>
      </ion-item>
    </div>
    <div>
      <ion-item *ngIf="bandDetailsForm?.get('location')?.value == true">
        <ion-label>Location Warning (beta)
          <ion-icon src="assets/icon/icons_Hint.svg" (click)="showLocationInfo('locationAlert', $event)">
          </ion-icon>
        </ion-label>
        <ion-toggle mode="ios" color="controls" slot="end" formControlName="locationAlert"
          (ionChange)="onLocationWarning()"></ion-toggle>
      </ion-item>
    </div>
    <!-- (ionChange)="bandFlow == BandPageFlow.AssetOndoTagAssociated ? onToggleChange() : false;" -->
    <div>
      <ion-item *ngIf="bandDetailsForm?.get('locationAlert')?.value == true">
        <ion-label>Grace Period</ion-label>
        <ion-select slot="end" formControlName="gracePeriod" interface="popover">
          <ion-select-option *ngFor="let gpo of gracePeriodOptions" [value]="gpo">
            {{gpo}} Minutes
          </ion-select-option>
        </ion-select>
      </ion-item>
    </div>

    <!-- <ion-grid class="ion-no-padding">
    <ion-row class="upper-row">
      <ion-col size="12">
        <div class="band-card-status">
            <div class="progress-text" [ngClass]="bandTitle.replace(' ', '-')"> {{bandTitle}} </div>
            <img class="band-state-img" [src]="bandIcon" />
            <div class="wearer-name">
              <div>Band/Tag ID: {{bandId}}</div>
              <div>Wearer: {{wearerName}}</div>
            </div>
        </div>
      </ion-col>
    </ion-row>
    <ion-row class="lower-row">
      <ion-col size="12">
        <div class="scan-instruction">
          <p>
            Tap below to replace the currently associated Band with another one.
          </p>
          <div class="replace-band-btn">
            <ion-button class="no-padding" *ngIf="isAssociatedBand" (click)="navigateToWearerDetails()">Go to Wearer Details</ion-button>
          </div>
          <div class="replace-band-btn">
            <ion-button (click)="replaceBand()">{{replaceButtonName}}</ion-button>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid> -->
  </form>
  <div *ngIf="isReplaceRemoveEnabled">
    <div class="remove-device">
      <ion-button color="danger" (click)="removeDevice(isTag ? 'Tag' : 'Band')">Remove this {{isTag ? 'Tag' :
        'Band'}}</ion-button>
      <ion-button (click)="replaceBand()" *ngIf="isNative" class="primary-button">Replace this {{isTag ? 'Tag' :
        'Band'}}</ion-button>
    </div>
    <ion-note class="replace-note">Exiting device settings are reset to default values when a device is removed or
      replaced. This actions can not be undone</ion-note>
  </div>
</ion-content>