import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AlertController, IonNav, IonSelect, IonicModule, NavParams } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import {
  AddFloorDetailsModel,
  AddRoomDetails,
  AddRoomDetailsModel,
  RoomTypeName,
} from '../../../../shared/models/addRoomModel';
import { SettingsService } from '../../services/settings.service';
import { UiUtilityService } from '../../../../core/services/ui-utility.service';
import {
  RoomDetails,
  RoomDetail,
} from 'src/app/shared/models/roomDetailsModel';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';
import { takeUntil, startWith, Subject } from 'rxjs';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { RoomDetailsPage } from '../room-details/room-details.page';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlType, FormError } from 'src/app/shared/components/unified-custom-input/unified-input.types';
import { InputConfigService } from 'src/app/core/services/input-config.service';

@Component({
  selector: 'app-create-room',
  templateUrl: './create-room.component.html',
  styleUrls: ['./create-room.component.scss'],
  standalone: true,
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    UnifiedHeaderComponent,
  ],
})
export class CreateRoomComponent implements OnInit {
  addRoomFormData: RoomDetail = new RoomDetail();
  addRoomDetailsData: AddRoomDetails = new AddRoomDetails();
  addRoomTypeDetailsData: RoomTypeName = new RoomTypeName();
  addFloorFormData!: AddFloorDetailsModel[];
  roomDetails: RoomDetails = new RoomDetails();
  roomDetailsFormGroup!: FormGroup;
  room: any = '';
  floor: any = '';
  type: any = '';
  subTypeRoom: any = '';
  showList = true;
  addRoomLabel: string = '';
  roomID: any = '';
  editRoomEnable: boolean = false;
  preSelectedRoomName = 'My Room';
  preSelectedFloor: any;
  preSelectedRoomType: any;
  preSelectedSubtype: any;
  enableSubtype: boolean = false;
  private destroy = new Subject<void>();
  @ViewChild('floorList') selectRef!: IonSelect;
  @ViewChild('roomType') selectRoomRef!: IonSelect;
  @ViewChild('subRoomType') selecteSubtypeRef!: IonSelect;
  destroyRef = inject(DestroyRef);
  addRoomForm!: FormGroup;
  floors = [
    {
      floorID: '',
      floorName: '',
    },
  ];
  roomOptions = [
    {
      id: 1,
      name: '',
      type: 'room',
    },
    {
      id: 2,
      name: '',
      type: 'room',
    },
  ];
  subType = [
    {
      id: 1,
      name: '',
      subTypeName: '',
    },
    {
      id: 2,
      name: '',
      subTypeName: '',
    },
  ];
  isSaveDisabled = true;
  fieldConfig: any = {
    roomName: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Name',
      label: 'Name',
      value: '',
      detail: false,
      name: 'roomName',
      showNone: true,
      validator: 'abstractName',
      required: true
    }
  };

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private ionNav: IonNav,
    private navParams: NavParams,
    private inputConfigService: InputConfigService,
    private alertController: AlertController
  ) {
    if (this.navParams.get('roomId')) {
      this.addRoomLabel = 'Edit Room';
      this.editRoomEnable = !this.editRoomEnable;
    } else {
      this.addRoomLabel = 'Add Room';
    }
  }

  setFormModified() {
    this.addRoomForm.markAsDirty();
  }

  ionViewWillLeave() {
    this.subscribeForm();
  }

  subscribeForm() {
    this.addRoomForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.addRoomForm) {
          this.isSaveDisabled =
            this.addRoomForm.invalid || !this.addRoomForm.dirty;
        }
      });
  }

  ngOnInit() {
    // this.roomDetailsFormGroup = this.fb.group(this.roomDetails.roomDetail);
    if (this.editRoomEnable) {
      this.roomDetailsFormGroup = this.fb.group({
        floorName: '',
        id: '',
        floorId: '',
        roomTypeName: '',
        subTypeName: '',
        roomName: '',
        startDate: '',
        endDate: '',
        createdBy: '',
        createDate: '',
        updateDate: '',
        updatedBy: '',
      });
      this.roomDetailsFormGroup.valueChanges
        .pipe(
          takeUntil(this.destroy),
          startWith(this.roomDetailsFormGroup.value)
        )
        .subscribe();
      this.getRoomDetails();
    }
    this.addRoomForm = this.fb.group({
      roomName: ['', Validators.required],
      floor: [''],
      type: [''],
    });
    this.getRoomType();
    this.getFloorList();
    this.getSubtype();
  }

  getRoomType() {
    this.settingsService.getRoomTypeDetails().subscribe((response) => {
      if (response.success) {
        let data: any = response.data;
        for (let i = 0; i < data.length; i++) {
          this.roomOptions[i].name = data[i].roomTypeName;
          this.addRoomTypeDetailsData.roomTypeName = data[i].roomTypeName;
        }
      }
    });
  }

  getSubtype() {
    this.settingsService.getSubtype().subscribe((response) => {
      if (response.success) {
        let data: any = response.data;
        for (let i = 0; i < data.length; i++) {
          this.subType[i].subTypeName = data[i].subTypeName;
        }
      }
    });
  }

  getFloorList() {
    this.settingsService.getFloorListDetails().subscribe((response) => {
      if (response.success) {
        let data: any = response.data;
        this.addFloorFormData = data.floors;
      }
    });
  }

  getRoomList() {
    this.settingsService.getRoomListByFacilityId().subscribe((response) => {
      if (response.success) {
        let data: any = response.data;
      }
    });
  }

  addRoomData() {
    let type = {
      sub: '',
    };
    if (this.enableSubtype == false) {
      this.addRoomDetailsData.subTypeName = 'None';
      this.sendAddRoomData();
    } else if (this.enableSubtype == true) {
      type.sub = this.addRoomDetailsData.subTypeName;
      if (type.sub == '' || type.sub == 'None') {
        this.uiUtilityService.showToast('Please enter data to all the fields');
      } else {
        this.sendAddRoomData();
      }
    }
  }

  sendAddRoomData() {
    this.addRoomDetailsData.facilityID = FACILITY_ID_URL;
    if (
      this.addRoomDetailsData.roomName.length &&
      this.addRoomDetailsData.roomTypeName.length &&
      this.addRoomDetailsData.subTypeName.length &&
      this.addRoomDetailsData.facilityID.length > 0
    ) {
      this.settingsService
        .saveRoomManagementDetails(this.addRoomDetailsData, this.floorIDValue)
        .subscribe((response) => {
          if (response && response.success && response.data) {
            this.uiUtilityService.closeLoader();
            // After successfully adding the room navigate to details page and
            // Remove the Add room page from the stack, so that clicking back button on details page navigate to list page
            // instead of room add page.
            this.ionNav
              .push(RoomDetailsPage, {
                roomId: response.data.id,
              })
              .then(() => this.ionNav.removeIndex(1));
            this.uiUtilityService.showToast('Room Created Successfully');
          } else {
            const header = 'Error';
            this.uiUtilityService.showAlert(response.errorMessage!, header);
          }
        });
    } else {
      this.uiUtilityService.showToast('Please enter data to all the fields');
    }
  }

  updateRoomData() {
    this.addRoomDetailsData.facilityID = FACILITY_ID_URL;
    this.addRoomDetailsData.subTypeName =
      this.roomDetails.roomDetail.subTypeName;
    this.addRoomDetailsData.roomTypeName =
      this.roomDetails.roomDetail.roomTypeName;
    this.addRoomDetailsData.roomName = this.roomDetails.roomDetail.roomName;
    this.addRoomDetailsData.id = this.roomDetails.roomDetail.id;
    if (this.enableSubtype == false) {
      this.addRoomDetailsData.subTypeName = 'None';
    }
    this.settingsService
      .editRoomManagementDetails(this.addRoomDetailsData, this.floorIDValue)
      .subscribe((response) => {
        if (response && response.success && response.data) {
          this.uiUtilityService.closeLoader();
          this.editRoomEnable = !this.editRoomEnable;
          let url: string = '/room-details' + '/' + this.addRoomDetailsData.id;
          this.ionNav.pop();
          this.uiUtilityService.showToast('Updated Successfully');
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  openSelectionList() {
    setTimeout(() => {
      this.selectRef.open();
    }, 2);
  }
  floorIDValue: any;
  setFloor(event: any) {
    for (let i = 0; i < this.addFloorFormData.length; i++) {
      if (this.addFloorFormData[i].floorID == event.detail.value) {
        this.floor = this.addFloorFormData[i].floorName;
        if (this.editRoomEnable) {
          this.roomDetails.roomDetail.floorName =
            this.addFloorFormData[i].floorName;
        }
      }
    }
    this.addRoomForm.get('floor')?.setValue(event.detail.value);
    this.setFormModified();
    this.floorIDValue = event.detail.value;
    // if(this.editRoomEnable) {
    //   this.floorIDValue = this.roomDetails.roomDetail.FloorId;
    // }
    // this.addRoomDetailsData.floorId = event.detail.value[0];
  }

  openRoomSelectionList() {
    setTimeout(() => {
      this.selectRoomRef.open();
    }, 2);
  }

  openSubtype() {
    setTimeout(() => {
      this.selecteSubtypeRef.open();
    }, 2);
  }

  setRoomType(event: any) {
    this.type = event.detail.value['name'];
    this.addRoomForm.get('type')?.setValue(event.detail.value['name']);
    this.setFormModified();
    this.addRoomDetailsData.roomTypeName = this.type;
    if (event.detail.value['id'] == 1) {
      this.enableSubtype = false;
    } else {
      this.enableSubtype = true;
    }
    if (this.editRoomEnable) {
      this.roomDetails.roomDetail.roomTypeName = this.type;
    }
  }

  setSubRoomType(event: any) {
    this.subTypeRoom = event.detail.value;
    this.addRoomDetailsData.subTypeName = event.detail.value;
    if (this.editRoomEnable) {
      this.roomDetails.roomDetail.subTypeName = event.detail.value;
    }
  }

  getRoomDetails() {
    this.roomID = this.navParams.get('roomId');
    this.settingsService.getRoomDetails(this.roomID).subscribe((res) => {
      if (res.data) {
        this.roomDetails = res.data;
        this.roomDetailsFormGroup.patchValue(res?.data.roomDetail);
        this.floorIDValue = this.roomDetails.roomDetail.floorId;
        this.preSelectedFloor = this.roomDetails.roomDetail.floorName;
        let type = {
          roomType: '',
        };
        type.roomType = this.roomDetails.roomDetail.roomTypeName;
        if (type.roomType == 'Bed Room') {
          this.enableSubtype = false;
        } else {
          this.enableSubtype = true;
        }
      }
      this.uiUtilityService.closeLoader();
    });
  }

  navigateBack() {
    this.ionNav.pop();
  }

  async presentAlert(
    operation: any
  ) {
    const config = this.fieldConfig[operation];
    const formG = this.editRoomEnable ? this.roomDetailsFormGroup : this.addRoomForm;
    const initValue = formG?.get(operation)?.value;
    const formControl = new FormControl(initValue, {
      validators: this.inputConfigService.getValidatorForPrompt(this.fieldConfig[operation])
    });
    const alert = await this.alertController.create({
      header: config.label,
      buttons: [
        {
          text: 'Cancel',
          role: 'dismiss',
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            if (!data) return false;
            let errorMsg = '';
            const value = data[0];
            formControl.patchValue(value);
            const errorType = formControl.errors as FormError;
            const errMsg = this.inputConfigService.getValidationMessage(config);
            if (errorType) {
              if (errorType.required) {
                errorMsg = `Please ${config.controlType == 'text' ? 'Enter' : 'select'} ${config.title || config.label}.`;
              } else if (
                errorType.maxlength ||
                errorType.minlength ||
                errorType.pattern
              ) {
                errorMsg = `Please enter valid ${config.title || config.label}${errMsg ? `, ${errMsg}.` : '.'}`
              }
            }
            if (errorMsg) {
              alert.message = errorMsg;
              return false;
            } else {
              this.addRoomForm?.patchValue({ [operation]: value, });
              this.roomDetailsFormGroup?.get(operation)?.patchValue(value);
              this.room = value;
              this.addRoomDetailsData.roomName = value;
              if (this.editRoomEnable) {
                this.roomDetails.roomDetail.roomName = value;
              }
              if (value) {
                this.isSaveDisabled = false;
              } else {
                this.isSaveDisabled = true;
              }
              this.addRoomForm?.markAsDirty();
              return;
            }
          },
        },
      ],
      inputs: [
        {
          placeholder: config.placeholder,
          value: initValue,
        },
      ],
      cssClass: `notable-state-alert unified-custom-input-alert`,
      animated: false,
      backdropDismiss: false,
    });
    await alert.present();
  }
}
