import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { environment } from 'src/environments/environment';
import { BedDetailModel, BedModel } from 'src/app/shared/models/bed-modal';
import { FACILITY_ID_URL } from 'src/app/shared/config/app.constant';

@Injectable({
  providedIn: 'root',
})
export class BedService {

  constructor(
    private dataService: DataService<any>
  ) { }

  getBedList(): Observable<ApiResponseModel<BedModel[]>> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/getBedList`;
    return this.dataService.getData(url);
  }

  getBedDetails(
    id: string
  ): Observable<ApiResponseModel<BedDetailModel>> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/getBedDetail/${id}`;
    return this.dataService.getData(url);
  }

  createBed(payload: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/manage`;
    return this.dataService.post(url, payload);
  }

  updateBed(payload: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/manage`;
    return this.dataService.put(url, payload);
  }

  deleteBed(bedId: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/delete/${bedId}`;
    return this.dataService.delete(url, {});
  }

  assignRoomToBed(bedId: any, roomId: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/assignRoomToBed/${bedId}/${roomId}`;
    return this.dataService.getData(url);
  }

  removeRoomToBed(bedId: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/removeRoomToBed/${bedId}`;
    return this.dataService.getData(url);
  }

  assignResidentToBed(bedId: any, residentId: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/assignResidentToBed/${bedId}/${residentId}`;
    return this.dataService.getData(url);
  }

  removeResidentToBed(bedId: any): Observable<any> {
    const url = `${environment.apiUrl.bedManagement}${FACILITY_ID_URL}/removeResidentToBed/${bedId}`;
    return this.dataService.getData(url);
  }

  get(controlName: string, form: FormGroup | null): AbstractControl | null {
    return form && form.get(controlName);
  }

  assignBedSlotToBed(bedId: any, bedSlotId: any): Observable<any> {
    const url = `${environment.apiUrl.pccRoomManagement}${FACILITY_ID_URL}/assignBedSlotToBed/${bedId}/${bedSlotId}`;
    return this.dataService.getData(url);
  }
}
