<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Bed Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddBed()" [nAccess]="'postBed'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <app-sub-header [groups]="groups" (onFilterApply)="onFilterApply($event)" (onFilterCancel)="onFilterCancel($event)"
    (onFilterReset)="onFilterReset()"></app-sub-header>
  <div *ngIf="bedList?.length || searchBedList?.length">
    <ion-searchbar [(ngModel)]="searchQuery" (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar"
      animated placeholder="Search...">
    </ion-searchbar>
  </div>
</ion-header>

<ion-content>
  <div *ngIf="bedList?.length || searchBedList?.length">
    <ion-item *ngFor="let bed of bedList" (click)="navigateToDetailsPage(bed)" detail class="list-with-name">
      <ion-label>
        <h3>{{bed.bedName}}</h3>
      </ion-label>
      <ion-label slot="end" class="no-margin-right end-name-max list-right-slot"
        *ngIf="bed.roomName || bed.appResidentName">
        <h3>
          <p><span class="slot-1" [ngClass]="{'offline-text': !bed.appRoomName}">{{bed.appRoomName
              || 'No room'}}</span></p>
          <p><span class="slot-2" [ngClass]="{'offline-text': !bed.appResidentName}">{{bed.appResidentName || 'No
              resident'}}</span>
          </p>
        </h3>
      </ion-label>
    </ion-item>
  </div>
  <div class="no-bed" *ngIf="!bedList?.length">
    No beds found in the selected facility.
  </div>
</ion-content>