<ion-content class="content">
  <ion-button class="nav-back" (click)="navigateBack()" fill="clear">
    <ion-icon slot="start" color="primary" name="chevron-back-outline" class="custom-back-icon"></ion-icon>
    <a class="app-link">Back to Population Summary</a>
  </ion-button>
  <ion-row class="ion-justify-content-between report-header">
    <ion-col>
      Report generated: {{reportData.generatedOn}}
    </ion-col>
    <ion-col class="ion-text-end">
      Report Data: <a class="app-link" [class.disabled]="loading" (click)="!loading && downloadPDF()">Download PDF</a>
      <!-- | <a
        class="app-link disabled">CSV</a> -->
    </ion-col>
  </ion-row>
  <div #contentToDownload>
    <div class="report-card">
      <h3 class="header">{{wearerTitle}}</h3>
      <h4 class="sub-header">Overview</h4>
      <ion-row>
        <ion-col size="5.5" class="ion-no-padding">
          <ion-row class="ps-left-content">
            <ion-col class="ion-no-padding">
              <p>{{reportData.name}}</p>
              <p>Facility: {{facilityName}}</p>
              <p>Facility timezone: {{timeZone}}</p>
            </ion-col>
            <ion-col class="ion-no-padding">
              <p>Timeframe:</p>
              <p>{{reportData.timeframe}}</p>
            </ion-col>
          </ion-row>
        </ion-col>
        <ion-col class="ion-no-padding kpi-block">
          <div class="kpi">
            <div class="kpi-detail" *ngIf="isTemperature">
              <p>Average Temperature</p>
              <p class="value" [style.color]="reportData.data.avgTempColor">{{reportData.data.avgTempFormatted}}
              </p>
            </div>
            <div class="kpi-detail" *ngIf="isBedPosition">
              <p>Back Angle Score <ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
                  (click)="onInfo(backAngleScoreInfo)"></ion-icon></p>
              <p class="value" [style.color]="reportData.data.backAngleScoreColor"><span
                  *ngIf="reportData.data['backAngleScore'+'Formatted']">{{
                  reportData.data.backAngleScoreFormatted}}</span><span
                  *ngIf="!reportData.data['backAngleScore'+'Formatted']">{{
                  reportData.data.backAngleScore}}</span></p>
              <p *ngIf="reportData.data.backAngleScoreDiff"><span class="average"
                  [style.color]="reportData.data.backAngleScoreDiffColor">{{reportData.data.backAngleScoreDiff}}</span>
                <span> {{reportData.data.backAngleScoreDiffState === 'WARNING' ? "worse than last week's average" :
                  "better
                  than last week's average"}}</span>
              </p>
            </div>
            <div class="kpi-detail" *ngIf="isBedPosition">
              <p>Bed Height Score <ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
                  (click)="onInfo(bedHeightScoreInfo)"></ion-icon></p>
              <p class="value" [style.color]="reportData.data.bedHeightScoreColor"><span
                  *ngIf="reportData.data['bedHeightScore'+'Formatted']">{{
                  reportData.data.bedHeightScoreFormatted}}</span><span
                  *ngIf="!reportData.data['bedHeightScore'+'Formatted']">{{
                  reportData.data.bedHeightScore}}</span></p>
              <p *ngIf="reportData.data.bedHeightScoreDiff"><span class="average"
                  [style.color]="reportData.data.bedHeightScoreDiffColor">{{reportData.data.bedHeightScoreDiff}}</span>
                <span> {{reportData.data.bedHeightScoreDiffColor === 'WARNING' ? "worse than
                  last week's average" : "better than last week's average"}}</span>
              </p>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </div>
    <div class="report-card report-wearer-summary">
      <div class="header-block">
        <h3 class="header">Report</h3>
      </div>
      <div class="report-detail"
        [ngClass]="{'temp-with-line': reportData.data.tempTrendsGraph?.category !== 'stacked-bar'}"
        *ngIf="isTemperature">
        <p>Temperature<ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
            (click)="onInfo(tempGraphInfo)"></ion-icon></p>
        <p class="graph-error" *ngIf="reportData.data.tempTrendsGraph?.error; else isTempTrendsGraph">
          {{reportData.data.tempTrendsGraph.errorTitle}}</p>
        <ng-template #isTempTrendsGraph>
          <div class="canvas-content">
            <canvas baseChart [data]="reportData.data.tempTrendsGraph" [options]="reportChartOptions['tempTrendsGraph']"
              [plugins]="reportData.data.tempTrendsGraph?.category === 'stacked-bar' ? barChartPlugins : barChartPluginsWithLineGraph"
              [type]="reportData.data.tempTrendsGraph?.type">
            </canvas>
          </div>
        </ng-template>
      </div>
      <div class="report-detail" *ngIf="isBedPosition">
        <p>Back Angle<ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
            (click)="onInfo(backAngleGraphInfo)"></ion-icon></p>
        <p class="graph-error" *ngIf="reportData.data.backAngleGraph?.error; else isBackAngleGraph">
          {{reportData.data.backAngleGraph.errorTitle}}</p>
        <ng-template #isBackAngleGraph>
          <div class="canvas-content">
            <canvas baseChart [data]="reportData.data.backAngleGraph" [options]="reportChartOptions['backAngleGraph']"
              [plugins]="reportData.data.backAngleGraph?.category === 'stepped-line' ? lineChartPlugins : []"
              [type]="reportData.data.backAngleGraph?.type">
            </canvas>
          </div>
        </ng-template>
      </div>
      <div class="report-detail" *ngIf="isBedPosition">
        <p>Bed Height<ion-icon class="state-info interactive" [src]="'assets/icon/icons_Hint.svg'"
            (click)="onInfo(bedHeightGraphInfo)"></ion-icon></p>
        <p class="graph-error" *ngIf="reportData.data.bedHeightGraph?.error; else isBedHeightGraph">
          {{reportData.data.bedHeightGraph.errorTitle}}</p>
        <ng-template #isBedHeightGraph>
          <div class="canvas-content">
            <canvas baseChart [data]="reportData.data.bedHeightGraph" [options]="reportChartOptions['bedHeightGraph']"
              [plugins]="reportData.data.bedHeightGraph?.category === 'stepped-line' ? lineChartPlugins : []"
              [type]="reportData.data.bedHeightGraph?.type">
            </canvas>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</ion-content>