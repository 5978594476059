import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { AuthService } from '../services/auth.service';
import { UiUtilityService } from '../services/ui-utility.service';
import { errorMessage } from './error-message';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  // private readonly DEFAULT_TIMEOUT = 20000;

  constructor(
    private authService: AuthService,
    private uiUtilityService: UiUtilityService,
    private route: Router,
    private ngZone: NgZone
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.uiUtilityService.closeLoader();
        if (
          err instanceof HttpErrorResponse &&
          !request.url.includes(EndPointsEnum.logout)
        ) {
          switch (err.status) {
            case 401:
              this.authService.clearStorage();
              this.ngZone.run(() => {
                this.uiUtilityService.closeModal();
                this.route.navigate([RouterEnum.login], { replaceUrl: true });
              });
              break;
            case 404:
              if (this.authService.isUserLoggedIn) {
                this.ngZone.run(() => {
                  this.route.navigate([RouterEnum.dashboard]);
                });
              }
              break;
            case 423:
              // forbidden access handling
              return throwError(err);
            default:
              if (!navigator.onLine) {
                this.uiUtilityService.showAlert(errorMessage.noNetwork, 'Alert');
                return of();
              }
              return throwError(err);
          }
        }
        return throwError(err);
      })
    );
  }
}
