<ion-header id="custom-nav">
  <ion-toolbar class="non-modal-toolbar">
    <ion-buttons slot="start">
      <ion-button menu="sideMenu" (click)="toggleMenu()">
        <ion-button>
          <ion-icon slot="icon-only" class="menu-icon"
            [src]="isMenuOpen ? 'assets/icon/icon-close.svg' : 'assets/icon/icons_Menu.svg'"></ion-icon>
        </ion-button>
      </ion-button>
    </ion-buttons>
    <ion-title class="facility-title">
      <div class="custom-header">
        <ion-icon class="facility-icon" src="assets/icon/menu-facility.svg"></ion-icon>
        <div class="select-container">
          <ion-select *ngIf="availableFacilities && availableFacilities.length > 1 && !isSettingModalOpen"
            interface="popover" [interfaceOptions]="{ cssClass: 'jfac-popover' }" [compareWith]="compareWith"
            [(ngModel)]="selectedFacility" (ionChange)="onFacilityChange()">
            <ion-select-option *ngFor="let facility of availableFacilities" [value]="facility">
              {{facility.facilityName}}
            </ion-select-option>
          </ion-select>
          <div class="facility-name"
            *ngIf="availableFacilities && (availableFacilities.length === 1 || isSettingModalOpen)">
            {{selectedFacility.facilityName}}</div>
        </div>
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="openMessageDrawer()" *ngIf="selectedFacility.enablePCC && header === 'main'">
        <ion-icon slot="icon-only" class="message-icon" src="assets/svg/{{messageIcon}}"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end" class="summary-button">
      <ion-button (click)="openSummary()">
        <ion-icon slot="icon-only" class="summary-icon" src="assets/icon/icons_Summary.svg"></ion-icon>
        <!-- <ion-icon *ngIf="drawerExpanded" slot="icon-only" src="assets/icon/wearer.svg"></ion-icon> -->
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<!-- [hidden]="!(availableFacilities.length > 1)" -->