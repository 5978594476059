<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons>
      <ion-button (click)="navigateBack()">
        <ion-icon *ngIf="!isRootPage" slot="start" name="chevron-back-outline"></ion-icon>
        {{isRootPage ? 'Close' : 'Back'}}
      </ion-button>

    </ion-buttons>
    <ion-title mode="ios">Room Details</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateForward(RouterEnum.editRoom, {roomId})" [nAccess]="'putRoom'">Edit</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <ion-item class="form-item-detail" [detail]="false">
    <ion-label>Name</ion-label><ion-text>{{roomName}}</ion-text>
  </ion-item>
  <ion-item class="form-item-detail" [detail]="false">
    <ion-label>Floor</ion-label><ion-text>{{floorName}}</ion-text>
  </ion-item>
  <ion-item class="form-item-detail" [detail]="false">
    <ion-label>Room Type</ion-label><ion-text>{{roomType}}</ion-text>
  </ion-item>
  <ion-item class="form-item-detail" *ngIf="showSubtype" [detail]="false">
    <ion-label>Subtype</ion-label><ion-text>{{subType}}</ion-text>
  </ion-item>
  <ion-list>
    <ng-container *ngIf="showAssignedResidents">
      <ion-list-header class="detail-header">
        <ion-label>Residents</ion-label>
      </ion-list-header>
      <ion-list *ngFor="let residents of roomDetails.residents; let i = index">
        <ion-item button (click)="navigateForward(RouterEnum.wearerDetails, {id: residents['residentId']})"
          [detail]="false" class="form-item-detail">
          <ion-label class="app-link p-b-1"
            [ngClass]="{ 'no-link': !isWearerSummaryEnabled }">{{residents['displayName']}}</ion-label>
        </ion-item>
      </ion-list>
      <!-- <ion-item [routerLink]="['/assigned-residents',roomID]"> -->
      <ion-item (click)="navigateForward(RouterEnum.residentList, {roomId})" [nAccess]="'getWearerList'">
        <a class="btn btn-link add-resident-link app-link-action">Assign resident...</a>
      </ion-item>
    </ng-container>
    <ion-list-header class="detail-header">
      <ion-label>Items</ion-label>
    </ion-list-header>

    <ion-item class="form-item-detail" [button]="true" (click)="onRoomItems('ASSET')">
      <ion-label>Assets</ion-label>
      <ion-note slot="end">{{roomDetails.assets?.length || 0}}</ion-note>
    </ion-item>
    <ion-item class="form-item-detail" [button]="true" (click)="onRoomItems('BED')" *ngIf="!isCommonRoom">
      <ion-label>Beds</ion-label>
      <ion-note slot="end">{{roomDetails.beds?.length || 0}}</ion-note>
    </ion-item>
    <!-- <ion-item [button]="true" (click)="onRoomItems('DEVICE')">
      <ion-label>Devices</ion-label>
      <ion-note slot="end">{{roomDetails.devices?.length || 0}}</ion-note>
    </ion-item> -->
    <ion-item class="form-item-detail" [button]="true" (click)="onRoomItems('BRIDGE')">
      <ion-label>Bridges</ion-label>
      <ion-note slot="end">{{roomDetails.bridges?.length || 0}}</ion-note>
    </ion-item>
  </ion-list>
  <ion-list-header class="detail-header">
    <ion-label>Advanced</ion-label>
  </ion-list-header>
  <ion-item (click)="navigateForward(RouterEnum.roomPlacement, {roomId})" [nAccess]="'putRoom'">
    <label class="app-link-action">Place on floor plan</label>
  </ion-item>
  <div class="delete-room">
    <ion-button color="danger" (click)="deleteRoom()" [nAccess]="'deleteRoom'">Delete This Room</ion-button>
  </div>
</ion-content>