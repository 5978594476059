import { Injectable } from '@angular/core';
import { subDays } from "date-fns/subDays";
import { formatInTimeZone } from "date-fns-tz";
import { Observable, Subject, catchError, forkJoin, map, of } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { FACILITY_ID_URL, META_ASSETS } from 'src/app/shared/config/app.constant';
import * as barColor from 'src/app/shared/config/bar-color.constant';
import { sortingDelimiter } from 'src/app/shared/config/report.constant';
import { defaultTempUnit } from 'src/app/shared/config/temp-unit.constant';
import { assetStates } from 'src/app/shared/enums/asset-states.enum';
import { bandIcons } from 'src/app/shared/enums/band-icons';
import { bandStates } from 'src/app/shared/enums/band-states';
import { notableColors } from 'src/app/shared/enums/color-classes';
import { colorClasses } from 'src/app/shared/enums/color-classes.enum';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import {
  locationIcons
} from 'src/app/shared/enums/location-icons.enum';
import {
  LocationStateDescription,
  LocationStateText,
} from 'src/app/shared/enums/location-message.enum';
import {
  locationAlertThresholdState,
  locationStates,
  notableLocationStates,
} from 'src/app/shared/enums/location-states.enum';
import { notableStateMessage } from 'src/app/shared/enums/notable-state-message';
import { SortingTypeEnum } from 'src/app/shared/enums/sorting-type.enum';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { WearerGroupEnum } from 'src/app/shared/enums/wearer-group.enum';
import { wearerStates } from 'src/app/shared/enums/wearer-states.enum';
import {
  Band,
  CurrentStatusSummary,
  CustomColors,
  DashboardData,
  DashboardResponseModel,
  HourlyTrendChartSeries,
  LastUpdatedTime,
  LocationAlert,
  Temperature,
  TrendChartData,
} from 'src/app/shared/models/dashboard.model';
import { SortingTypeModel } from 'src/app/shared/models/sorting-type.model';
import { WearerGroupModel } from 'src/app/shared/models/wearer-group.model';
import { environment } from 'src/environments/environment';
import { CasparService } from './caspar.service';
import { BED_IN, BED_INDETERMINATE, BED_OUT, BED_PRESENCE, CASPAR_ICON_ORDER, HEART_RATE_BASELINE_ALERT, HEART_RATE_THRESHOLD_ALERT, NOTABLE_ALERTS, RESPIRATION_RATE_BASELINE_ALERT, RESPIRATION_RATE_THRESHOLD_ALERT } from 'src/app/shared/config/caspar.contant';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  dashboardData: DashboardData[] = [];
  refreshDashBoardPage: Subject<any> = new Subject();
  sortingDelimiter: number = sortingDelimiter;
  currentWearer: Subject<any> = new Subject();
  currentStatusSummary: CurrentStatusSummary | undefined = undefined;
  plotFloorDashboard: Subject<any> = new Subject();
  onDashboardDataFetch: Subject<any> = new Subject();
  onEntityListClick: Subject<any> = new Subject();
  onDashboardTabsSwitch: Subject<any> = new Subject();
  requestId = '';
  selectedWearerGroup = 0;
  refreshDashboardWithData: Subject<any> = new Subject();

  mapIteration: number = 0;
  maxMapIteration: number = 9;
  isFloorPlanZoomIn = false;
  casparData: any = {};
  getCasparDataSub: Subject<any> = new Subject();
  isCasparEnabled = false;

  constructor(
    public dataService: DataService<DashboardResponseModel>,
    private headerService: HeaderService,
    private storageService: StorageService,
    private authService: AuthService,
    private uiUtilityService: UiUtilityService,
    private casparService: CasparService
  ) { }

  getDashboardData(): Observable<any> {
    const timeStamps = this.getTimeStamps();
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.dashboard}?startTime=${timeStamps.startTime}&endTime=${timeStamps.endTime}&timeZone=${timeStamps.timeZone}`;
    return this.dataService.getData(url);
  }

  getCasparActiveAlerts(): Observable<any> {
    this.casparData = [];
    if (!this.isCasparEnabled) {
      return of([]);
    }
    return this.casparService.getCasparActiveAlerts().pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  getCasparBedPresenseList(): Observable<any> {
    this.casparData = [];
    if (!this.isCasparEnabled) {
      return of([]);
    }
    return this.casparService.getCasparBedPresenseList().pipe(
      catchError(error => {
        return of([]);
      })
    );
  }

  getMergedDashboardData(): Observable<any[]> {
    return forkJoin([this.getDashboardData(), this.getCasparActiveAlerts(), this.getCasparBedPresenseList()]);
  }

  constructDashboardData(responseData: any) {
    this.currentStatusSummary = responseData.currentStatusSummary;
    this.sortingDelimiter = responseData.sortingDelimiter;
    this.requestId = responseData.requestId;
    const loginData = this.authService.getLoginData;
    this.mapIteration < this.maxMapIteration
      ? this.mapIteration++
      : (this.mapIteration = 0);
    const dashboardData: DashboardData[] =
      responseData.ondoRespDetails?.map((data: DashboardData) => {
        // if (
        //   data.wearerGroupName?.toLowerCase()?.indexOf('caregiver') !== -1
        // ) {
        //   if (
        //     ![wearerStates.lowBattery, wearerStates.offline].includes(
        //       +data.wearerState
        //     )
        //   ) {
        //     data.wearerState = wearerStates.healthy;
        //   }
        //   if (+data.bandState === 10) {
        //     data.bandState = 7;
        //   }
        // }
        data.patientId = `${data.wearerId}`;
        data.displayName = this.uiUtilityService.getEntityDisplayName(data);
        if (data.metaData !== META_ASSETS) {
          data.shortDisplayName = this.uiUtilityService.getEntityShortDisplayName(data);
        }
        data.band = this.getNotableState(data);
        data.locationInfo = this.getLocationInfo(data);
        data.sortOrder = this.getSortOrderForWearer(
          +data.locationState,
          (data.isTag || data.metaData === META_ASSETS) ? +data.assetState : +data.wearerState
        );
        data.temperature = this.temperatureBasedOnTempUnitPref(
          data,
          loginData.tempUnit || defaultTempUnit
        );
        // data.wearerGroupName = this.wearerIconAndGroupName(
        //   +data.wearerGroupName
        // );
        data.wearerGroupName = data.wearerGroupName;
        const customColorsTemp: CustomColors[] = [];
        data.graphDisplay = data.graphDisplay.map(
          (trends: TrendChartData) => {
            customColorsTemp.push({
              name: trends.name,
              value: this.calBarColor(trends.colorCode),
            });
            trends.value = this.calBarHeight(+trends.valueInC) + '';
            return trends;
          }
        );
        data.customColorsTemp = customColorsTemp;
        if (data.briefDisplay) {
          const customColorsBrief: CustomColors[] = [];
          data.briefDisplay[0].series.map(
            (series: HourlyTrendChartSeries) => {
              const customColorObj = new CustomColors();
              customColorObj.name = series.name;
              customColorObj.value = series.colorCode;
              customColorsBrief.push(customColorObj);
            }
          );
          data.customColorsBrief = customColorsBrief;
        }
        this.setEntityStates(data);
        return data;
      });
    this.dashboardData = dashboardData;
  }

  formDashboardData(): Observable<any> {
    this.uiUtilityService.dismissErrorAlert();
    const selectedFacility = this.headerService.getCurrentFacility;
    this.isCasparEnabled = selectedFacility.enableCaspar;
    return new Observable(observer => {
      this.getMergedDashboardData().subscribe(results => {
        const response = results[0];
        if (response.success && response.data) {
          this.constructDashboardData(response.data);
          observer.next();
          observer.complete();
        }
        const responseC = results[1];
        const responseCB = results[2];
        if (response.data) {
          if (response.data && responseC?.success && responseC?.data && responseCB?.success && responseCB?.data) {
            const responseCA = Object.values(responseC.data).flat();
            const timeZone = selectedFacility.timeZone;
            this.casparData = responseCA.reduce((acc: any, cur: any) => {
              cur.displayName = CASPAR_ICON_ORDER[cur.type]?.displayName;
              if (cur.alert_time) {
                cur.alertTime = formatInTimeZone(new Date(cur.alert_time * 1000), timeZone, 'M/d/yyyy h:mm a');
              }
              if (this.dashboardData.length) {
                const wearer = this.dashboardData.find((item: any) => item.patientId === cur.patient_id);
                if (wearer) {
                  cur.wearerName = wearer.localId || wearer.displayName;
                  cur.wearerId = wearer.wearerId;
                  this.setEntityStatesWithAlerts(wearer, cur);
                }
              }
              return {
                ...acc, [cur.patient_id]: acc[cur.patient_id] ? { ...acc[cur.patient_id], [cur.type]: cur } : { [cur.type]: cur }
              }
            }, {});
          }
          if (responseCB?.success && responseCB?.data) {
            const responseCBA = Object.keys(responseCB.data);
            for (const cur of responseCBA) {
              const tempType = responseCB.data[cur] ? BED_IN : BED_OUT;
              const obj = { displayName: CASPAR_ICON_ORDER[tempType]?.displayName, type: tempType };
              if (this.casparData[cur]) {
                this.casparData = { ...this.casparData, [cur]: { ...this.casparData[cur], [obj.type]: obj } }
              } else {
                this.casparData[cur] = {
                  [obj.type]: obj
                };
              }
            }
          }
          this.getCasparDataSub.next(this.casparData);
        }
      });
    });
  }

  setEntityStatesWithAlerts(data: any, alert: any) {
    if (NOTABLE_ALERTS.indexOf(alert.type) !== -1) {
      data.entityIconColor = notableColors.warning;
      data.isCasparNotableState = true;
    }
  }

  setEntityStates(data: any) {
    let mapPinColor = notableColors.offline;
    const locationState = +data.locationState;
    if (locationState === locationStates.ok || locationState === locationStates.locon) {
      mapPinColor = notableColors.blueOk;
    } else if (locationState === locationStates.warning) {
      mapPinColor = notableColors.warning;
    } else if (locationState === locationStates.caution) {
      mapPinColor = notableColors.caution;
    }
    // else if (locationState === locationStates.locon) {
    //   mapPinColor = notableColors.darkGrey;
    // }
    data.mapPinColor = mapPinColor;

    let tempIconColor = notableColors.offline;
    const wearerState = +data.wearerState;
    if (wearerState === wearerStates.healthy) {
      tempIconColor = notableColors.greenOk;
    } else if (wearerState === wearerStates.tempWarning) {
      tempIconColor = notableColors.warning;
    } else if (wearerState === wearerStates.tempCaution || wearerState === wearerStates.tempIndeterminate) {
      tempIconColor = notableColors.caution;
    } else if (wearerState === wearerStates.offline || wearerState === wearerStates.newWearer) {
      data.mapPinColor = notableColors.offline;
    }
    data.tempIconColor = tempIconColor;

    let entityIconColor = notableColors.offline;
    if (data.metaData === META_ASSETS || data.isTag) {
      let assetIconColor = notableColors.offline;
      //  && +data.locationState === locationStates.ok
      const assetState = +data.assetState;
      const bandState = +data.bandState;
      if (assetState === assetStates.online) {
        entityIconColor = notableColors.blueOk;
        assetIconColor = notableColors.blueOk;
      } else if (bandState === wearerStates.lowBattery) {
        entityIconColor = notableColors.caution;
        assetIconColor = notableColors.caution;
      }
      data.assetIconColor = assetIconColor;
    } else {
      if ((!data.isTag && wearerState === wearerStates.tempWarning) || locationState === locationStates.warning) {
        entityIconColor = notableColors.warning;
      } else if ((!data.isTag && (wearerState === wearerStates.tempCaution || wearerState === wearerStates.tempIndeterminate)) || locationState === locationStates.caution) {
        entityIconColor = notableColors.caution;
      } else if (wearerState === wearerStates.unworn || wearerState === wearerStates.offline || locationState === locationStates.offline) {
        // states.unworn = states.unworn + 1;
      } else if ((wearerState === wearerStates.healthy && locationState === locationStates.ok) || (wearerState === wearerStates.healthy && locationState === locationStates.locon) || (wearerState === wearerStates.healthy && !data.location)) {
        entityIconColor = notableColors.greenOk;
      } else if ((wearerState === wearerStates.unworn && locationState === locationStates.ok)) {
        entityIconColor = notableColors.blueOk;
      }
    }
    data.entityIconColor = entityIconColor;
  }

  getCurrentTimeInTimezone() {
    const selectedFacility = this.headerService.getCurrentFacility;
    const tz = selectedFacility.timeZone;
    const now = new Date();
    const options: any = {
      timeZone: tz,
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    };
    return new Intl.DateTimeFormat('en-US', options).format(now);
  }

  getTimeStamps() {
    const selectedFacility = this.headerService.getCurrentFacility;
    const tz = selectedFacility.timeZone;
    const now = new Date();
    return {
      startTime: subDays(now, 1).valueOf(),
      endTime: now.valueOf(),
      timeZone: tz,
    };
  }

  /**
   * Sort Dashboard data according to user preference
   * @param dashBoardData -
   * @param sortingType - firstName | lastName | localId
   */
  // this.storageService.getLocalStorage(
  //   StorageEnum.sortingType,
  //   true
  // ) ||
  sortDashboardData(
    dashBoardData: DashboardData[],
    sortingType: SortingTypeModel = new SortingTypeModel()
  ): DashboardData[] {
    const highPriorityList: DashboardData[] = dashBoardData
      .filter((wearer) => wearer.sortOrder < locationAlertThresholdState)
      .sort((a, b) => a.sortOrder - b.sortOrder);
    const lowPriorityList: DashboardData[] = dashBoardData.filter(
      (wearer) => wearer.sortOrder >= locationAlertThresholdState
    );
    let sortingKey = sortingType.value;
    if (sortingType.fallback?.length) {
      const wearerGroups =
        this.storageService.getSessionStorage(
          StorageEnum.filteredGroups,
          true
        ) || [];
      const selected = wearerGroups.find((wGroup: any) => wGroup.isOn);
      if (selected?.filterKey) {
        sortingKey =
          sortingType.fallback.find(
            (item: any) =>
              item.filterKey === selected.filterKey &&
              item.filterValue === selected.filterValue
          )?.property || '';
      }
    }
    const notNullList = lowPriorityList.filter((item: any) => (item[sortingKey] && item.sortingKey !== null));
    const nullList = lowPriorityList.filter((item: any) => !item[sortingKey] || item.sortingKey === null);
    const sortedNullList = this.uiUtilityService.sortList(
      nullList,
      sortingType.fallbackKey || SortingTypeEnum.displayName,
      sortingType.order
    );
    const sortedLowPriorityList = this.uiUtilityService.sortList(
      notNullList,
      sortingKey,
      sortingType.order
    );
    return [...highPriorityList, ...sortedLowPriorityList, ...sortedNullList];
  }

  /**
   * Filter wearer list against wearer group
   * @param wearerGroups - wearer group to filter wearer
   */
  // this.storageService.getSessionStorage(
  //   StorageEnum.filteredGroups,
  //   true
  // ) ||
  filterDashboardData(
    wearerGroups: WearerGroupModel[] = [],
    sortingType?: any
  ) {
    const selected = wearerGroups.find((wGroup) => wGroup.isOn);
    if (selected?.filterKey) {
      const filteredData = this.dashboardData.filter(
        (item: any) => item[selected.filterKey] === selected.filterValue
      );
      return sortingType ? this.sortDashboardData(filteredData, sortingType) : filteredData;
    }
    return sortingType
      ? this.sortDashboardData(this.dashboardData, sortingType)
      : this.dashboardData;
  }

  /**
   * Convert temperature value of each hour in [0-100] range to set the bar height.
   * @param temperature - A temperature value of 24 hours trend data
   */
  calBarHeight(temperature: number): number {
    let factor = 14;
    let minOkValue = 35;
    let maxOffset = 5;
    const maxHeight = 100;
    const baseHeight = 30;
    const offlineHeight = 25;
    const noDataTempHeight = 15;
    const offset: number = temperature - minOkValue;
    const barHeight =
      !temperature && !isNaN(offset)
        ? noDataTempHeight
        : isNaN(offset)
          ? offlineHeight
          : offset >= 0 && offset <= maxOffset
            ? baseHeight + offset * factor
            : offset > maxOffset
              ? maxHeight
              : offlineHeight;
    return barHeight;
  }

  /**
   * Calculates the color of the bar from the color value of each hour.
   * @param temperature -
   */
  calBarColor(colorCode: string): string {
    switch (+colorCode) {
      case 1:
        return barColor.warning;
      case 2:
        return barColor.caution;
      case 3:
        return barColor.offline;
      case 7:
        return barColor.gTemp;
      case 8:
        return barColor.gTemp;
      default:
        return barColor.offline;
    }
  }

  /**
   * Calculates color code for the latest temperature based on wearer state
   * @param wearerState - State of wearer
   * @return string
   */
  getTemperatureColor(wearerState: number): string {
    switch (wearerState) {
      case 1:
        return colorClasses.warning;
      case 2:
        return colorClasses.caution;
      case 3:
        return colorClasses.caution; //Indeterminate
      case 5:
      case 6:
        return colorClasses.offline;
      case 7:
        return colorClasses.ok;
      case 8:
        return colorClasses.ok;
      default:
        return colorClasses.offline;
    }
  }

  /**
   * Convert temperature value from celsius to fahrenheit
   * @param celsius -
   */
  temperatureBasedOnTempUnitPref(
    dashBoardData: DashboardData,
    unitPref: number
  ): Temperature {
    const skinTemperature =
      unitPref === 1
        ? dashBoardData.currentTemperatureInC
        : dashBoardData.currentTemperatureInF;
    if (
      skinTemperature === '' ||
      skinTemperature === null ||
      skinTemperature === undefined
    ) {
      return {
        color: colorClasses.offline,
        value: null,
      };
    } else {
      let tempValue = '–';
      if (dashBoardData.wearerState === '3') {
        return {
          color: this.getTemperatureColor(+dashBoardData.wearerState),
          value: tempValue,
        };
      } else if (
        (+skinTemperature >= 30 && unitPref === 2) ||
        (+skinTemperature >= 86 && unitPref === 1)
      ) {
        tempValue = parseFloat(skinTemperature.toString()).toFixed(1);
      }
      return {
        color: this.getTemperatureColor(+dashBoardData.wearerState),
        value: tempValue,
      };
    }
  }

  /**
   * Manipulate Band state and Wearer state to  get wearer notable state
   * class - To handle notable state color
   * icon - To handle band icon
   * state - To notable state text
   * description - To handle description about notable state
   * @param data - Dashboard data
   */
  getNotableState(data: DashboardData): any {
    let state = 0;
    if (+data.wearerState) {
      state = +data.wearerState;
    } else {
      state = +data.bandState;
    }
    if (state === 4) {
      return null;
    }
    return {
      class: this.getColorClasses(state),
      icon: this.getBandIcon(+data.bandState),
      stateMsg: this.getNotableStateMsg(state),
      state,
      description: this.getNotableStateDesc(state),
    };
  }

  /**
   * Get band icon based on user status
   * @param status - Band State
   */
  getBandIcon(status: number): string {
    switch (+status) {
      case 3:
        return bandIcons.iconOk;
      case 4:
        return bandIcons.iconLowBatt;
      case 7:
      case 8:
        return bandIcons.iconOk;
      case 9:
        return bandIcons.iconOffline;
      case 10:
        return bandIcons.iconUnworn;
      default:
        return bandIcons.iconOffline;
    }
  }

  /**
   * Get notable state description
   * @param status -
   */
  getNotableStateDesc(status: number): string {
    switch (+status) {
      case 1:
        return notableStateMessage.warning;
      case 2:
        return notableStateMessage.caution;
      case 3:
        return notableStateMessage.indeterminate;
      case 4:
        return notableStateMessage.lowBattery;
      case 7:
        return '';
      case 8:
      case 9:
        return notableStateMessage.offline;
      case 10:
        return notableStateMessage.unworn;
      case 11:
        return notableStateMessage.noStatus;
      default:
        return notableStateMessage.offline;
    }
  }

  getNotableStateMsg(status: number): string {
    switch (+status) {
      case 1:
        return bandStates.warning;
      case 2:
        return bandStates.caution;
      case 3:
        return bandStates.indeterminate;
      case 4:
        return bandStates.lowBattery;
      case 7:
        return '';
      case 8:
      case 9:
        return bandStates.offline;
      case 10:
        return bandStates.unworn;
      case 11:
        return bandStates.noStatus;
      default:
        return bandStates.offline;
    }
  }

  /**
   * Get notable state message's color classes
   * @param status -
   */
  getColorClasses(status: number): string {
    switch (+status) {
      case 1:
        return colorClasses.warning;
      case 2:
      case 3:
        return colorClasses.caution;
      case 4:
      case 7:
        return colorClasses.ok;
      default:
        return colorClasses.offline;
    }
  }

  /**
   * Calculates the difference between system time and last temperature reading timestamp
   * @param timeStamp - Dashboard data
   */
  convertLTSOffset(timeStamp: number): LastUpdatedTime {
    const currentTime: Date = new Date();
    const lts: Date = new Date(timeStamp);
    const timeDiff = (currentTime.getTime() - lts.getTime()) / 60000;
    let timestampText = '';
    let color = '';
    if (timeDiff >= 120) {
      timestampText = `1+ hrs Ago`;
      color = 'warning';
    } else {
      timestampText =
        Math.round(timeDiff) +
        (Math.round(timeDiff) < 2 ? ' minute ago' : ' minutes ago');
      color = '';
    }
    return {
      color,
      value: timestampText,
    };
  }

  getDashBoardRefreshEvent() {
    return this.refreshDashBoardPage.asObservable();
  }

  notifyDashBoardRefresh() {
    this.refreshDashBoardPage.next(true);
  }

  getLocationInfo(data: DashboardData): LocationAlert {
    let state = 0;
    if (data.locationState) {
      state = +data.locationState;
    }
    return {
      class: this.getLocationLabelClass(state),
      icon: this.getLocationPin(state),
      label: this.getLocationStateLabel(state),
      state,
      description: this.getLocationStateInfo(state),
    };
  }

  /**
   * Get location icon based on location status
   * @param status - Location State
   */
  getLocationPin(status: number): string | null {
    switch (+status) {
      case locationStates.warning:
        return locationIcons.warning;
      case locationStates.caution:
        return locationIcons.caution;
      case locationStates.ok:
        return locationIcons.ok;
      case locationStates.offline:
        return locationIcons.offline;
      case locationStates.locon:
        return locationIcons.locon;
      default:
        return null;
    }
  }

  /**
   * Get location state label
   * @param status -
   */
  getLocationStateLabel(status: number): string {
    switch (+status) {
      case locationStates.warning:
        return LocationStateText.warning;
      case locationStates.caution:
        return LocationStateText.caution;
      default:
        return '';
    }
  }

  /**
   * Get notable state Message
   * @param status -
   */
  getLocationStateInfo(status: number): string {
    switch (+status) {
      case locationStates.warning:
        return LocationStateDescription.warning;
      case locationStates.caution:
        return LocationStateDescription.caution;
      default:
        return '';
    }
  }

  /**
   * Get notable state message's color classes
   * @param status -
   */
  getLocationLabelClass(status: number): string {
    switch (+status) {
      case locationStates.warning:
        return colorClasses.warning;
      case locationStates.caution:
        return colorClasses.caution;
      case locationStates.ok:
        return colorClasses.ok;
      case locationStates.offline:
        return colorClasses.offline;
      case locationStates.locon:
        return colorClasses.lbalck;
      default:
        return colorClasses.offline;
    }
  }

  /**
   * Sort Dashboard data according to user preference
   * @param locationState
   * @param wearerState
   */
  getSortOrderForWearer(locationState: number, wearerState: number) {
    let sortOrder = 99999;
    if (!locationState && !wearerState) {
      return sortOrder;
    }
    let multiplier = 400;
    if (
      notableLocationStates.indexOf(locationState) !== -1 ||
      wearerState === wearerStates.tempWarning
    ) {
      multiplier = 100;
    }
    sortOrder = locationState * multiplier + wearerState;
    return sortOrder;
  }

  generateRandomInteger(min: number, max: number) {
    return Math.floor(min + Math.random() * (max - min + 1));
  }

  getCurrentWearer() {
    return this.currentWearer.asObservable();
  }

  wearerIconAndGroupName(group: number) {
    switch (group) {
      case 1:
        return 'wearer';
      case 2:
        return 'caregiver';
      case 3:
        return 'asset';
      default:
        return 'wearer';
    }
  }

  getSingularFormWearerGroup(gName: string) {
    if (gName.toLowerCase().includes('resident')) {
      return WearerGroupEnum.resident;
    } else if (gName.toLowerCase().includes('caregiver')) {
      return WearerGroupEnum.caregiver;
    } else if (gName.toLowerCase().includes('asset')) {
      return WearerGroupEnum.asset;
    } else {
      return '';
    }
  }

  getWearerNotableEvents(residentID: any): Observable<any> {
    const timeStamps = this.getTimeStamps();
    const url = `${environment.apiUrl.unifiedDashboard}${residentID}/${EndPointsEnum.getWearerNotableEvents}?startTime=${timeStamps.startTime}&endTime=${timeStamps.endTime}&timeZone=${timeStamps.timeZone}`;
    return this.dataService.getData(url);
  }

  getAssetNotableEvents(assetId: any): Observable<any> {
    const timeStamps = this.getTimeStamps();
    const url = `${environment.apiUrl.unifiedDashboard}${assetId}/${EndPointsEnum.getAssetNotableEvents}?startTime=${timeStamps.startTime}&endTime=${timeStamps.endTime}&timeZone=${timeStamps.timeZone}`;
    return this.dataService.getData(url);
  }

  getBedSummary(): Observable<any> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/bedSummary`;
    return this.dataService.getData(url);
  }

  getAssetSummary(): Observable<any> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/assetSummary`;
    return this.dataService.getData(url);
  }

  getFloorDataForDashBoard(): Observable<any> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/getFloorDataForDashBoard`;
    return this.dataService.getData(url);
  }

  getLocationDataForFacility(): Observable<any> {
    const url = `${environment.apiUrl.location}getLocationDataForFacility/${FACILITY_ID_URL}?requestId=${this.requestId}`;
    return this.dataService.getData(url);
  }

  getBedPosition(bedID: any): Observable<ApiResponseModel<any>> {
    const url = `${environment.apiUrl.unifiedDashboard}${FACILITY_ID_URL}/${EndPointsEnum.getBedPosition}/${bedID}`;
    return this.dataService.getData(url);
  }
}

