import {
  UnifiedInput,
  ControlType,
} from 'src/app/shared/components/unified-custom-input/unified-input.types';

export const bedDetailsInputConfig: UnifiedInput[] = [
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Name',
    label: 'Name',
    value: '',
    detail: false,
    name: 'bedName',
    validator: 'abstractName',
    required: true,
    editRule: 'editBedDetails'
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Bed ID',
    label: 'Bed ID',
    value: '',
    detail: false,
    name: 'bedId',
    showNone: true,
    validator: 'localId',
    editRule: 'editBedDetails'
  },
  {
    controlType: ControlType.TOGGLE,
    type: 'text',
    placeholder: 'Connexio Enabled',
    label: 'Connexio Enabled',
    value: true,
    detail: false,
    name: 'connexioEnabled',
    editRule: 'editBedDetails'
  },
];
