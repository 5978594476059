<!-- <ion-grid> -->
<ion-row class="row">
  <ion-col size-xs="12" size-sm="12" size-md="12" size-lg="12" class="padding-less-header col-2">
    <app-header (onOpenSummary)="togglePopulationSummary()" [header]="'main'"></app-header>
  </ion-col>
  <ion-col size-xs="0" size-sm="0" size-md="0" size-lg="0" class="brand-logo-col col-3">
    <div _ngcontent-ng-c1945850961="" class="brand-logo">
      <img _ngcontent-ng-c1945850961="" src="assets/svg/logo.svg" alt="Joerns Healthcare">
    </div>
    <div [ngClass]="{'add-backdrop': isSettingModalOpen || isMenuOpen}"></div>
  </ion-col>
</ion-row>
<!-- </ion-grid> -->

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)" *ngIf="isMobile">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-grid class="custom-row ion-no-padding dashboard-grid">
    <ion-row class="ion-no-padding row dashboard-row" [@animSlider]="counter">
      <ion-col size-xs="12" size-sm="12" size-md="5" size-lg="4" class="dashboard-col col-1"
        *ngIf="loadWearerComponent">
        <div class="flex-container-column dark-panels" (swipe)="onSwipeWearerList($event)">
          <app-wearer-group-filter></app-wearer-group-filter>
          <app-wearer-list></app-wearer-list>
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="7" size-lg="8" size-xl="auto"
        [ngClass]="isClick ? 'show-floor': 'hide-fllor'" class="padding-less-header hide-fllor dashboard-col col-2">
        <div *ngIf="isMobile && !loadWearerComponent">
          <app-wearer-group-filter></app-wearer-group-filter>
        </div>
        <div class="full-height" (swipe)="onSwipeFloorDashboard($event)">
          <app-floor-dashboard></app-floor-dashboard>
        </div>
      </ion-col>
      <ion-col size-xs="0" size-sm="0" size-md="0" size-lg="0" class="col-3">
        <app-population-summary></app-population-summary>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>



<ion-footer class="ion-no-border custom-footer">
  <ion-toolbar class="custom-footer-toolbar">
    <div class="segment-container">
      <div class="segment-box">
        <div class="segment-elem" [ngClass]="{'active': propertyIcon}" (click)="loadFloor()">
          <ion-icon class="segment-elem-icon"
            [src]="propertyIcon ? 'assets/icon/cards-active.svg' : 'assets/icon/cards-inactive.svg'"></ion-icon>
          Cards
        </div>
        <div class="segment-elem" [ngClass]="{'active': !propertyIcon}" (click)="loadWearer()">
          <ion-icon class="segment-elem-icon"
            [src]="propertyIcon ? 'assets/icon/floors-inactive.svg' : 'assets/icon/floors-active.svg'"></ion-icon>
          Floors
        </div>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>