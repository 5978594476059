<app-unified-header title="Notifications" (onSave)="saveSettings()" defaultHref="/dashboard"
  [restrictBack]="notificationForm && !notificationForm.pristine"
  [saveBtn]="{title: 'Save', isDisable: isSaveDisabled || isDisable}" isRootPage="true"></app-unified-header>

<ion-content *ngIf="notificationForm">
  <div class="section-label">Notification Settings</div>
  <ion-note class="disable-note" *ngIf="isDisable">Corporate-level accounts do not receive
    notifications. Sign in with a facility-level account to receive notifications.</ion-note>
  <form [formGroup]="notificationForm">
    <ion-item *ngFor="let setting of notificationSettingArr" class="list-with-name" [disabled]="isDisable">
      <ion-label class="item-label">
        <h3>{{setting.displayName}}</h3>
        <ion-icon class="entity-error-icon" src="assets/icon/icons_Hint.svg"
          (click)="onInfo(setting.description)"></ion-icon>
      </ion-label>
      <ion-toggle mode="ios" color="controls" slot="end" [formControlName]="setting.key"></ion-toggle>
    </ion-item>
  </form>
</ion-content>