<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons>
      <ion-back-button defaultHref (click)="goBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>Select Bed</ion-title>
    <ion-buttons slot="end">
      <ion-button [disabled]="!isSaveEnabled" (click)="associateBedToRoom()"> Save </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-searchbar (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar" animated
    placeholder="Type to search...">
  </ion-searchbar>
</ion-header>


<ion-content>
  <ion-list *ngIf="bedList.length; else noRoomsAvailable">
    <ion-radio-group [formControl]="selectBedControl" (ionChange)="isSaveEnabled = true">
      <ion-item *ngFor="let bed of bedList">
        <ion-radio mode="md" color="controls" justify="start" labelPlacement="end" [value]="bed.id">{{bed.bedName}}
          <span *ngIf="bed.id === roomId">(Current)</span>
        </ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
  <ng-template #noRoomsAvailable>
    <div class="ion-text-center">
      <p>No bed available right now.</p>
    </div>
  </ng-template>
</ion-content>