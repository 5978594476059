<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons>
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Room Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddRoom()" [nAccess]="'postRoom'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <app-sub-header [groups]="groups" (onFilterApply)="onFilterApply($event)" (onFilterCancel)="onFilterCancel($event)"
    (onFilterReset)="onFilterReset()"></app-sub-header>
  <ion-searchbar [(ngModel)]="searchQuery" (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar"
    animated placeholder="Type to search...">
  </ion-searchbar>
</ion-header>

<ion-content>
  <!-- <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Room Management</ion-title>
    </ion-toolbar>
  </ion-header> -->
  <ion-list *ngIf="roomListData?.length || searchRoomListData?.length">
    <ng-container *ngFor="let room of roomListData">
      <ion-item detail (click)="navigateToRoomDetails(room.id)" class="list-with-name">
        <ion-label>
          <h3>{{room.roomName}}</h3>
        </ion-label>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 61 63" fill="none">
          <path d="M28 3.5L56 31.5005L28 59.5" stroke="#808080" stroke-width="6" stroke-miterlimit="10" stroke-linecap="round"/>
          </svg> -->
      </ion-item>
    </ng-container>
  </ion-list>
  <div class="no-rooms" *ngIf="!roomListData?.length || !searchRoomListData?.length">
    No rooms found in the selected facility.
  </div>
</ion-content>