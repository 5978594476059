<ng-container>
  <div class="wearer-dashboard">
    <div class="user-list" *ngIf="dashBoardData.length">
      <div class="user-list-scroll">
        <div class="weare-list-card-container" *ngFor="let data of dashBoardData">
          <ion-card class="user-card" button="true" [id]="'cardListWearer'+data.wearerId" *ngIf="data.wearerId"
            [ngClass]="{'active': currentWearerId && currentWearerId === data.wearerId }"
            (click)="showSummary($event, data)">
            <div class="card-container">
              <div class="user-card-details card-container-top-section">
                <div class="room-container" *ngIf="data?.roomName">
                  <span class="room-icon">
                    <ion-icon class="entity-icon-stateless" src="assets/icon/room-location.svg"></ion-icon>
                  </span>
                  <p class="room-name">
                    <span>{{ data.roomName }}</span><span *ngIf="data.slotName && isBedSlotEnabled">-{{ data.slotName
                      }}</span>
                  </p>
                  <!-- <span class="room-icon">
                    <ion-icon
                      src="assets/icon/{{data?.metaData == null ? data.wearerGroupName: data?.metaData}}.svg"></ion-icon>
                  </span>
                  <p class="room-name">
                    <span>{{ data.inBed ? 'In' : 'Out' }}</span>
                  </p> -->
                </div>
              </div>
              <div class="user-card-details">
                <div class="user-block">
                  <div class="wearer-name-block">
                    <p class="uname">
                      <!-- [style.color]="data?.metaData && data?.assetIconColor ? data.assetIconColor : ''" -->
                      <ion-icon class="uname-icon"
                        src="assets/icon/{{data?.metaData == null ? data.wearerGroupName: data?.metaData}}.svg" />
                      <!-- <img src="assets/icon/{{data?.metaData == null ? data.wearerGroupName: data?.metaData}}.svg" /> -->
                      <span class="uname-title"> {{data.shortDisplayName || data.displayName}}</span>
                    </p>
                    <ion-icon *ngIf="data.bandId || data.tagId" class="location-wearer-pin"
                      [src]="!data.location ? 'assets/icon/pin-off.svg' : 'assets/icon/pin.svg'"
                      [style.color]="data.mapPinColor"></ion-icon>
                    <span *ngFor="let alert of alertsIconOrder | keyvalue">
                      <ion-icon *ngIf="casparData[data.patientId]?.[alert.key]" class="warning-icon"
                        src="assets/icon/{{alert.value?.icon}}"></ion-icon>
                    </span>

                    <!-- <span *ngIf="casparData[data.patientId]?.['heart-rate-threshold-alert']">
                      <ion-icon class="warning-icon" src="assets/icon/heartrate_warning.svg"></ion-icon>
                    </span>
                    <span *ngIf="casparData[data.patientId]?.['respiration-threshold-alert']">
                      <ion-icon class="warning-icon" src="assets/icon/respiratory_warning.svg"></ion-icon>
                    </span> -->
                    <span class="intellisense" *ngIf="data.intellisense">
                      <img src="assets/icon/intellisense.svg" />
                    </span>
                    <span *ngIf="data.bandState === 4">
                      <ion-icon class="low-battery-icon" src="assets/icon/low-battery.svg"></ion-icon>
                    </span>
                  </div>
                  <!-- <p class="group">{{ data.wearerGroupName }}</p> -->
                </div>
              </div>
              <!-- ngx-charts-bar-vertical -->
              <div class="chart-section wearer-list-card-chart">
                <div class="temp-chart-container" *ngIf="data.graphDisplay && data.graphDisplay.length && !data.isTag">
                  <div class="temp-icon">
                    <ion-icon [src]="'assets/icon/thermometer.svg'" [style.color]="data.tempIconColor" />
                  </div>
                  <div class="temp-chart" #trend>
                    <ngx-charts-bar-vertical [view]="[trend.offsetWidth, 60]" [results]="data.graphDisplay"
                      [customColors]="data.customColorsTemp" [barPadding]="6" [tooltipDisabled]="true"
                      [roundEdges]="false" [yScaleMin]="0" [yScaleMax]="100" [animations]="false"
                      [noBarWhenZero]="false">
                    </ngx-charts-bar-vertical>
                  </div>
                </div>
              </div>
            </div>
          </ion-card>
        </div>
      </div>
    </div>
    <div class="no-wearer" *ngIf="!dashBoardData?.length">
      {{noMsg}}
    </div>
  </div>
</ng-container>