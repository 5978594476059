<app-unified-header [title]="!wearerId ? 'Add Wearer' : isReadMode ? 'Wearer Details' : 'Edit Wearer'"
  (onSave)="isReadMode ? setEditMode(true) : manageWearer(wearerId ? 'put': 'post')" defaultHref="/wearer-list"
  [restrictBack]="wearerDetailsForm && !wearerDetailsForm.pristine"
  [saveBtn]="{title: wearerId && isReadMode ? 'Edit' : 'Save', isDisable: wearerId && isReadMode ? false : isSaveDisabled, rule: wearerId ? 'putWearer' : ''}"
  [isRootPage]="isRootPage"></app-unified-header>

<ion-content *ngIf="wearerDetailsForm">
  <form [formGroup]="wearerDetailsForm">
    <ng-container *ngFor="let config of wearerDetailsInputConfig">
      <unified-input ngDefaultControl [formControlName]="config.name || null" [config]="getConfig(config)"
        (changed)="inputChanged($event)"></unified-input>
    </ng-container>
    <ion-item *ngIf="!(wearerId && !isReadMode)">
      <ion-label slot="start" class="select-label">Wearer Group</ion-label>
      <ion-select slot="end" formControlName="wearerGroupId">
        <ion-select-option *ngFor="let group of storedGroups" [value]="group.id">{{group.label}}</ion-select-option>
      </ion-select>
    </ion-item>
    <!-- <ion-item></ion-item> -->
    <ion-item>
      <ion-label slot="start" class="select-label">Wearer Status</ion-label>
      <ion-select slot="end" (ionChange)='changeStatus($event)' formControlName="wearerStatus" [disabled]="isReadMode">
        <ion-select-option *ngFor="let status of wearerStatus"
          [value]="status.value">{{status.name}}</ion-select-option>
      </ion-select>
    </ion-item>
    <ng-container *ngIf="wearerId">
      <ng-container *ngIf="isResident">
        <ion-item></ion-item>
        <ion-item class="form-item-detail" *ngIf="!isReadMode && !roomDisabled" detail (click)="roomSwitch()">
          <ion-label>Room</ion-label>
          <ion-text slot="end">{{(wearerDetails?.rooms?.[0]?.roomName) || 'None'}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" detail="false" *ngIf="isReadMode && showRoom && !roomDisabled"
          (click)="navigateToRoomDetails()">
          <ion-label>Room</ion-label>
          <ion-text slot="end" [color]="wearerDetails?.rooms?.[0] ? 'ok' : 'dark-text'"
            [ngClass]="{'app-link': wearerDetails?.rooms?.[0]?.roomName, 'no-link': !isRoomSummaryEnabled}">{{(wearerDetails?.rooms?.[0]?.roomName)
            ||
            'None'}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" *ngIf="isReadMode && showRoom && !roomDisabled"
          (click)="navigateToBedDetails()">
          <ion-label>Bed</ion-label>
          <ion-text slot="end" [color]="wearerDetails?.beds?.[0] ? 'ok' : 'dark-text'"
            [ngClass]="{'app-link': wearerDetails?.beds?.[0]?.bedName, 'no-link': !isBedSummaryEnabled}">{{(wearerDetails?.beds?.[0]?.bedName)
            ||
            'None'}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" *ngIf="!isReadMode && !roomDisabled" (click)="selectBed()" detail>
          <ion-label>Bed</ion-label>
          <ion-text slot="end">{{(wearerDetails?.beds?.[0]?.bedName) || 'None'}}</ion-text>
        </ion-item>
      </ng-container>

      <ng-container *ngIf="isResident && wearerAlerts.length">
        <div class="section-label">Alerts</div>
        <div *ngFor="let alert of wearerAlerts">
          <ion-item>
            <ion-label>{{alert.label}}</ion-label>
            <ion-toggle mode="ios" color="controls" slot="end" [formControlName]="'alertSettings.'+alert.key"
              *ngIf="!isReadMode" (ionChange)="onAlertToggle($event, alert.key)"></ion-toggle>
            <div slot="end" class="custom-input ion-text-right" *ngIf="isReadMode">
              <ion-input [value]="f['alertSettings.'+alert.key]?.value ? 'Yes' : 'No'" readonly></ion-input>
            </div>
          </ion-item>
          <div *ngIf="f['alertSettings.'+alert.key]?.value">
            <ion-item *ngFor="let alert1 of alert.alerts">
              <ion-label class="bed-alert-label select-label">{{alert1.label}}</ion-label>
              <ion-select slot="end" aria-label="Choose" placeholder="Choose" (ionChange)="handleAlertChange($event)"
                [formControlName]="'alertSettings.'+alert1.key" interface="popover" [disabled]="isReadMode">
                <ion-select-option *ngFor="let alertData of alert1.datasetcsv"
                  [value]="alertData.key">{{alertData.displayName}}</ion-select-option>
              </ion-select>
            </ion-item>
          </div>
        </div>
      </ng-container>

      <div class="wearer-item band" *ngIf="isReadMode && !roomDisabled">
        <div class="section-label">Device Association</div>

        <ion-item *ngFor="let band of wearerDetails?.bands" (click)="findBand(band)" detail class="first-item-border">
          <ion-label>{{band?.isTag ? 'ONDO Tag' : 'ONDO Band'}}</ion-label>
          <div slot="end" class="band-id">
            <ion-text> {{band?.bandId || band?.tagId}}</ion-text>
          </div>
        </ion-item>
        <ion-note class="replace-note" *ngIf="wearerDetails?.bands?.length">Access device-specific settings, or replace
          or remove any associated devices from this wearer.</ion-note>
        <ion-note class="replace-note" *ngIf="!wearerDetails?.bands?.length && !isNative">To add or replace a Band/Tag,
          use the mobile app.</ion-note>
        <ion-item detail (click)="findBand()" class="first-item-border"
          *ngIf="!wearerDetails?.bands?.length && isNative">
          <ion-label class="app-link-action">Assign Devices...</ion-label>
        </ion-item>
      </div>
    </ng-container>
  </form>
  <div class="delete-wearer" *ngIf="isReadMode">
    <ion-button color="danger" (click)="deleteWearer()" [nAccess]="'deleteWearer'">Delete This
      Wearer</ion-button>
  </div>
</ion-content>