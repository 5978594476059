import { EndPointsEnum } from "../enums/end-points";

export const APP_VERSION = '2.7.7-QA-B1';
export const NOT_ASSIGNED = 'None';
export const MIN_FILTER_OPTIONS_LENGTH = 1;
export const ALL_GRP_TEXT = 'All Wearer Groups';
export const FILTER_SORT_TYPE = 'sort';
export const FILTER_TOGGLE_TYPE = 'toggle';
export const META_ASSETS = 'Assets';
export const ALERT_LOCATION_STATES = [1, 6];
export const ALERT_BAND_STATES = [8, 9, 11];
export const INGNORE_AUTH = ['/auth/login'];
export const TEMPORARY_PASSWORD = '0';
export const RECOVER_PASSWORD = 'password';
export const FACILITY_ID_URL = '$facility_id';
export const MODAL_PAGE_TYPE = 'detail';
export const ENTITY_ASSET = 'ASSET';
export const ENTITY_BED = 'BED';
export const ENTITY_BRIDGE = 'BRIDGE';
export const ENTITY_WEARER = 'WEARER';
export const PLACEMENT_BED_ICON = 'BED';
export const COMMON_ROOM = 'Common Room';
export const MOBILE_BREAKPOINT_WIDTH = 767;
export const TABLET_BREAKPOINT_WIDTH = 1200;
export const TENANT = 'Connexio';

export const NOTIFICATION_SETTINGS = [{ key: 'tempNotification', displayName: 'Temperature Warning', description: 'You will receive a notification when a wearer has an elevated temperature persisting at or above the alert threshold (1 notification per event every 6 hours).' }, { key: 'locationNotification', displayName: 'Location Warning', description: 'You will receive a notification when a wearer or asset with Location Warning enabled has moved out of range (1 notification per event every 2 hours).' }, { key: 'bedNotification', displayName: 'Bed Alerts', description: 'You will receive a notification when a resident has any active bed alert warnings (1 notification per event every 2 hours).' }];

export const PERMISSIONS: any = {
  [EndPointsEnum.getCorpAccount]: {
    code: 'ONDO_TR_260',
    action: 'GET'
  },
  [EndPointsEnum.editCorpAccount]: {
    code: 'ONDO_TR_219',
    action: 'PUT'
  },
  [`get${EndPointsEnum.manageFacility}`]: {
    code: 'ONDO_TR_265',
    action: 'GET'
  },
  [`put${EndPointsEnum.manageFacility}`]: {
    code: 'ONDO_TR_269',
    action: 'PUT'
  },
  [`post${EndPointsEnum.manageFacility}`]: {
    code: 'ONDO_TR_243',
    action: 'POST'
  },
  [`delete${EndPointsEnum.manageFacility}`]: {
    code: 'ONDO_TR_298',
    action: 'DELETE'
  },
  [EndPointsEnum.getFacilityFloorsList]: {
    code: 'ONDO_TR_209',
    action: 'GET'
  },
  [`get${EndPointsEnum.manageFloors}`]: {
    code: 'ONDO_TR_104',
    action: 'GET'
  },
  [`put${EndPointsEnum.managefloors}`]: {
    code: 'ONDO_TR_168',
    action: 'PUT'
  },
  [`post${EndPointsEnum.managefloors}`]: {
    code: 'ONDO_TR_212',
    action: 'POST'
  },
  [`delete${EndPointsEnum.managefloors}`]: {
    code: 'ONDO_TR_196',
    action: 'DELETE'
  },
  ['getWearerList']: {
    code: 'ONDO_TR_188',
    action: 'GET'
  },
  ['getWearerDetail']: {
    code: 'ONDO_TR_270',
    action: 'GET'
  }, ['putWearer']: {
    code: 'ONDO_TR_216',
    action: 'PUT'
  }, ['getWearerGroupList']: {
    code: 'ONDO_TR_236',
    action: 'GET'
  }, ['postWearer']: {
    code: 'ONDO_TR_194',
    action: 'POST'
  }, ['deleteWearer']: {
    code: 'ONDO_TR_244',
    action: 'DELETE'
  }, ['getAssetList']: {
    code: 'ONDO_TR_116',
    action: 'GET'
  }, ['getAssetDetail']: {
    code: 'ONDO_TR_201',
    action: 'GET'
  }, ['putAsset']: {
    code: 'ONDO_TR_253',
    action: 'PUT'
  }, ['postAsset']: {
    code: 'ONDO_TR_223',
    action: 'POST'
  }, ['deleteAsset']: {
    code: 'ONDO_TR_239',
    action: 'DELETE'
  }, ['getBedList']: {
    code: 'ONDO_TR_210',
    action: 'GET'
  }, ['getBedDetail']: {
    code: 'ONDO_TR_284',
    action: 'GET'
  }, ['putBed']: {
    code: 'ONDO_TR_233',
    action: 'PUT'
  }, ['postBed']: {
    code: 'ONDO_TR_278',
    action: 'POST'
  }, ['deleteBed']: {
    code: 'ONDO_TR_227',
    action: 'DELETE'
  }, ['getUserList']: {
    code: 'ONDO_TR_198',
    action: 'GET'
  }, ['getUserDetail']: {
    code: 'ONDO_TR_241',
    action: 'GET'
  }, ['putUser']: {
    code: 'ONDO_TR_131',
    action: 'PUT'
  }, ['postUser']: {
    code: 'ONDO_TR_177',
    action: 'POST'
  }, ['deleteUser']: {
    code: 'ONDO_TR_226',
    action: 'DELETE'
  }, ['getBridgeList']: {
    code: 'ONDO_TR_229',
    action: 'GET'
  }, ['getBridgeDetail']: {
    code: 'ONDO_TR_161',
    action: 'GET'
  }, ['putBridge']: {
    code: 'ONDO_TR_206',
    action: 'PUT'
  }, ['postBridge']: {
    code: 'ONDO_TR_178',
    action: 'POST'
  }, ['deleteBridge']: {
    code: 'ONDO_TR_231',
    action: 'DELETE'
  }, ['getRoomList']: {
    code: 'ONDO_TR_191',
    action: 'GET'
  }, ['getRoomDetail']: {
    code: 'ONDO_TR_163',
    action: 'GET'
  }, ['putRoom']: {
    code: 'ONDO_TR_174',
    action: 'PUT'
  }, ['postRoom']: {
    code: 'ONDO_TR_217',
    action: 'POST'
  }, ['deleteRoom']: {
    code: 'ONDO_TR_101',
    action: 'DELETE'
  }, ['getBandLookup']: {
    code: 'ONDO_TR_237',
    action: 'GET'
  }, ['getCorporateAccountsList']: {
    code: 'ONDO_TR_272',
    action: 'GET'
  }, ['editBedDetails']: {
    code: 'ONDO_TR_302',
    action: 'PUT'
  }
};
