<ion-header>
  <ion-toolbar mode="ios" class="modal-toolbar">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Wearer Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddWearer()" [nAccess]="'postWearer'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <app-sub-header [groups]="groups" (onFilterApply)="onFilterApply($event)" (onFilterCancel)="onFilterCancel($event)"
    (onFilterReset)="onFilterReset()"></app-sub-header>
</ion-header>

<ion-content>
  <div *ngIf="wearerList?.length || searchWearerList?.length">
    <ion-searchbar [(ngModel)]="searchQuery" (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar"
      animated placeholder="Type to search...">
    </ion-searchbar>
    <!-- <ion-content [scrollY]="false"> -->
    <!-- <cdk-virtual-scroll-viewport class="ion-content-scroll-host" itemSize="45" minBufferPx="900" maxBufferPx="1350"> -->
    <!-- <ion-item-divider *virtualHeader="let groupAlphabet">
          <ion-label>{{groupAlphabet}}</ion-label>
        </ion-item-divider> -->
    <ion-item *ngFor="let wearer of wearerList" (click)="navigateToDetailsPage(wearer)" detail class="list-with-name">
      <!-- <ion-label>{{wearer.firstName + ' ' + wearer.lastName}}</ion-label> -->
      <ion-label class="list-name">{{wearer.displayName}}</ion-label>
      <ion-label slot="end" class="no-margin-right end-name-max" *ngIf="wearer.roomName || wearer.bedName">
        <h3>
          <span [ngClass]="{'offline-text': !wearer.bedName}">{{wearer.bedName || 'No bed'}}</span><span
            class="list-name-separator">||</span><span class="end-name-right"
            [ngClass]="{'offline-text': !wearer.roomName}">{{wearer.roomName
            || 'No room'}}</span>
        </h3>
      </ion-label>
    </ion-item>
    <!-- Your existing content and configurations -->
    <!-- </cdk-virtual-scroll-viewport> -->
    <!-- </ion-content> -->
    <!-- <ion-virtual-scroll [items]="wearerList" [approxItemHeight]="'45'" [headerFn]="groupByAlphabet">
      <ion-item-divider *virtualHeader="let groupAlphabet">
        <ion-label>{{groupAlphabet}}</ion-label>
      </ion-item-divider>
      <ion-item *virtualItem="let wearer" (click)="navigateToDetailsPage(wearer)">
        <ion-label>{{wearer.firstName + ' ' + wearer.lastName}}</ion-label>
      </ion-item>
  </ion-virtual-scroll> -->
  </div>
  <div class="no-wearer" *ngIf="!wearerList?.length">
    No wearers found in the selected facility.
  </div>

</ion-content>