import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { DataService } from 'src/app/core/services/api/data.service';
import { HeaderService } from 'src/app/core/services/header.service';
import {
  InputConfigService,
  InputValidator,
} from 'src/app/core/services/input-config.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { HttpRequestTypeEnum } from 'src/app/shared/enums/http-request-type.enum';
import { InputEnum } from 'src/app/shared/enums/input-field.enum';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';
import { FacilityModel } from 'src/app/shared/models/facility.model';
import {
  LoginResponseModel,
  ValidationConfig,
} from 'src/app/shared/models/login-response.model';
import { environment } from 'src/environments/environment';
import { FacilityService } from '../services/facility.service';
import {
  AlertController,
  IonNav,
  IonicModule,
  MenuController,
  NavParams,
} from '@ionic/angular';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { FloorListPage } from '../facility-floors/floor-list/floor-list.page';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { AuthService } from 'src/app/core/services/auth.service';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { ControlType, FormError } from 'src/app/shared/components/unified-custom-input/unified-input.types';

@Component({
  standalone: true,
  selector: 'app-add-facility',
  templateUrl: './add-facility.page.html',
  styleUrls: ['./add-facility.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
})
export class AddFacilityPage implements OnInit {
  facilityDetails: FacilityModel = new FacilityModel();
  isAddFacility = false;
  isSubmitted = false;
  editEnable: boolean = false;
  isSaveDisabled: boolean = true;
  destroyRef = inject(DestroyRef);
  timeZones = [
    'US/Hawaii',
    'US/Alaska',
    'US/Pacific',
    'US/Arizona',
    'US/Mountain',
    'US/Central',
    'US/Eastern',
    'Asia/Kolkata',
    'Japan',
  ];
  facilityDetailsForm: FormGroup | null = null;
  validators: InputValidator[] = [];
  formConfig = {
    abstractName: {} as ValidationConfig,
    repreName: {} as ValidationConfig,
    corpPhone: {} as ValidationConfig,
    email: {} as ValidationConfig,
  };
  EndPoints = EndPointsEnum;
  isRootPage: boolean = false;
  fieldConfig: any = {
    facilityName: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Facility Name',
      label: 'Facility Name',
      value: '',
      detail: false,
      name: 'facilityName',
      showNone: true,
      validator: 'abstractName',
      required: true
    },
    facilityPhone: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Facility Phone',
      label: 'Facility Phone',
      value: '',
      detail: false,
      name: 'facilityPhone',
      showNone: true,
      validator: 'userPhone'
    },
    facilityEmail: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Facility Email',
      label: 'Facility Email',
      value: '',
      detail: false,
      name: 'facilityEmail',
      showNone: true,
      validator: 'userEmail'
    },
    facilityRepresentativeName: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Facility Representative Name',
      label: 'Facility Representative Name',
      value: '',
      detail: false,
      name: 'facilityRepresentativeName',
      showNone: true,
      validator: 'abstractName',
    },
    facilityRepresentativeTitle: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Facility Representative Title',
      label: 'Facility Representative Title',
      value: '',
      detail: false,
      name: 'facilityRepresentativeTitle',
      showNone: true,
      validator: 'abstractName'
    }
  };

  constructor(
    private storageService: StorageService,
    private dataService: DataService<FacilityModel[]>,
    private uiUtilityService: UiUtilityService,
    private facilityService: FacilityService,
    private headerService: HeaderService,
    private fb: FormBuilder,
    private inputConfigService: InputConfigService,
    private ionNav: IonNav,
    private navParams: NavParams,
    private alertController: AlertController,
    private authService: AuthService,
    private dashboardService: DashboardService,
    private menuCtrl: MenuController
  ) { }

  ngOnInit() {
    this.facilityDetailsForm = this.fb.group({
      facilityName: [''],
      facilityPhone: [''],
      facilityEmail: [''],
      facilityRepresentativeName: [''],
      facilityRepresentativeTitle: [''],
      timeZone: ['', [Validators.required]],
    });
    this.checkForAddFacility();
    // this.facilityDetailsForm.valueChanges.subscribe(value => {
    //   if (this.facilityDetailsForm?.valid) {
    //     this.isSaveDisabled = false
    //   } else {
    //     this.isSaveDisabled = true;
    //   }
    //   // this.subscribeForm();
    //   console.log('name has changed:', value)
    // });
  }

  ionViewWillEnter() {
    this.didViewWillEnter();
  }

  checkForAddFacility() {
    const facilityId: string = this.navParams.get('facilityId');
    if (facilityId) {
      let facilityList: FacilityModel[] = this.storageService.getSessionStorage(
        StorageEnum.facilityList,
        true
      );
      if (!facilityList) {
        this.facilityService
          .getFacilityList()
          .subscribe((response: ApiResponseModel<any>) => {
            if (response && response.success) {
              facilityList = response.data!;
              this.storageService.setSessionStorage(
                StorageEnum.facilityList,
                facilityList,
                true
              );
              this.setCurrentFacilityDetails(facilityList, facilityId);
            } else {
              const header = 'Error';
              this.uiUtilityService.showAlert(response.errorMessage!, header);
            }
          });
      } else {
        this.setCurrentFacilityDetails(facilityList, facilityId);
      }
    } else {
      this.isAddFacility = true;
      this.facilityDetails.corporateId = this.facilityService.corporateId;
      this.patchFormValue();
    }
  }

  setCurrentFacilityDetails(facilityList: FacilityModel[], facilityId: string) {
    this.facilityDetails =
      facilityList.find(
        (facility: FacilityModel) => facility.facilityId === facilityId
      ) || this.facilityDetails;
    this.patchFormValue();
  }

  patchFormValue() {
    this.facilityDetailsForm?.patchValue(this.facilityDetails);
    this.facilityDetailsForm?.markAsPristine();
  }

  async manageFacility(operationType: string) {
    const manageFacilityUrl = `${environment.apiUrl.corporate}${EndPointsEnum.manageFacility}`;
    const { corpId } = this.authService.getLoginData;
    this.facilityDetails.corporateId = corpId;
    this.isSubmitted = true;
    this.facilityDetails = {
      ...this.facilityDetails,
      ...this.facilityDetailsForm?.value,
    };
    let manageApiObservable: Observable<any>;
    switch (operationType) {
      case HttpRequestTypeEnum.post:
        manageApiObservable = this.dataService.post(
          manageFacilityUrl,
          this.facilityDetails
        );
        break;
      case HttpRequestTypeEnum.put:
        manageApiObservable = this.dataService.put(
          manageFacilityUrl,
          this.facilityDetails
        );
        break;
      case HttpRequestTypeEnum.delete:
        manageApiObservable = this.dataService.delete(
          manageFacilityUrl,
          this.facilityDetails
        );
        break;
      default:
        manageApiObservable = this.dataService.post(
          manageFacilityUrl,
          this.facilityDetails
        );
    }
    await this.uiUtilityService.showLoader();
    manageApiObservable.subscribe((response: ApiResponseModel<any>) => {
      if (response && response.success) {
        // once facility data is updated, we need to update login data stored.
        const loginData: LoginResponseModel =
          this.storageService.getLocalStorage(StorageEnum.loginData, true);
        let facility = loginData.facility.find(
          (fac) => fac.facilityId == this.facilityDetails.facilityId
        );
        if (facility) {
          facility.timeZone = this.facilityDetails.timeZone;
          facility.facilityName = this.facilityDetails.facilityName;
        } else {
          facility = response.data;
          this.facilityDetails = response.data;
          if (facility) {
            facility.timeZone = this.facilityDetails.timeZone;
            facility.facilityName = this.facilityDetails.facilityName;
            loginData.facility.push(facility);
          }
        }
        this.storageService.setLocalStorage(
          StorageEnum.loginData,
          loginData,
          true
        );
        this.facilityService.updateFacilityHeader(true);
        if (this.headerService.getFacilityId() == facility?.facilityId) {
          // if you're updating data for selected facility
          // update also the session storage to get current facility selected.
          this.storageService.setSessionStorage(
            StorageEnum.selectedFacility,
            facility,
            true
          );
        }

        // After successful delete navigate to list page
        if (HttpRequestTypeEnum.delete === operationType) {
          this.navigateBack();
        } else {
          // After Add/Edit make it details page
          this.editEnable = false;
          this.isAddFacility = false;
          this.facilityDetailsForm?.markAsPristine();
        }
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  viewFloorList() {
    const facilityId: string = this.navParams.get('facilityId') || this.facilityDetails?.facilityId;
    this.ionNav.push(FloorListPage, { facilityId });
  }

  deleteFacility() {
    const header = 'Delete This Facility';
    const message =
      'You are about to delete this facility. Once deleted, ' +
      'you cannot monitor the wearers and the user accounts created for this facility will be deactivated. Would you like to delete this facility?';
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'Delete',
        handler: () => {
          this.confirmDelete();
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  confirmDelete() {
    const header = 'Confirm Delete';
    const message =
      'This action cannot be undone. Are you sure you’d like to delete this facility?';
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'Delete',
        handler: () => {
          this.manageFacility(HttpRequestTypeEnum.delete);
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  get(controlName: string): AbstractControl {
    return this.facilityDetailsForm?.get(controlName)!;
  }

  backClick() {
    if (this.editEnable || this.isAddFacility) {
      if (!this.facilityDetailsForm?.pristine) {
        const header = 'Confirm';
        const message =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';
        this.showAlert(header, message);
      } else {
        if (this.isAddFacility) {
          this.navigateBack();
        } else {
          this.editEnable = !this.editEnable;
        }
      }
    } else {
      this.navigateBack();
    }
  }

  async showAlert(header: string, message: string) {
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'ok',
        handler: () => {
          if (this.isAddFacility) {
            this.navigateBack();
          } else {
            this.editEnable = !this.editEnable;
            this.patchFormValue();
          }
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.dashboardService.refreshDashboardWithData.next(true);
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  navigateBack() {
    this.ionNav.pop();
    if (this.isRootPage) {
      this.dismiss();
    } else {
      this.ionNav.pop();
    }
  }

  enableEditMode() {
    this.editEnable = true;
  }

  async presentAlert(
    operation: any
  ) {
    const config = this.fieldConfig[operation];
    const initValue = this.facilityDetailsForm?.get(operation)?.value;
    const formControl = new FormControl(initValue, {
      validators: this.inputConfigService.getValidatorForPrompt(this.fieldConfig[operation])
    });
    const alert = await this.alertController.create({
      header: config.label,
      buttons: [
        {
          text: 'Cancel',
          role: 'dismiss',
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            if (!data) return false;
            let errorMsg = '';
            const value = data[0];
            formControl.patchValue(value);
            const errorType = formControl.errors as FormError;
            const errMsg = this.inputConfigService.getValidationMessage(config);
            if (errorType) {
              if (errorType.required) {
                errorMsg = `Please ${config.controlType == 'text' ? 'Enter' : 'select'} ${config.title || config.label}.`;
              } else if (
                errorType.maxlength ||
                errorType.minlength ||
                errorType.pattern
              ) {
                errorMsg = `Please enter valid ${config.title || config.label}${errMsg ? `, ${errMsg}.` : '.'}`
              }
            }
            this.facilityDetailsForm?.markAsDirty();
            if (errorMsg) {
              alert.message = errorMsg;
              return false;
            } else {
              return this.facilityDetailsForm?.patchValue({ [operation]: value, });;
            }
          },
        },
      ],
      backdropDismiss: false,
      inputs: [
        {
          placeholder: config.placeholder,
          value: initValue,
        },
      ],
      cssClass: `notable-state-alert unified-custom-input-alert`,
      animated: false
    });
    await alert.present();
  }

  didViewWillEnter() {
    this.isSaveDisabled = true;
    this.subscribeForm();
  }

  subscribeForm() {
    this.facilityDetailsForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.facilityDetailsForm) {
          this.facilityDetailsForm.markAsDirty();
          this.isSaveDisabled = this.facilityDetailsForm.invalid;
        }
      });
  }
}
