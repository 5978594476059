import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, MenuController, ModalController } from '@ionic/angular';
import { FacilityModel } from 'src/app/shared/models/facility.model';
import { StorageEnum } from '../../enums/storage.enum';
import { StorageService } from 'src/app/core/services/storage.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { Subscription, interval } from 'rxjs';
import { FacilityService } from 'src/app/features/settings/facility-management/services/facility.service';
import { MessageDrawerComponent } from '../message-drawer/message-drawer.component';
import { MESSAGES_REFRESH_INTERVAL } from '../../config/report.constant';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [IonicModule, CommonModule, FormsModule],
})
export class HeaderComponent implements OnInit, OnDestroy {
  availableFacilities: any[] = [];
  selectedFacility: FacilityModel = new FacilityModel();
  @Output() onOpenSummary = new EventEmitter<any>();
  isSettingModalOpen: boolean = false;
  isMenuOpen: boolean = false;
  private subscriptions: Subscription[] = [];
  isPCCEnabled = false;
  messages: any = [];
  messageIcon = 'messages.svg';
  @Input('header') header = '';

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private headerService: HeaderService,
    private menuCtrl: MenuController,
    private uiUtilityService: UiUtilityService,
    private facilityService: FacilityService,
    private modalCtrl: ModalController,
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.isPCCEnabled = this.headerService.isPCCEnabled;
    const sortedFacilities = this.uiUtilityService.sortList(this.authService.getLoginData?.facility, 'facilityName');
    this.availableFacilities = sortedFacilities;
    if (this.availableFacilities?.length) {
      if (this.headerService.getCurrentFacility?.facilityId) {
        this.selectedFacility = this.headerService.getCurrentFacility;
      } else {
        this.selectedFacility = this.availableFacilities[0];
      }
      this.onFacilityChange();
    }
    this.subscriptions.push(
      this.uiUtilityService.isPageModalOpen.subscribe((isOpen: boolean) => {
        this.isSettingModalOpen = isOpen;
      })
    );
    this.subscriptions.push(
      this.uiUtilityService.isMenuOpen.subscribe((isOpen: boolean) => {
        this.isMenuOpen = isOpen;
      })
    );
    this.facilityService.facilityData.subscribe((isCurrent = false) => {
      const sortedFacilities = this.uiUtilityService.sortList(this.authService.getLoginData?.facility, 'facilityName');
      this.availableFacilities = sortedFacilities;
      if (isCurrent) {
        const fac = this.headerService.getCurrentFacility;
        if (fac.facilityId) {
          this.selectedFacility = fac;
          this.willGetMessageData();
        }
      }
    });
  }

  willGetMessageData() {
    if (this.selectedFacility.enablePCC && this.header === 'main') {
      this.getMessagesData();
      this.subscriptions.push(interval(MESSAGES_REFRESH_INTERVAL).subscribe(() => {
        this.getMessagesData();
      }));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  compareWith(o1: FacilityModel, o2: FacilityModel) {
    return o1 && o2 ? o1.facilityId === o2.facilityId : o1 === o2;
  }

  onFacilityChange() {
    this.storageService.setSessionStorage(
      StorageEnum.selectedFacility,
      this.selectedFacility,
      true
    );
    this.storageService.removeSessionStorage(StorageEnum.filteredGroups);
    this.headerService.changeFacility(this.selectedFacility);
    this.willGetMessageData();
  }

  toggleMenu() {
    this.menuCtrl.isOpen().then((isOpen) => {
      if (isOpen) {
        this.isMenuOpen = false;
        this.dismissNavModal();
      } else {
        this.isMenuOpen = true;
        this.menuCtrl.open();
      }
    });
  }

  dismissNavModal() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    } else {
      this.menuCtrl.close();
    }
  }

  openSummary() {
    this.onOpenSummary.emit();
  }

  getMessagesData() {
    this.dashboardService.getPccNotificationForUser().subscribe({
      next: (response: any) => {
        if (response.success && response.data) {
          for (const res of response.data) {
            res.wearerName = this.uiUtilityService.getEntityShortDisplayName(res);
            const regex = new RegExp(`(\\{residentID\\})`);
            res.parts = res.messageText.split(regex).filter((part: string) => part !== '');
          }
          this.didGetMessagesData(response.data);
        }
      }, error: () => {

      }
    });
  }

  didGetMessagesData(response: any = []) {
    const notify = this.storageService.getLocalStorage(StorageEnum.notifications, true) || {};
    if (response.length && notify.cleared) {
      const index = response.findIndex((obj: any) => obj.id === notify.cleared);
      this.messages = index !== -1 ? response.slice(0, index) : response;
    } else {
      this.messages = response;
    }
    this.messageIcon = 'messages.svg';
    let temp = {};
    if (this.messages.length) {
      temp = { lastNotificationId: this.messages[0].id, cleared: notify.cleared || '' };
      if (!notify.lastNotificationId || (notify.lastNotificationId && this.messages[0].id !== notify.lastNotificationId)) {
        this.messageIcon = 'messages-new.svg';
      }
      const isError = this.messages.findIndex((item: any) => item.isError);
      if (isError !== -1) {
        this.messageIcon = 'messages-error.svg';
      }
      this.storageService.setLocalStorage(
        StorageEnum.notifications,
        temp,
        true
      );
    }
  }

  async openMessageDrawer() {
    const modal = await this.modalCtrl.create({
      component: MessageDrawerComponent,
      cssClass: 'message-drawer-modal',
      componentProps: {
        messages: this.messages,
      },
      backdropDismiss: false
    });
    await modal.present();
    const { role } = await modal.onWillDismiss();
    // if (role === 'cleared') {
    // }
    this.didGetMessagesData(this.messages);
  }
}
