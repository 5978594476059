<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="close-button" (click)="cancel()"><ion-icon slot="icon-only"
          name="close"></ion-icon></ion-button>
      <ion-back-button class="back-button" defaultHref (click)="cancel()"></ion-back-button>
    </ion-buttons>
    <ion-title>{{data.title}}</ion-title>
    <ion-buttons slot="end" *ngIf="isSave">
      <!-- [disabled]="this.selectControl.value === undefined || this.selectControl.value === null" -->
      <ion-button (click)="onSave()" [disabled]="!isChanged">Save</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-searchbar (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar" animated
    placeholder="Type to search...">
  </ion-searchbar>
</ion-header>
<ion-content>
  <div class="section-label" *ngIf="data.header">{{data.header}}</div>
  <ion-list *ngIf="items.length; else noItemsAvailable">
    <ion-radio-group [formControl]="selectControl" (ionChange)="onSelect($event)">
      <ion-item *ngFor="let item of items">
        <ion-radio color="controls" justify="start" labelPlacement="end" mode="md"
          [value]="item[data.key]">{{item[data.displayName]}} <span
            *ngIf="item[data.key] === data.value">(Current)</span></ion-radio>
        <ion-label slot="end" class="no-margin-right end-name-max list-right-slot"
          *ngIf="data.rightSlot && item[data.key]">
          <h3>
            <p *ngIf="data.rightSlot.title"><span class="slot-1"
                [ngClass]="{'offline-text': !item[data.rightSlot.title]}">{{item[data.rightSlot.title]
                || data.rightSlot.titleFallback}}</span>
            </p>
          </h3>
        </ion-label>
      </ion-item>
    </ion-radio-group>
  </ion-list>
  <ng-template #noItemsAvailable>
    <div class="ion-text-center">
      <p>{{data.emptyMessage}}</p>
    </div>
  </ng-template>
</ion-content>