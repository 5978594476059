<ion-content>
  <div class="container">
    <!-- Header section -->
    <ion-grid class="ion-no-padding title">
      <ion-row class="ion-justify-content-space-between padding-top">
        <ion-col size="9">
          <p class="room-summary-title">{{ data.roomName }}<span *ngIf="data.slotName && isBedSlotEnabled">-{{
              data.slotName }}</span>
          </p>
        </ion-col>
        <ion-col size="3" class="ion-no-padding edit-link">
          <!-- <ion-icon class="edit-icon" src="assets/icon/room-summary-edit.svg"></ion-icon> -->
          <span class="edit-room" (click)="navigateToRoomDetailsPage()" [nAccess]="'getRoomDetail'">Edit</span>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-space-between padding-top">
        <p class="wearer-group-name">{{subTitle}}</p>
      </ion-row>
    </ion-grid>

    <div class="ion-text-center m-t-10">
      <ion-spinner name="lines" class="spinner" *ngIf="loading"></ion-spinner>
    </div>

    <ion-note class="replace-note"
      *ngIf="!loading && !(roomDetails.residents?.length || roomDetails.assets?.length || roomDetails.beds?.length)">No
      residents,
      assets, or beds have been
      assigned to this room.</ion-note>

    <!-- Content -->
    <ion-grid class="ion-no-padding">
      <div class="wearer-list">
        <ng-container *ngIf="!commonRoom">
          <ng-container *ngIf="isCasparEnabled; else elseTemplate">
            <ion-row class="resident-bed" *ngFor="let residents of roomDetails.residents; let i = index">
              <ion-col size="auto">
                <div class="room-name-row entity-row" lines="none">
                  <ion-icon class="entity-icon" src="assets/icon/icons_resident.svg"
                    [style.color]="residents.entityIconColor"></ion-icon>
                  <ion-label class="resident-name entity-label"><span class="app-link"
                      [ngClass]="{ 'no-link': !isWearerSummaryEnabled }"
                      (click)="showWearerSummary(residents, $event)">{{residents['displayName']}}</span>
                    <ion-row class="bed-presence" *ngIf="casparData[residents.residentId]?.[bedInType]">
                      <ion-col size="1.0" class="room-location-icon">
                        <ion-icon class="entity-icon" src="assets/icon/{{alertsIconOrder[bedInType]?.icon}}"></ion-icon>
                      </ion-col>
                      <ion-col>
                        <span>{{alertsIconOrder[bedInType]?.displayName}}</span>
                      </ion-col>
                    </ion-row>
                    <ion-row class="bed-presence" *ngIf="casparData[residents.residentId]?.[bedOutType]">
                      <ion-col size="1.0" class="room-location-icon">
                        <ion-icon class="entity-icon"
                          src="assets/icon/{{alertsIconOrder[bedOutType]?.icon}}"></ion-icon>
                      </ion-col>
                      <ion-col>
                        <span>{{alertsIconOrder[bedOutType]?.displayName}}</span>
                      </ion-col>
                    </ion-row>
                  </ion-label>
                </div>
              </ion-col>
              <ion-col size="auto">
                <div class="room-name-row entity-row" lines="none" *ngIf="residents.bedInfo; else elseBedTemplate">
                  <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                    [style.color]="residents.bedInfo.bedMetaData?.bedColor"></ion-icon>
                  <ion-label class="resident-name entity-label entity-bed-label app-link"
                    (click)="navigateToBedDetailsPage(residents.bedInfo)"
                    [ngClass]="{ 'no-link': !isBedSummaryEnabled }">{{residents.bedInfo.bedName}}
                  </ion-label>
                  <ion-icon *ngIf="residents.bedInfo.bedMetaData?.batteryStateIcon && residents.bedInfo.connexioEnabled"
                    class="bed-battery-state" [src]="residents.bedInfo.bedMetaData.batteryStateIcon"></ion-icon>
                </div>
                <ng-template #elseBedTemplate>
                  <div class="room-name-row entity-row" lines="none">
                    <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                      [style.color]="'#bbbbbb'"></ion-icon>
                    <ion-label class="resident-name entity-label entity-bed-label">No bed</ion-label>
                  </div>
                </ng-template>
              </ion-col>
            </ion-row>
            <ion-row class="resident-bed" *ngFor="let bed of beds">
              <ion-col size="auto" *ngIf="!bed.appResidentInBed">
                <div class="room-name-row entity-row" lines="none">
                  <ion-icon class="entity-icon" src="assets/icon/icons_resident.svg"
                    [style.color]="'#bbbbbb'"></ion-icon>
                  <ion-label class="resident-name entity-label"><span>No resident</span></ion-label>
                </div>
              </ion-col>
              <ion-col size="auto" *ngIf="!bed.appResidentInBed">
                <div class="room-name-row entity-row" lines="none">
                  <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                    [style.color]="bed.bedMetaData?.bedColor"></ion-icon>
                  <ion-label class="resident-name entity-label entity-bed-label app-link"
                    (click)="navigateToBedDetailsPage(bed)"
                    [ngClass]="{ 'no-link': !isBedSummaryEnabled }">{{bed.bedName}}
                  </ion-label>
                  <ion-icon *ngIf="bed.bedMetaData?.batteryStateIcon && bed.connexioEnabled" class="bed-battery-state"
                    [src]="bed.bedMetaData.batteryStateIcon"></ion-icon>
                </div>
              </ion-col>
            </ion-row>
          </ng-container>
          <ng-template #elseTemplate>
            <ion-row class="resident-bed" *ngFor="let residents of roomDetails.residents; let i = index">
              <ion-col size="auto">
                <div class="room-name-row entity-row" lines="none">
                  <ion-icon class="entity-icon" src="assets/icon/icons_resident.svg"
                    [style.color]="residents.entityIconColor"></ion-icon>
                  <ion-label class="resident-name entity-label"><span class="app-link"
                      [ngClass]="{ 'no-link': !isWearerSummaryEnabled }"
                      (click)="showWearerSummary(residents, $event)">{{residents['displayName']}}</span>
                  </ion-label>
                </div>
              </ion-col>
              <ion-col size="auto">
                <div class="room-name-row entity-row" lines="none" *ngIf="residents.bedInfo; else elseBedTemplate">
                  <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                    [style.color]="residents.bedInfo.bedMetaData?.bedColor"></ion-icon>
                  <ion-label class="resident-name entity-label entity-bed-label app-link"
                    (click)="navigateToBedDetailsPage(residents.bedInfo)"
                    [ngClass]="{ 'no-link': !isBedSummaryEnabled }">{{residents.bedInfo.bedName}}
                  </ion-label>
                  <ion-icon *ngIf="residents.bedInfo.bedMetaData?.batteryStateIcon && residents.bedInfo.connexioEnabled"
                    class="bed-battery-state" [src]="residents.bedInfo.bedMetaData.batteryStateIcon"></ion-icon>
                </div>
                <ng-template #elseBedTemplate>
                  <div class="room-name-row entity-row" lines="none">
                    <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                      [style.color]="'#bbbbbb'"></ion-icon>
                    <ion-label class="resident-name entity-label entity-bed-label">No bed</ion-label>
                  </div>
                </ng-template>
              </ion-col>
            </ion-row>
            <ion-row class="resident-bed" *ngFor="let bed of beds">
              <ion-col size="auto" *ngIf="!bed.appResidentInBed">
                <div class="room-name-row entity-row" lines="none">
                  <ion-icon class="entity-icon" src="assets/icon/icons_resident.svg"
                    [style.color]="'#bbbbbb'"></ion-icon>
                  <ion-label class="resident-name entity-label"><span>No resident</span></ion-label>
                </div>
              </ion-col>
              <ion-col size="auto" *ngIf="!bed.appResidentInBed">
                <div class="room-name-row entity-row" lines="none">
                  <ion-icon class="entity-icon" src="assets/icon/icons_bed_current.svg"
                    [style.color]="bed.bedMetaData?.bedColor"></ion-icon>
                  <ion-label class="resident-name entity-label entity-bed-label app-link"
                    (click)="navigateToBedDetailsPage(bed)"
                    [ngClass]="{ 'no-link': !isBedSummaryEnabled }">{{bed.bedName}}
                  </ion-label>
                  <ion-icon *ngIf="bed.bedMetaData?.batteryStateIcon && bed.connexioEnabled" class="bed-battery-state"
                    [src]="bed.bedMetaData.batteryStateIcon"></ion-icon>
                </div>
              </ion-col>
            </ion-row>
          </ng-template>
        </ng-container>

        <!-- <ion-item lines="none" *ngFor="let residents of roomDetails.Residents; let i = index">
        <ion-icon aria-hidden="true" slot="start" src="assets/icon/person.svg" [color]="'ok'"></ion-icon>
          <ion-label color="ok" class="localId-label" (click)="showWearerSummary(residents['localId'], $event)">{{residents['localId'] || residents['FirstName']}}</ion-label>
      </ion-item> -->
        <ion-item class="assets-row entity-row" *ngIf="roomDetails.assets?.length" lines="none">
          <ion-icon class="entity-icon" src="assets/icon/icons_asset.svg"></ion-icon>
          <ion-label class="asset-label entity-label">{{roomDetails.assets.length}} {{
            roomDetails.assets.length > 1 ?
            'Assets assigned' : 'Asset assigned'}}</ion-label>
        </ion-item>
        <ng-container *ngIf="!commonRoom">
          <div class="entity-errors" *ngFor="let bed of beds">
            <div class="entity-error-row" *ngIf="bed.bedMetaData?.errorState">
              <div class="entity-error-row-header">
                <ion-icon class="entity-error-icon" src="assets/icon/warning-icon.svg"></ion-icon>
                <h5>{{bed.bedMetaData?.errorTitle}}: {{bed.bedName}}</h5>
              </div>
              <div class="entity-error-row-desc">
                <p>{{bed.bedMetaData?.errorMsg}}</p>
              </div>
            </div>
            <div class="entity-error-row"
              *ngIf="bed.bedMetaData?.bedAngleAlertState === 'WARNING_RED' || bed.bedMetaData?.bedAngleAlertState === 'CAUTION_YELLOW'">
              <div class="entity-error-row-header">
                <ion-icon class="entity-error-icon" src="assets/icon/info-icon.svg"
                  [ngStyle]="{'color': bed.bedMetaData?.bedAngleAlertTextColor}"></ion-icon>
                <h5 [ngStyle]="{'color': bed.bedMetaData?.bedAngleAlertTextColor}"
                  [innerHTML]="bed.bedMetaData?.bedAngleAlertTitle+': '+bed.bedName"></h5>
              </div>
              <div class="entity-error-row-desc">
                <p>{{bed.bedMetaData?.bedAngleAlertMessage}}</p>
              </div>
            </div>
            <div class="entity-error-row"
              *ngIf="bed.bedMetaData?.bedHeightAlertState === 'WARNING_RED' || bed.bedMetaData?.bedHeightAlertState === 'CAUTION_YELLOW'">
              <div class="entity-error-row-header">
                <ion-icon class="entity-error-icon" src="assets/icon/info-icon.svg"
                  [ngStyle]="{'color': bed.bedMetaData?.bedHeightAlertTextColor}"></ion-icon>
                <h5 [ngStyle]="{'color': bed.bedMetaData?.bedHeightAlertTextColor}"
                  [innerHTML]="bed.bedMetaData?.bedHeightAlertTitle+': '+bed.bedName"></h5>
              </div>
              <div class="entity-error-row-desc">
                <p>{{bed.bedMetaData?.bedHeightAlertMessage}}</p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ion-grid>
    <!-- Bed position -->
    <div *ngIf="!commonRoom && beds.length" class="bed-segments">
      <div class="divider bed-position-divider"></div>
      <div class="bed-position-header">
        <h3 class="bed-position-title">Bed Positions</h3>
        <ion-button (click)="refresh()" fill="clear" size="small" class="refresh-btn">
          <ion-icon slot="icon-only" src="assets/svg/refresh-icon.svg"></ion-icon>
        </ion-button>
      </div>
      <!-- Room segment -->
      <ion-segment class="bed-tabs" *ngIf="beds.length > 1" [value]="selectedBed" mode="md"
        (ionChange)="handleChange($event)" scrollable="true">
        <ion-segment-button *ngFor="let bed of beds" [value]="bed.id"
          [style.--color-checked]="bed.bedMetaData?.bedColor">
          <ion-item lines="none" class="room-tab">
            <ion-icon src="assets/icon/icons_bed_current.svg" [style.color]="bed.bedMetaData?.bedColor"></ion-icon>
            <ion-label>{{bed.bedName}}</ion-label>
          </ion-item>
        </ion-segment-button>
      </ion-segment>
      <ion-item lines="none"
        *ngIf="bedData.bedMetaData?.errorState && bedData.bedMetaData?.errorCode === 'bridgeOffline' && bedData.connexioEnabled">
        <ion-note class="app-note">Bed status could not be retrieved at this time.</ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="!bedData.connexioEnabled">
        <ion-note class="app-note">Bed status is available only for Connexio-enabled beds.</ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="bedData.connexioEnabled && bedData.bedMetaData?.bridgeAssigned === false">
        <ion-note class="app-note">Bed status unavailable because no bridge has been assigned to this bed.</ion-note>
      </ion-item>
      <div class="bed-container" [style.paddingTop.px]="headRestAnglePx"
        *ngIf="bedData.bedPositionMetaData?.bedId && bedData.connexioEnabled">
        <div class="height-section">
          <div class="bed-dimensions" [ngStyle]="{'color': bedData.bedMetaData?.bedAngleAlertTextColor}"
            *ngIf="bedData.bedPositionMetaData.headRestAngle === -1"><span>&#8211;</span>
          </div>
          <div class="bed-dimensions" [ngStyle]="{'color': bedData.bedMetaData?.bedAngleAlertTextColor}"
            *ngIf="bedData.bedPositionMetaData.headRestAngle === -2"><span>?</span>
          </div>
          <div class="bed-dimensions" [ngStyle]="{'color': bedData.bedMetaData?.bedAngleAlertTextColor}"
            *ngIf="bedData.bedPositionMetaData.headRestAngle !== -1 && bedData.bedPositionMetaData.headRestAngle !== -2">
            {{bedData.bedPositionMetaData.headRestAngle || 0}}&deg;</div>
        </div>
        <ion-row class="bed-img">
          <ion-col size="5.5" class="bed-img-part rotate" [ngClass]="bedData.bedPositionAngle.anglePos"
            [style]="'transform:rotate(' +bedData.bedPositionAngle.headRestAngle+ 'deg);'"></ion-col>
          <ion-col size="3" class="bed-img-part rotate-center" [ngClass]="bedData.bedPositionAngle.anglePos"
            [style]="'transform:rotate(' +bedData.bedPositionAngle.centerRestAngle+ 'deg);'"></ion-col>
          <ion-col size="3" class="bed-img-part rotate-footer" [ngClass]="bedData.bedPositionAngle.anglePos"
            [style]="'transform:rotate(' +bedData.bedPositionAngle.footRestAngle+ 'deg);'"></ion-col>
        </ion-row>
        <div class="height-section">
          <ion-img src="assets/svg/height-arrow.svg"></ion-img>
          <div class="bed-dimensions" [ngStyle]="{'color': bedData.bedMetaData?.bedHeightAlertTextColor}"
            *ngIf="bedData.bedPositionMetaData.bedHeight === -1"><span>&#8211;</span>
          </div>
          <div class="bed-dimensions" [ngStyle]="{'color': bedData.bedMetaData?.bedHeightAlertTextColor}"
            *ngIf="bedData.bedPositionMetaData.bedHeight === -2"><span>?</span>
          </div>
          <div class="bed-dimensions" [ngStyle]="{'color': bedData.bedMetaData?.bedHeightAlertTextColor}"
            *ngIf="bedData.bedPositionMetaData.bedHeight !== -1 && bedData.bedPositionMetaData.bedHeight !== -2">
            {{bedData.bedPositionMetaData.bedHeight || 0}}"</div>
        </div>
      </div>
      <div class="divider" *ngIf="bedData.bedPositionMetaData?.bedId"></div>
      <div class="bed-safe-light" *ngIf="bedData.bedPositionMetaData?.bedId">
        <ion-icon
          [src]="bedData.bedPositionMetaData?.lightInstalled && bedData.bedPositionMetaData?.bedLight ? 'assets/svg/safe-light-on.svg' : 'assets/svg/safe-light-off.svg'"></ion-icon>
        <div class="bed-safe-light-details">
          <h3>SafeLight: {{bedData.bedPositionMetaData?.lightInstalled ? (bedData.bedPositionMetaData?.bedLight ? 'On' :
            'Off') : 'Not installed'}}</h3>
          <h3 class="intensity"
            *ngIf="bedData.bedPositionMetaData?.lightInstalled && bedData.bedPositionMetaData?.bedLight">Intensity:
            {{bedData.bedPositionMetaData?.lightIntensity}}</h3>
        </div>
      </div>
      <div class="bed-state" *ngIf="bedData.bedPositionMetaData?.bedId">
        <div class="divider"></div>
        <h3 class="bed-position-title">Bed State<span
            *ngIf="bedData.bedPositionMetaData?.bedState">{{bedData.bedPositionMetaData.bedState}}</span></h3>
        <div class="bed-state-info">
          <ion-icon *ngFor="let bedState of bedStates; let i = index;" class="bed-state-info-icon"
            [src]="bedState.icon+(bedData.bedPositionMetaData?.['state_'+(i+1)] || '')+'.svg'"></ion-icon>
        </div>
      </div>
    </div>
  </div>

</ion-content>