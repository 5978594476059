import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { StorageEnum } from '../../enums/storage.enum';
import { WearerDetailsPage } from 'src/app/features/wearer-management/pages/wearer-details/wearer-details.page';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { MODAL_PAGE_TYPE, PATIENT_SYNC } from '../../config/app.constant';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  standalone: true,
  selector: 'app-message-drawer',
  templateUrl: './message-drawer.component.html',
  styleUrls: ['./message-drawer.component.scss'],
  imports: [IonicModule, CommonModule, FormsModule],
})
export class MessageDrawerComponent {
  messages: any = [];
  loading = false;
  lastUpdated = '';
  isLastUpdateLoading = false;

  constructor(private modalCtrl: ModalController, private storageService: StorageService, private uiUtilityService: UiUtilityService, private dashboardService: DashboardService, private headerService: HeaderService) {

  }

  ngOnInit() {
    if (this.messages.length) {
      this.checkSyncTypeStatusPCC(PATIENT_SYNC);
    }
  }

  checkSyncTypeStatusPCC(syncType: string) {
    this.isLastUpdateLoading = true;
    const selectedFacility = this.headerService.getCurrentFacility;
    this.dashboardService.syncPCCStatus(selectedFacility.facilityId, syncType).subscribe({
      next: (res: any) => {
        this.isLastUpdateLoading = false;
        if (res.data && res.data[0]?.resolved) {
          this.lastUpdated = this.dashboardService.getCurrentTimeInTimezone();
        } else {
          this.lastUpdated = this.messages[0].timestamp;
        }
      }, error: () => {
        this.isLastUpdateLoading = false;
        this.lastUpdated = this.messages[0].timestamp;
      }
    });
  }

  clearAll() {
    const temp = { lastNotificationId: this.messages[0].id, cleared: this.messages[0].id };
    this.storageService.setLocalStorage(
      StorageEnum.notifications,
      temp,
      true
    );
    return this.modalCtrl.dismiss('', 'cleared');
  }

  onLinkClick(isClickable: boolean, data: any) {
    if (!isClickable) {
      return;
    }
    this.close();
    const menuItem = { component: WearerDetailsPage, id: 'wearer-list', confirm: false, pageType: MODAL_PAGE_TYPE, modalData: { wearerId: data.wearerId, isRootPage: true } };
    this.uiUtilityService.menuClicked.next(menuItem);
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
