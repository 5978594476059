import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Router } from '@angular/router';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { StorageService } from './storage.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { DataService } from './api/data.service';
import { environment } from 'src/environments/environment';
import { UiUtilityService } from './ui-utility.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { ApiResponseModel } from './api/api-response.model';
import {
  PushNotiDeRegistrationModel,
  PushNotiRegistrationModel,
} from 'src/app/shared/models/push-noti-registration.model';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private registrationFailCount = 0;
  private readonly maxReregistrationAttempt = 5;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private dataService: DataService<any>,
    private uiUtilityService: UiUtilityService
  ) {
    if (Capacitor.isNativePlatform()) {
      this.registerPush();
      this.registerPushEvents();
    }
  }

  /**
   * Initialize push notification
   */
  initPush() {
    if (Capacitor.isNativePlatform()) {
      this.registerPush();
    }
  }

  /**
   *  Register with Apple / Google to receive push via APNS/FCM
   */
  async registerPush() {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  }

  async registerPushEvents() {
    await PushNotifications.addListener('registration', (token) => {
      this.storageService.removeLocalStorage(
        StorageEnum.pushNotificationToken
      );
      this.storageService.setLocalStorage(
        StorageEnum.pushNotificationToken,
        token.value
      );
    });

    await PushNotifications.addListener('registrationError', (err) => {
      if (this.registrationFailCount < this.maxReregistrationAttempt) {
        this.registerPush();
      }
      this.registrationFailCount++;
    });

    await PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.log('Push received: ' + JSON.stringify(notification));
      }
    );

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        console.log(
          'Push notification action performed',
          notification.actionId,
          notification.inputValue
        );
        this.router.navigate([RouterEnum.dashboard]);
      }
    );
  }

  /**
   * Save prenotification device token on server
   * @param token - Push notification token
   */
  saveTokenOnServer(token: string) {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    const url = `${environment.apiUrl.user}${EndPointsEnum.userDeviceRegistration}`;
    const payload: PushNotiRegistrationModel = {
      userId: this.storageService.getLocalStorage(StorageEnum.loginData, true)
        ?.userId,
      token,
      platform: Capacitor.getPlatform() === 'ios' ? '1' : '2',
    };
    const registerToken: Observable<any> = this.dataService.post(url, payload);
    registerToken.subscribe((response: ApiResponseModel<any>) => {
      if (response && response.success) {
        this.storageService.setLocalStorage(
          StorageEnum.pushNotificationToken,
          token
        );
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage || '', header);
      }
    });
  }

  /**
   * Calls API to delete token when user logs out.
   */
  removeTokenOnLogout() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    const url = `${environment.apiUrl.user}${EndPointsEnum.userDeviceDeRegistration}`;
    const payload: PushNotiDeRegistrationModel = {
      userId: this.storageService.getLocalStorage(StorageEnum.loginData, true)
        ?.userId,
      token: this.storageService.getLocalStorage(
        StorageEnum.pushNotificationToken
      ),
    };
    const deRegisterToken: Observable<any> = this.dataService.post(
      url,
      payload
    );
    deRegisterToken.subscribe((response: ApiResponseModel<any>) => {
      if (response && response.success) {

      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage || '', header);
      }
    });
  }
}
