import { CommonModule } from "@angular/common";
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink } from "@angular/router";
import { IonNav, IonicModule } from "@ionic/angular";
import { BaseChartDirective } from "ng2-charts";
import { AuthService } from "src/app/core/services/auth.service";
import { HeaderService } from "src/app/core/services/header.service";
import { HeaderComponent } from "src/app/shared/components/header/header.component";
import { mockDayReport, mockMonthReport, mockWeekReport } from "../mockdata";
import { cloneDeep, merge, mergeWith, unionWith } from 'lodash-es';
import { Observable, Subscription, forkJoin } from "rxjs";
import { ReportsService } from "../reports.service";
import { WearerReportDetailsPage } from "../wearer-reports-details/wearer-report-details.page";
import { toZonedTime } from "date-fns-tz";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { format, parse, subDays } from "date-fns";
import { defaultTempUnit } from "src/app/shared/config/temp-unit.constant";
import { UiUtilityService } from "src/app/core/services/ui-utility.service";

@Component({
  standalone: true,
  selector: 'app-wearer-reports',
  templateUrl: './wearer-reports.page.html',
  styleUrls: ['./wearer-reports.page.scss'],
  imports: [CommonModule, FormsModule, IonicModule, RouterLink, ReactiveFormsModule, BaseChartDirective, HeaderComponent],
})
export class WearerReportsPage implements OnInit, OnDestroy {
  @ViewChild('contentToDownload') content!: ElementRef;
  timeZone = '';
  facilityName = '';
  popoverEvent: any;
  tempUnitId = defaultTempUnit;
  private tableDataCopy: any = [
    {
      name: 'Wearer ID',
      key: 'localId',
      sort: true,
      graph: false
    },
    {
      name: 'Last Name',
      key: 'lastName',
      sort: true,
      graph: false
    },
    {
      name: 'First Name',
      key: 'firstName',
      sort: true,
      graph: false
    },
    {
      name: 'Avg. Temp',
      type: 'temp',
      key: 'avgTemp',
      sort: true,
      graph: false
    },
    {
      name: 'Back Angle Score',
      type: 'bed',
      key: 'backAngleScore',
      sort: true,
      graph: false
    },
    {
      name: 'Bed Height Score',
      type: 'bed',
      key: 'bedHeightScore',
      sort: true,
      graph: false
    },
    {
      name: 'Temp. Trends',
      type: 'temp',
      key: 'tempTrendsGraph',
      graph: true
    },
    {
      name: 'Back Angle',
      type: 'bed',
      key: 'backAngleGraph',
      graph: true
    },
    {
      name: 'Bed Height',
      type: 'bed',
      key: 'bedHeightGraph',
      graph: true
    }
  ];
  tableData: any = [];
  currentSortColumn: string | null = null;
  isAscending: boolean = true;
  showValues = [{
    name: 'all',
    displayName: 'All'
  },
  {
    name: 'avgTempState,backAngleState,bedHeightState',
    displayName: 'All warning states',
    value: 'WARNING'
  },
  {
    name: 'avgTempState',
    displayName: 'Temperature warnings',
    value: 'WARNING'
  },
  {
    name: 'backAngleState',
    displayName: 'Back Angle warnings',
    value: 'WARNING'
  },
  {
    name: 'bedHeightState',
    displayName: 'Bed Height warnings',
    value: 'WARNING'
  }];
  showSelectedValue = this.showValues[0];
  private subscriptions: Subscription[] = [];
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective> | undefined;
  showGraph = true;
  isTemperature = true;
  isBedPosition = true;
  wearerTitle = '';
  loading = false;
  graphCols = 'col-3';
  backAngleScoreInfo = 'The average score for bed back angle compliance in the facility. The score is based on how long each Connexio bed was in a Back Angle Warning state. Beds must be online and residents must be nearby for a bed to enter a warning state.';
  bedHeightScoreInfo = 'The average score for bed height compliance in the facility. The score is based on how long each Connexio bedwas in a Bed Height Warning state. Beds must be online and residents must be nearby for a bed to enter a warning state.';

  constructor(private headerService: HeaderService, private uiUtilityService: UiUtilityService, private authService: AuthService, private reportsService: ReportsService, private nav: IonNav) {

  }

  barChartOptions: any = {
    responsive: true,
    barThickness: 20,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          display: false
        }
      },
    },
    layout: {
      padding: {
        left: 50, // Adjust this value to leave space on the left
      },
    },
  };


  barChartDataGr: any = {
    type: 'bar',
    category: 'stacked-bar',
    labels: [
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    ],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: "#00A980", barThickness: 8 },
      { data: [28, 48, 40, 19, 86, 27, 90], label: '', backgroundColor: "#FFAA00", barThickness: 8 },
      { data: [18, 38, 70, 29, 76, 67, 50], label: '', backgroundColor: "#D34626", barThickness: 8 },
    ]
  };

  barChartData: any = {
    type: 'bar',
    category: 'stacked-bar',
    labels: [
      'Monday',
      '',
      'Wednesday',
      '',
      'Friday',
      '',
      'Sunday'
    ],
    datasets: [
      { data: [65, 59, 80, 81, 56, 55, 40], label: '', backgroundColor: "#00A980", },
      { data: [28, 48, 40, 19, 86, 27, 90], label: '', backgroundColor: "#FFAA00" },
      { data: [18, 38, 70, 29, 76, 67, 50], label: '', backgroundColor: "#D34626" },
    ]
  };

  barChartPlugins = [
    {
      id: 'horizontalLinePlugin',
      afterDatasetsDraw(chart: any) {
        const { ctx, chartArea, scales } = chart;
        const yValue = 100; // The Y-axis value for the horizontal line
        const label = '100.4°F'; // The label text

        if (chartArea) {
          // Draw the horizontal line
          ctx.save();
          ctx.strokeStyle = 'red'; // Line color
          ctx.lineWidth = 2;
          ctx.beginPath();
          const yPixel = scales.y.getPixelForValue(yValue);
          ctx.moveTo(chartArea.left, yPixel);
          ctx.lineTo(chartArea.right, yPixel);
          ctx.stroke();

          // Draw the label
          ctx.fillStyle = 'red'; // Label color
          ctx.font = '12px Arial'; // Label font
          ctx.textAlign = 'right'; // Align text to the right
          ctx.textBaseline = 'middle'; // Vertically center the text
          ctx.fillText(label, chartArea.left - 10, yPixel); // Position label slightly left of the chart area
          ctx.restore();
        }
      },
    },
  ];

  barChartOptions1: any = {
    responsive: true,
    barThickness: 20,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context: any) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          display: false
        }
      },
    },
    layout: {
      padding: {
        left: 50, // Adjust this value to leave space on the left
      },
    },
  };

  generateArrayWithLabels() {
    const result = [];
    for (let i = 0; i <= 12; i++) {
      if (i % 2 === 0) {
        result.push(i); // Add the number as the label
      } else {
        result.push(''); // Add an empty string
      }
    }
    return result;
  }

  datapre = [{
    value: 84,
    color: 'rgba(0, 169, 128, 0.2)',
    label: '1 PM'
  }, {
    value: 103,
    color: 'rgba(255, 170, 0, 0.2)',
    label: '2 PM'
  }, {
    value: 96,
    color: 'rgba(211, 70, 38, 0.2)',
    label: '3 PM'
  }, {
    value: 99,
    color: 'rgba(0, 169, 128, 0.2)',
    label: '4 PM'
  }, {
    value: 100,
    color: 'rgba(255, 170, 0, 0.2)',
    label: '5 PM'
  }, {
    value: 102,
    color: 'rgba(211, 70, 38, 0.2)',
    label: '6 PM'
  }];

  datapre1: any = this.datapre.map((item: any) => {
    let temp = { value: 0, color: '', label: '' };
    let tempA: any = [];
    for (let i = 0; i < 12; i++) {
      if (i === 11) {
        tempA.push(item);
      } else {
        tempA.push(temp);
      }
    }
    return tempA;
  }).flat();

  generateArrayWithNumber() {
    let result: any = [];
    for (let i = 0; i <= this.datapre.length; i++) {
      result = result.concat(this.generateRandomNumbers(20, 70, 12));
    }
    return result;
  }

  generateRandomNumbers(min: number, max: number, count: number) {
    const randomNumbers = [];
    for (let i = 0; i < count; i++) {
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      randomNumbers.push(randomNumber);
    }
    return randomNumbers;
  }

  barChartData1: any = {
    labels: this.datapre1.map((e: any) => e.label),
    datasets: [
      { type: 'bar', data: this.datapre1.map((e: any) => e.value), label: '', backgroundColor: this.datapre1.map((e: any) => e.color) },
      {
        type: 'line',
        data: this.generateArrayWithNumber(),
        fill: false,
        borderColor: 'transparent',
        borderWidth: 2,
        pointRadius: 0,
        label: ''
      }
    ]
  };

  barChartPlugins1 = [
    {
      id: 'horizontalLinePlugin',
      afterDatasetsDraw(chart: any) {
        const { ctx, chartArea, scales } = chart;
        const yValue = 100.4; // The Y-axis value for the horizontal line
        const label = '100.4'; // The label text

        if (chartArea) {
          // Draw the horizontal line
          ctx.save();
          ctx.strokeStyle = 'red'; // Line color
          ctx.lineWidth = 2;
          ctx.beginPath();
          const yPixel = scales.y.getPixelForValue(yValue);
          ctx.moveTo(chartArea.left, yPixel);
          ctx.lineTo(chartArea.right, yPixel);
          ctx.stroke();

          // Draw the label
          ctx.fillStyle = 'red'; // Label color
          ctx.font = '12px Arial'; // Label font
          ctx.textAlign = 'right'; // Align text to the right
          ctx.textBaseline = 'middle'; // Vertically center the text
          ctx.fillText(label, chartArea.left - 10, yPixel); // Position label slightly left of the chart area
          ctx.restore();
        }
      },
    },
    {
      id: 'LinePlugin',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const dataset = chart.data.datasets[1];
        const data = dataset.data;

        // Loop through each segment of the line
        for (let i = 0; i < data.length - 1; i++) {
          const startX = chart.scales.x.getPixelForValue(i);
          const startY = chart.scales.y.getPixelForValue(data[i]);
          const endX = chart.scales.x.getPixelForValue(i + 1);
          const endY = chart.scales.y.getPixelForValue(data[i + 1]);

          // Calculate the color based on the value
          const color = this.getSegmentColor(data[i]);

          // Set the line color dynamically for each segment
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.strokeStyle = color;
          ctx.lineWidth = 3;
          ctx.stroke();
        }
      }
    },
  ];

  getSegmentColor(currentValue: number, breakpoints: any = []) {
    if (breakpoints?.length) {
      const sorted = breakpoints.sort((a: any, b: any) => b.value - a.value);
      let color = sorted.find((item: any) => item.default)?.color || '';
      for (const point of sorted) {
        if (point.aggregator === '==' && currentValue == point.value) {
          color = point.color;
          break;
        } else if (point.aggregator === '<' && currentValue < point.value) {
          color = point.color;
        } else if (point.aggregator === '>' && currentValue > point.value) {
          color = point.color;
          break;
        }
      }
      return color;
    }
  }

  lineChartPlugins = [
    {
      id: 'LinePlugin',
      beforeDraw: (chart: any) => {
        const ctx = chart.ctx;
        const dataset = chart.data.datasets[0];
        const data = dataset.data;
        const breakpoints = chart.data.breakpoints;

        // Loop through each segment of the stepped line
        for (let i = 0; i < data.length - 1; i++) {
          const startX = chart.scales.x.getPixelForValue(i);
          const startY = chart.scales.y.getPixelForValue(data[i]);
          const endX = chart.scales.x.getPixelForValue(i + 1);
          const endY = chart.scales.y.getPixelForValue(data[i + 1]);

          // Calculate the color based on the value
          const color = this.getSegmentColor(data[i], breakpoints);

          // Draw horizontal line (stepping right)
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(startX, endY);  // Step vertically
          ctx.strokeStyle = color;
          ctx.lineWidth = 3;
          ctx.stroke();

          const hcolor = this.getSegmentColor(data[i + 1], breakpoints);
          // Draw vertical line (stepping up or down)
          ctx.beginPath();
          ctx.moveTo(startX, endY);
          ctx.lineTo(endX, endY);  // Step horizontally
          ctx.strokeStyle = hcolor;
          ctx.lineWidth = 3;
          ctx.stroke();
        }
      }
    },
  ];

  lineChartOptions: any = {
    responsive: true,
    scales: {
      x: {
        scaleLabel: {
          display: true,
          labelString: 'Time', // X-axis label
        },
      },
      y: {
        ticks: {
          min: 0,
          max: 50, // Adjust as needed
          stepSize: 10,
          beginAtZero: true,
          callback: function (value: number) {
            return `${value}°`; // Add degree symbol to the label
          },
        },
      },
    },
  };

  lineChartData: any = {
    type: 'line',
    category: 'stepped-line',
    labels: [
      '12 AM',
      '',
      '12 PM',
      '',
      '12 AM',
      '',
      '12 PM',
      '',
      '12 AM'
    ],
    datasets: [
      {
        data: [23, 10, 18, 34, 50, 14, 20, 24, 30],
        label: 'Sample Data',
        fill: false,
        borderColor: 'transparent',
        tension: 0.4,
        stepped: 'after'
      }
    ],
    breakpoints: [{
      value: 20,
      color: 'red',
      aggregator: '<'
    }, {
      value: 40,
      color: 'yellow',
      aggregator: '>'
    }, {
      color: 'green',
      default: true
    }]
  };
  reportData: any = {};
  sortedData: any = [];
  reportChartOptions: any = {};

  ngOnInit() {
    this.tempUnitId = this.authService.getLoginData?.tempUnit;
    this.tableData = [...this.tableDataCopy];
    this.didOnInit();
    this.getData({ isBedPosition: true, isTemperature: true });
    // this.setWearerTitle();
    this.subscriptions.push(
      this.reportsService.onUpdateFilter.subscribe((data: any) => {
        this.didOnInit();
        this.onSubmitFilter(data);
      })
    )
  }

  didOnInit() {
    const selectedFacility = this.headerService.getCurrentFacility;
    this.timeZone = selectedFacility.timeZone;
    this.facilityName = selectedFacility.facilityName;
  }

  getMergedData(data?: any): Observable<any[]> {
    const now = new Date();
    const zonedDate = toZonedTime(now, this.timeZone);
    const defaultDate1 = subDays(zonedDate, 1);
    let day = format(defaultDate1, 'MMddyyyy');
    if (data?.startFrom) {
      const parsedDate = parse(data.startFrom, 'yyyy-MM-dd', new Date());
      day = format(parsedDate, 'MMddyyyy');
    }
    const bedReq = this.reportsService.getDailyBedGraph({ day, timeZone: this.timeZone });
    const tempReq = this.reportsService.getTempBedGraph({ day, timeZone: this.timeZone });
    let allReq = [bedReq, tempReq];
    if (!data?.isTemperature && data?.isBedPosition) {
      allReq = [bedReq];
    } else if (data?.isTemperature && !data?.isBedPosition) {
      allReq = [tempReq];
    }
    return forkJoin(allReq);
  }

  getData(data?: any) {
    this.loading = true;
    this.getMergedData(data).subscribe({
      next: (results: any) => {
        this.loading = false;
        let resp = results[0];
        let resp1: any = {};
        if (results.length > 1) {
          resp1 = results[1];
        }
        if (results.length > 1 && resp?.data && resp1?.data) {
          resp = mergeWith({}, resp?.data || {}, resp1?.data || {}, this.reportsService.deepMergeById);
        } else {
          resp = resp?.data;
        }
        if (resp.data) {
          let wrGroup = '';
          if (data?.wearerGroup) {
            wrGroup = data.wearerGroup;
          } else {
            const selectedFacility = this.headerService.getCurrentFacility;
            const loginData: any = this.authService.getLoginData;
            const wearerGroups = loginData.facility.find((item: any) => item.facilityId === selectedFacility.facilityId)?.wearerGroupDefinition || [];
            wrGroup = wearerGroups.find((item: any) => item.wearerGroupName?.toLowerCase() === 'resident' || item.wearerGroupName?.toLowerCase() === 'residents')?.id;
          }
          resp.data = resp.data.filter((item: any) => wrGroup === item.wearerGroup);
          for (const record of resp.data) {
            if (record.avgTemp && record.avgTemp !== 'NaN') {
              record.avgTemp = this.tempUnitId === 1 ? record.avgTemp : this.uiUtilityService.celsiusToFahrenheit(record.avgTemp);
              record.avgTempFormatted = this.tempUnitId === 1 ? `${record.avgTemp}°C` : `${record.avgTemp}°F`;
            }
            if (record.tempTrendsGraph?.datasets?.length) {
              const datasets = record.tempTrendsGraph.datasets;
              const arrayCopy = [...datasets];
              const removeIndex = datasets.findIndex((item: any) => item.type === 'line');
              const [removedObject] = arrayCopy.splice(removeIndex, 1);
              record.tempTrendsGraph.datasets = arrayCopy;
              record.tempTrendsGraph.datasets1 = [removedObject];
              const barData1 = datasets.find((item: any) => item.type === 'bar') || {};
              const barData = barData1?.data || [];
              if (barData) {
                record.tempTrendsGraph.labels1 = [...record.tempTrendsGraph.labels];
                record.tempTrendsGraph.labels = Array(barData.length).fill("");
                barData1['data'] = barData.map((item: any) => this.tempUnitId === 1 ? item : this.uiUtilityService.celsiusToFahrenheit(item));
              }
            }
          }
        }
        this.reportData = resp;
        this.setWearerTitle(data?.wearerGroup);
        this.setData();
      }, error: () => {
        this.loading = false;
      }
    });
  }

  setWearerTitle(groupName?: any) {
    const selectedFacility = this.headerService.getCurrentFacility;
    const loginData: any = this.authService.getLoginData;
    const wearerGroups = loginData.facility.find((item: any) => item.facilityId === selectedFacility.facilityId)?.wearerGroupDefinition || [];
    if (groupName) {
      const name = wearerGroups.find((item: any) => item.id === groupName)?.label || '';
      this.wearerTitle = this.reportData?.data.length > 1 ? name : name.replace(/s\s*$/, "");
    } else if (wearerGroups.length) {
      const name = wearerGroups[0].label;
      this.wearerTitle = this.reportData?.data.length > 1 ? name : name.replace(/s\s*$/, "");
    }
  }

  onSubmitFilter(data: any) {
    this.reportData = {};
    this.sortedData = [];
    if (data.period === 'day') {
      this.getData(data);
    } else if (data.period === 'week') {
      this.reportData = cloneDeep(mockWeekReport);
      this.setData();
    } else if (data.period === 'month') {
      this.reportData = cloneDeep(mockMonthReport);
      this.setData();
    }
    this.isTemperature = data.isTemperature;
    this.isBedPosition = data.isBedPosition;
    this.showGraph = false;
    // this.setWearerTitle(data.wearerGroup);
    let clonedData = [...this.tableDataCopy];
    this.graphCols = 'col-3';
    if (!data.isTemperature) {
      clonedData = clonedData.filter((item: any) => item.type !== 'temp');
      this.graphCols = 'col-2';
    }
    if (!data.isBedPosition) {
      clonedData = clonedData.filter((item: any) => item.type !== 'bed');
      this.graphCols = 'col-1';
    }
    this.tableData = clonedData;
    this.showGraph = true;
    this.charts?.forEach((chart) => {
      chart.chart?.resize();
    });
  }

  setData() {
    const firstRecord = this.reportData?.data && this.reportData.data[0];
    if (!firstRecord) {
      return;
    }
    for (const tableRow of this.tableDataCopy) {
      if (tableRow.graph) {
        this.reportChartOptions[tableRow.key] = this.getChartOptions(firstRecord[tableRow.key], tableRow.key);
      }
    }
    for (const data of this.reportData.data) {
      for (const tableRow of this.tableDataCopy) {
        if (tableRow.graph) {
          this.setFormattedData(data[tableRow.key]);
        }
      }
    }
    this.sortedData = [...this.reportData.data];
  }

  setFormattedData(data: any) {
    if (data?.category === 'bar' && data.datasets?.length) {
      for (const data1 of data.datasets) {
        // data1.barThickness = 5;
      }
    }
    if (data?.category === 'stepped-line' && data.datasets?.length) {
      for (const data1 of data.datasets) {
        data1.fill = false;
        data1.borderColor = 'transparent';
        data1.tension = 0.4;
        data1.stepped = 'after';
      }
    }
  }

  sortTable(column: string) {
    if (this.currentSortColumn === column) {
      this.isAscending = !this.isAscending; // Toggle sorting direction
    } else {
      this.currentSortColumn = column;
      this.isAscending = true; // Default to ascending when sorting a new column
    }

    this.sortedData = this.reportData.data.sort((a: any, b: any) => {
      const valueA = a[column];
      const valueB = b[column];

      if (valueA < valueB) return this.isAscending ? -1 : 1;
      if (valueA > valueB) return this.isAscending ? 1 : -1;
      return 0;
    });
  }

  getSortIcon(column: string): string {
    if (this.currentSortColumn === column) {
      return this.isAscending ? 'arrow-up' : 'arrow-down';
    }
    return 'swap-vertical'; // Default icon
  }

  trackByFn(index: number, item: any): number {
    return item.wearerId; // Use a unique identifier
  }

  getChartOptions(config: any, type: string) {
    let options = {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0, // Disables animation
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Hides vertical gridlines
          },
          ticks: {
            display: false
          },
          title: {
            display: false, // Hides the x-axis label
          },
        },
        y: {
          grid: {
            display: false, // Hides horizontal gridlines
          },
          ticks: {
            display: false,
            beginAtZero: true,
          },
          title: {
            display: false, // Hides the x-axis label
          },
        },
      },
      elements: {
        point: {
          radius: 0, // Hides all points
        },
      },
    };
    if (type === 'tempTrendsGraph') {
      options = merge(options, {
        scales: {
          y: {
            min: this.tempUnitId === 1 ? 32 : 89.6,
            max: this.tempUnitId === 1 ? 40 : 104
          }
        }
      });
    }
    if (config?.category === 'stacked-bar') {
      options = merge(options, {
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true,
          }
        }
      });
    } else if (config?.category === 'stepped-line') {

    }
    return options;
  }

  onShowValChange(event: any) {
    const temp = [...this.reportData.data];
    const selected = event.target.value;
    if (selected.value) {
      this.sortedData = temp.filter((item: any) => {
        const keys1 = selected.name.split(',');
        let flag = false;
        for (const key of keys1) {
          if (item[key] === selected.value) {
            flag = true;
          }
        }
        return flag;
      });
    } else {
      this.sortedData = temp;
    }
  }

  onRowClick(row: any) {
    const reportData = cloneDeep(this.reportData);
    const rowCopy = cloneDeep(row);
    if (rowCopy.tempTrendsGraph?.datasets?.length) {
      let datasets = rowCopy.tempTrendsGraph.datasets;
      if (rowCopy.tempTrendsGraph.datasets1) {
        datasets = [...datasets, ...rowCopy.tempTrendsGraph.datasets1];
        delete rowCopy.tempTrendsGraph.datasets1;
      }
      rowCopy.tempTrendsGraph.datasets = datasets;
      const barData = datasets.find((item: any) => item.type === 'bar')?.data || [];
      if (barData && rowCopy.tempTrendsGraph.labels1) {
        rowCopy.tempTrendsGraph.labels = [...rowCopy.tempTrendsGraph.labels1];
        delete rowCopy.tempTrendsGraph.labels1;
      }
    }
    reportData['data'] = rowCopy;
    this.nav.push(WearerReportDetailsPage, { reportInputData: reportData });
  }

  downloadPDF() {
    const div = this.content.nativeElement;

    html2canvas(div).then((canvas) => {
      const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait, mm units, A4 size

      // Dimensions of the A4 page in pixels (for 72 DPI)
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const canvasHeight = canvas.height;
      const canvasWidth = canvas.width;

      // Calculate the scale factor to fit the canvas width into the PDF width
      const scaleFactor = pdfWidth / canvasWidth;
      const imgHeight = canvasHeight * scaleFactor;

      let yOffset = 0;

      while (yOffset < imgHeight) {
        // Get the portion of the canvas for the current page
        const pageCanvas = document.createElement('canvas');
        const context = pageCanvas.getContext('2d');

        // Set the dimensions of the page canvas
        pageCanvas.width = canvasWidth;
        pageCanvas.height = pdfHeight / scaleFactor;

        // Draw the corresponding part of the original canvas onto the page canvas
        context?.drawImage(
          canvas,
          0,
          yOffset / scaleFactor,
          canvasWidth,
          pageCanvas.height,
          0,
          0,
          canvasWidth,
          pageCanvas.height
        );

        // Convert the page canvas to an image
        const pageImgData = pageCanvas.toDataURL('image/png');
        const margin = 20;
        pdf.addImage(pageImgData, 'PNG', margin, margin, pdfWidth - 2 * margin, pdfHeight - 2 * margin);

        yOffset += pdfHeight;

        if (yOffset < imgHeight) {
          pdf.addPage(); // Add a new page for the next portion
        }
      }

      // Generate a timestamp for the filename
      const now = new Date();
      const zonedDate = toZonedTime(now, this.timeZone);
      const day = format(zonedDate, 'yyyy-MM-dd HH-mm-ss');

      // Save the PDF
      pdf.save(`${this.facilityName}-${day}.pdf`);
    });
  }

  onInfo(msg: string) {
    this.uiUtilityService.showAlert(msg, 'Info');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}