import { RegexConfigModel } from './regex.model';

export class LoginResponseModel {
  userId = '';
  userRole = '';
  userName = '';
  emailId = '';
  isPsswdChanged = '';
  facility = [new FacilityModel()];
  success = false;
  message = '';
  corpId = '';
  tempUnit = 1;
  validationConfigList = [new RegexConfigModel()];
  transactions = [];
  timeout = 20000;
  wearerAlertsMetadata = [];
}

export class FacilityModel {
  facilityId = '';
  facilityName = '';
  timeZone = '';
  isDisabled = false;
  enableCaspar = false;
  enablePCC = false;
  caspar_siteid?: any = '';
  pccOffBedSlot = '';
  pccOffMaxmBedSlotPerRoom = 0;
}

export interface ValidationConfig {
  required?: boolean;
  minLength: number;
  maxLength: number;
  pattern: string;
  errorMessage: string;
  server: string;
}
