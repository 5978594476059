import { FLocation } from './floor-map.model';

export class DashboardResponseModel {
  ondoRespDetails: DashboardData[] = [new DashboardData()];
  sortingDelimiter = 0;
  currentStatusSummary: CurrentStatusSummary = new CurrentStatusSummary();
  requestId = '';
}

export class DashboardData {
  wearerId = '';
  assetId = '';
  localId = '';
  wearerGroup = '1';
  wearerGroupName = 'Resident';
  firstName = '';
  lastName = '';
  skinTemperature = '';
  temperature: Temperature = new Temperature();
  lastTempTime = '';
  bandState: number | string = '';
  wearerState: number | string = '';
  runingHourAvgInC = '';
  runingHourAvgInF = '';
  band: Band = new Band();
  graphDisplay: TrendChartData[] = [new TrendChartData()];
  briefDisplay: HourlyTrendChartData[] = [new HourlyTrendChartData()];
  customColorsTemp: CustomColors[] = [new CustomColors()];
  customColorsBrief: CustomColors[] = [new CustomColors()];
  currentTemp = '';
  currentTemperatureInC = '';
  currentTemperatureInF = '';
  displayName = '';
  shortDisplayName = '';
  highestHourlyAvgInC = '';
  highestHourlyAvgInF = '';
  sortingCriteria = '';
  twentyFourHrAvgInC = '';
  twentyFourHrAvgInF = '';
  wearerCreatedTime = '';
  locationInfo: LocationAlert = new LocationAlert();
  locationState = '';
  location = false;
  locationAlert = null;
  metaData = null;
  gracePriod = null;
  sortOrder = 0;
  fnln = '';
  lnfn = '';
  roomName = '';
  roomId = 0;
  floorId = 2;
  locationOnMap: FLocation = new FLocation();
  intellisense: boolean = false;
  dry: boolean = false;
  wet: boolean = false;
  confused: boolean = false;
  inBed: boolean = false;
  staticWearer = true;
  lightStatus = false;
  roomInOrOut = 'In';
  mapPinColor = '';
  tempIconColor = '';
  entityIconColor = '';
  assetIconColor = '';
  assetState: number | string = '';
  bandId: string = '';
  tagId: string = '';
  isTag: boolean = false;
  patientId = '';
  slotName = '';
}

export class CurrentStatusSummary {
  Caregivers = [new StatusSummaryChartData()];
  Residents = [new StatusSummaryChartData()];
}

export class SummaryChartData {
  Residents = {
    name: '',
    value: '',
  };
}

export class StatusSummaryChartData {
  name = '';
  value = '';
}

export class TrendChartData {
  name = '';
  value = '';
  colorCode = '';
  valueInC = '';
  valueInF = '';
  timeStamp = '';
}
export class HourlyTrendChartSeries {
  name = '';
  value = 0;
  fromTime = '';
  toTime = '';
  colorCode = '';
  actualFromTime = '';
  actualEndTime = '';
  exposureInMins = '';
}
export class HourlyTrendChartData {
  name = '';
  series = [new HourlyTrendChartSeries()];
}

export class Band {
  class = '';
  state = 0;
  icon = '';
  stateMsg = '';
  description = '';
}
export class LocationAlert {
  class = '';
  icon: string | null = '';
  state = 0;
  label = '';
  description = '';
}
export class CustomColors {
  name: string | number = 0;
  value = '';
}

export class Temperature {
  value: string | number | null = null;
  color = '';
}

export class LastUpdatedTime {
  value = '';
  color = '';
}
