import { Injectable } from '@angular/core';
import { DeviceInfo } from '@capacitor/device';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';

@Injectable()
export class BleService {

  constructor(
    private diagnostic: Diagnostic,
    private locationAccuracy: LocationAccuracy
  ) { }

  /**
   * Turn on GPS for Android version 10+
   * @returns {Promise<boolean>} if GPS on true, else false 
   */
  checkForAndroidVersion(deviceInfo: DeviceInfo) {
    const androidTenVersion = 10;
    //  if (deviceInfo.platform === 'android' && +deviceInfo.osVersion >= androidTenVersion) {
    //   return this.checkAppGpsPermission();
    // }
    return Promise.resolve(true);
  }

  /**
   * Check for GPS permission, if not granted request for the permission.
   * @returns {Promise<boolean>} if GPS on true, else false          
   */
  checkAppGpsPermission() {
    return this.diagnostic.getPermissionAuthorizationStatus(this.diagnostic.permission.ACCESS_FINE_LOCATION)
      .then((result) => {
        if (result === this.diagnostic.permissionStatus.GRANTED) {
          return this.askToTurnOnGPS();
        } else {
          return this.askGPSPermission();
        }
      },
        () => {
          return Promise.resolve(false);
        });
  }

  /**
   * Ask for gps permission if user has not granted already
   * @returns {Promise<boolean>} if GPS on true, else false 
   */
  askGPSPermission() {
    return this.diagnostic.requestRuntimePermission(this.diagnostic.permission.ACCESS_FINE_LOCATION)
      .then((status) => {
        if (status === this.diagnostic.permissionStatus.GRANTED) {
          return this.askToTurnOnGPS();
        } else {
          return Promise.resolve(false);
        }
      });
  }

  /**
   * Use this function to turn on the GPS which is needed to scan BLE devices
   * @returns {Promise<boolean>} if GPS on true, else false 
   */
  async askToTurnOnGPS(): Promise<boolean> {
    return await new Promise((resolve) => {
      this.locationAccuracy.canRequest().then((canRequest: boolean) => {
        if (canRequest) {
          this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY).then(
            () => { resolve(true); },
            () => { resolve(false); }
          );
        }
        else {
          resolve(false);
        }
      });
    });
  }

}
