<app-unified-header [title]="isAddFacility ? 'Add Facility' : editEnable ? 'Edit Facility Details' : 'Facility Details'"
  (onSave)="!editEnable && !isAddFacility ? enableEditMode() : manageFacility(isAddFacility ? 'post': 'put')"
  defaultHref="/facility-management" [customBack]="this.editEnable || this.isAddFacility" (onCustomBack)="backClick()"
  [saveBtn]="{title: !editEnable && !isAddFacility ? 'Edit' : 'Save', isDisable: !editEnable && !isAddFacility ? false : isSaveDisabled, rule: !isAddFacility ? 'put'+EndPoints.manageFacility : ''}"
  [isRootPage]="isRootPage && !editEnable"></app-unified-header>

<ion-content>
  <div *ngIf="facilityDetailsForm">
    <div class="ion-text-center p-t-10" *ngIf="loading">
      <ion-spinner></ion-spinner>
    </div>
    <form [formGroup]="facilityDetailsForm">

      <!-- read mode -->
      <div class="coporate-details-edit" *ngIf="editEnable || isAddFacility">
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityName')">
          <ion-label>Facility Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityPhone')">
          <ion-label>Facility Phone</ion-label><ion-text>{{facilityDetailsForm?.get('facilityPhone')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityEmail')">
          <ion-label>Facility Email</ion-label><ion-text>{{facilityDetailsForm?.get('facilityEmail')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityRepresentativeName')">
          <ion-label>Facility Representative
            Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail" button (click)="presentAlert('facilityRepresentativeTitle')">
          <ion-label>Facility Representative Title
          </ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeTitle')?.value}}</ion-text>
        </ion-item>
        <ion-item>
          <ion-label slot="start">Facility Time Zone</ion-label>
          <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'timezone-dropdown'}"
            placeholder="Select" formControlName="timeZone">
            <ion-select-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{timeZone}}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div class="group-item" *ngIf="isPCCEnabled">
          <ion-item class="form-item-detail first-item-border">
            <ion-label>PointClickCare Integration</ion-label>
            <ion-toggle mode="ios" color="controls" slot="end" [checked]="facilityDetailsForm?.get('enablePCC')?.value"
              (ionChange)="pccEnableChanged($event)"></ion-toggle>
          </ion-item>
        </div>
        <!-- <div class="group-item" *ngIf="!isPCCEnabled || !facilityDetailsForm?.get('enablePCC')?.value">
          <ion-item class="form-item-detail first-item-border">
            <ion-label>Bed Slot Naming</ion-label>
            <ion-select slot="end" style="border: none;" interface="popover"
              [interfaceOptions]="{cssClass: 'timezone-dropdown'}" placeholder="Select" formControlName="pccOffBedSlot">
              <ion-select-option *ngFor="let bSlot of bedSlotsList" [value]="bSlot.key">
                {{bSlot.displayName}}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item class="form-item-detail first-item-border">
            <ion-label>Max Beds per Room</ion-label>
            <ion-select slot="end" style="border: none;" interface="popover"
              [interfaceOptions]="{cssClass: 'timezone-dropdown'}" placeholder="Select"
              formControlName="pccOffMaxmBedSlotPerRoom">
              <ion-select-option *ngFor="let mBed of maxBedsList" [value]="mBed.key">
                {{mBed.displayName}}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </div> -->
      </div>

      <!-- edit mode -->
      <div class="coporate-details-edit" *ngIf="!editEnable && !isAddFacility">
        <ion-item class="form-item-detail">
          <ion-label>Facility Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Phone</ion-label><ion-text>{{facilityDetailsForm?.get('facilityPhone')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Email</ion-label><ion-text>{{facilityDetailsForm?.get('facilityEmail')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Representative
            Name</ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeName')?.value}}</ion-text>
        </ion-item>
        <ion-item class="form-item-detail">
          <ion-label>Facility Representative Title
          </ion-label><ion-text>{{facilityDetailsForm?.get('facilityRepresentativeTitle')?.value}}</ion-text>
        </ion-item>
        <ion-item class="disable-click">
          <ion-label slot="start">Facility Time Zone</ion-label>
          <ion-select slot="end" interface="popover" [interfaceOptions]="{cssClass: 'timezone-dropdown'}"
            placeholder="Select" formControlName="timeZone">
            <ion-select-option *ngFor="let timeZone of timeZones" [value]="timeZone">
              {{timeZone}}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <div class="group-item" *ngIf="isPCCEnabled">
          <ion-item class="form-item-detail first-item-border">
            <ion-label>PointClickCare Integration</ion-label>
            <div slot="end" class="custom-input">
              <ion-input [value]="facilityDetailsForm?.get('enablePCC')?.value ? 'On' : 'Off'" readonly></ion-input>
            </div>
          </ion-item>
        </div>
        <!-- <div class="group-item" *ngIf="!isPCCEnabled || !facilityDetailsForm?.get('enablePCC')?.value">
          <ion-item class="form-item-detail first-item-border">
            <ion-label>Bed Slot Naming</ion-label>
            <ion-text>{{facilityDetailsForm?.get('pccOffBedSlot')?.value}}</ion-text>
          </ion-item>
          <ion-item class="form-item-detail">
            <ion-label>Max Beds per Room</ion-label>
            <ion-text>{{facilityDetailsForm?.get('pccOffMaxmBedSlotPerRoom')?.value}}</ion-text>
          </ion-item>
        </div> -->
      </div>

      <div class="group-item" *ngIf="!isAddFacility && !editEnable" [nAccess]="EndPoints.getFacilityFloorsList">
        <div>
          <ion-item detail class="first-item-border" (click)="viewFloorList()">
            <ion-label>Facility Floors</ion-label>
          </ion-item>
          <ion-row>
            <p class="notification-info corporatepanel">Optional. Add facility floors and floor plans here to use with
              features such as Location.</p>
          </ion-row>
        </div>
      </div>

      <div class="group-item ion-text-center sync-actions"
        *ngIf="isPCCEnabled && facilityDetailsForm?.get('enablePCC')?.value && !editEnable && !isAddFacility">
        <ion-button (click)="forceRoomSyncPCC()" [disabled]="syncStatus['ROOM_SYNC']?.loading">
          Room Sync
          <ion-spinner *ngIf="syncStatus['ROOM_SYNC']?.loading"></ion-spinner>
        </ion-button>
        <ion-button (click)="forceSlotSyncPCC()"
          [disabled]="!syncStatus['ROOM_SYNC']?.resolved || syncStatus['BEDSLOT_SYNC']?.loading">
          Bed Slot Sync
          <ion-spinner *ngIf="syncStatus['BEDSLOT_SYNC']?.loading"></ion-spinner>
        </ion-button>
        <ion-button (click)="forcePatientSyncPCC()"
          [disabled]="!syncStatus['BEDSLOT_SYNC']?.resolved || syncStatus['PAITENT_SYNC']?.loading">
          Patient Sync
          <ion-spinner *ngIf="syncStatus['PAITENT_SYNC']?.loading"></ion-spinner>
        </ion-button>
        <ion-button (click)="forceSyncPCC()" disabled>Force Sync</ion-button>
        <!-- <div class="sync-note-container">
          <ion-note>
            {{syncStatus['ROOM_SYNC']?.loading ? 'Room Sync running...' : (syncStatus['ROOM_SYNC']?.synctime ? 'Room
            Sync
            last completed at ' + syncStatus['ROOM_SYNC']?.synctime : '')}}
          </ion-note>
          <ion-note>
            {{syncStatus['BEDSLOT_SYNC']?.loading ? 'Bed Slot Sync running...' : (syncStatus['BEDSLOT_SYNC']?.synctime ?
            'Bed Slot Sync
            last completed at ' + syncStatus['BEDSLOT_SYNC']?.synctime : '')}}
          </ion-note>
          <ion-note>
            {{syncStatus['PAITENT_SYNC']?.loading ? 'Patient Sync running...' : (syncStatus['PAITENT_SYNC']?.synctime ?
            'Patient Sync
            last completed at ' + syncStatus['PAITENT_SYNC']?.synctime : '')}}
          </ion-note>
        </div> -->
      </div>

      <!-- <div class="delete-wearer" *ngIf="!isAddFacility && !editEnable">
        <ion-button (click)="deleteFacility()" color="danger" [disabled]="true">Delete This Facility</ion-button>
      </div> -->
    </form>
  </div>
</ion-content>