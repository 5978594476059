export enum EndPointsEnum {
  dashboard = 'getDashboard',
  getCorpAccount = 'getcorporateaccount',
  getRoomDetail = '/getRoomDetail',
  getRoomType = 'getRoomType',
  getRoomSubtype = 'getRoomSubtype',
  getFacilityFloorList = '/getFacilityFloorsList',
  getAvailableResidentList = 'getAvailableResidentList',
  getResidentDetails = 'getResidentDetail',
  addResidentToRoom = 'addResidentToRoom',
  removeResidentToRoom = 'removeResidentToRoom',
  editCorpAccount = 'editcorporateaccount',
  addCorpAccount = 'createCorporateAccount',
  wearerList = 'getwearerlist',
  getWearerDetails = 'getwearerDetails',
  getUserList = 'getUserList',
  manageUser = 'manageuser',
  manage = 'manage',
  manageRoom = 'manageRoom',
  facilityList = 'getFacilityList',
  facilityDetail = 'getFacilityDetail',
  manageFacility = 'managefacility',
  manageFloors = 'manageFloors',
  managefloors = 'managefloors',
  getBridgeList = 'getBridgeList',
  checkAvailability = 'checkAvailability',
  bandLookUp = 'bandLookup',
  loginResponse = 'getLoginResponse',
  recoverPassword = 'resetPsswd',
  recoverUserId = 'recoverUserId',
  changePassword = 'changePassword',
  userDeviceRegistration = 'userDeviceRegistration',
  userDeviceDeRegistration = 'userDeviceDeRegistration',
  updateNotificationPreferences = 'updateNotificationPreferences',
  bridgeAvailability = 'bridgeavailability',
  monthlyDetection = 'monthlyDetection',
  ps30DaysDetection = 'getNotableEvent',
  monthlyUsage = 'monthlyUsage',
  ps30DaysUsage = 'getWearerUsage',
  wsNotableEvents = 'getWearerNotableEvents',
  ps30DaysLocationEvents = 'getLocationNotableEvent',
  getWearerLocation = 'getWearerLocation',
  getFacilityFloorsList = 'getFacilityFloorsList',
  getBridgeFloorsList = 'getFloorsList',
  manageFloorBridges = 'manageFloorBridges',
  unassignFloor = 'unassignFloor',
  logout = 'logout',
  accessToken = 'accessToken',
  login = 'login',
  getWearerGroups = 'getWearerGroups',
  getWearerGroupList = 'getWearerGroupList',
  getList = 'getList',
  getResidentDetail = 'getResidentDetail',
  getRoomList = 'getRoomList',
  getAvailableBeds = 'getAvailableBeds',
  assignBandTagToWearer = 'assignBandTagToWearer',
  getBedPosition = 'getBedPosition',
  assignBandTagToAsset = 'assignBandTagToAsset',
  rolesList = 'list',
  simulate = 'sendBandToUnifiedQA',
  bridgeKeepAlive = 'sendOndoBridgeKeepAliveQA',
  simulateDusun = 'sendBandToUnifiedDusunQA',
  bridgeKeepAliveDusun = 'sendOndoBridgeKeepAliveDusunQA',
  assignBandToWearer = 'assignBandToWearer',
  getWearerNotableEvents = 'getWearerNotableEvents',
  getAssetNotableEvents = 'getAssetNotableEvents',
  removeBandTagFromWearer = 'removeBandTagFromWearer',
  sendBedData = 'sendBedData',
  getNotificationPreferences = 'getNotificationPreferences'
}
