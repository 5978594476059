import { Injectable } from '@angular/core';
import {
  AlertController,
  AlertInput,
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { Subject } from 'rxjs';
import { APP_VERSION, META_ASSETS, MOBILE_BREAKPOINT_WIDTH, TABLET_BREAKPOINT_WIDTH } from 'src/app/shared/config/app.constant';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';
import { ModalParams } from 'src/app/shared/models/modal-params.model';

@Injectable({
  providedIn: 'root',
})
export class UiUtilityService {
  private modal: HTMLIonModalElement | null = null;
  public pageModal: HTMLIonModalElement | null = null;
  private loading: HTMLIonLoadingElement | null = null;
  private isAlertPresent = false;

  menuClicked = new Subject<any>();
  menuId = new Subject<any>();
  isPageModalOpen = new Subject<any>();
  isMenuOpen = new Subject<any>();
  updateMenu = new Subject<any>();

  constructor(
    public modalCtrl: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private platform: Platform
  ) { }

  /**
   * Create modal
   * @param params - All configurations required to open modal
   */
  async createModal(params: ModalParams): Promise<HTMLIonModalElement> {
    this.modal = await this.modalCtrl.create({
      component: params.component,
      mode: params.mode,
      cssClass: params.cssClass,
      canDismiss: params.canDismiss || true,
      backdropDismiss: false,
      componentProps: { data: params.data },
      enterAnimation: params.enterAnimation,
      leaveAnimation: params.leaveAnimation,
    });
    return this.modal;
  }

  /**
   * Close modal
   * @param params - All configurations required to open modal
   */
  closeModal(): void {
    if (this.modal) {
      this.modal.dismiss();
      this.modal = null;
    }
  }

  showLoader = async () => {
    try {
      await this.closeLoader();
    } catch (e) {
      console.log(e);
    }
    this.loading = await this.loadingController.create({
      spinner: 'lines',
      translucent: true,
      cssClass: 'full-screen-loader'
    });
    await this.loading.present();
  }

  closeLoader = async () => {
    if (this.loading) {
      try {
        let topLoader = await this.loadingController?.getTop();
        while (topLoader) {
          if (!(await topLoader.dismiss())) {
            throw new Error('Could not dismiss the topmost loader. Aborting...');
          }
          topLoader = await this.loadingController.getTop();
        }
      } catch (e) {
        console.log(e);
        this.loading = null;
      }
    }
  }

  async showAlert(
    message: string,
    header: string,
    buttons: AlertButtonModel[] = [{
      text: 'OK', handler: () => {
        this.isAlertPresent = false;
      }
    }],
    inputs: AlertInput[] = [],
    cssClass = ''
  ) {
    // await this.dismissAlert();
    if (this.isAlertPresent) {
      return;
    }
    this.isAlertPresent = true;
    const alert = await this.alertController.create({
      cssClass: `notable-state-alert ${cssClass}`,
      header,
      inputs,
      message,
      buttons,
      backdropDismiss: false
    });
    await alert.present();
    await alert.onDidDismiss();
    this.isAlertPresent = false;
  }

  doNothing() { }

  async dismissErrorAlert() {
    try {
      if (this.isAlertPresent) {
        await this.alertController.dismiss();
      }
      this.isAlertPresent = false;
    } catch (e) {
      console.log(e);
    }
  }

  async dismissAlert() {
    try {
      this.isAlertPresent = false;
      await this.alertController.dismiss();
    } catch (e) {
      console.log(e);
    }
  }

  async presentConfirm(
    subTitle: string,
    buttonAction: any,
    buttonAction1: any = this.doNothing,
    title: string = 'Confirm',
    buttonTitle: string = 'OK',
    buttonTitle1: string = 'Cancel'
  ) {
    await this.dismissAlert();
    const alert = await this.alertController.create({
      header: title,
      message: subTitle,
      buttons: [
        {
          text: buttonTitle1,
          role: 'cancel',
          handler: () => {
            buttonAction1();
          },
        },
        {
          text: buttonTitle,
          handler: () => {
            buttonAction();
          },
        },
      ],
      backdropDismiss: false
    });
    await alert.present();
  }

  async showToast(
    message: string,
    duration: number = 2500,
    type: string = 'info'
  ) {
    const alert = await this.toastController.create({
      cssClass: 'custom-toast',
      message,
      duration,
      position: 'bottom',
    });
    await alert.present();
  }

  sortList(list: any[], sortingKey: string = 'id', order: number = 1) {
    return list.sort((a, b) => {
      const aVal = a[sortingKey];
      const bVal = b[sortingKey];
      if (typeof aVal === 'string' && typeof bVal === 'string') {
        return order * aVal.toLowerCase().localeCompare(bVal.toLowerCase());
      }
      if (aVal < bVal) {
        return -order;
      }
      if (aVal > bVal) {
        return order;
      }
      return 0;
    });
  }

  getValuesFromUnifiedInput(list: any) {
    const temp: any = {};
    for (const item of list) {
      temp[item.name] = item.value;
    }
    return temp;
  }

  async dismissOverlays() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      this.modalCtrl.dismiss();
    }
    const popover = await this.popoverController.getTop();
    if (popover) {
      this.popoverController.dismiss();
    }
  }

  isNativePlatform() {
    const plts = this.platform.platforms();
    return (
      plts.indexOf('mobileweb') === -1 &&
      (plts.indexOf('ios') !== -1 || plts.indexOf('android') !== -1)
    );
  }

  getVersion() {
    return APP_VERSION;
  }

  isMobileBreakpoint() {
    return window.innerWidth <= MOBILE_BREAKPOINT_WIDTH;
  }

  isTabletBreakpoint() {
    return window.innerWidth < TABLET_BREAKPOINT_WIDTH;
  }

  isDesktopBreakpoint() {
    return window.innerWidth > TABLET_BREAKPOINT_WIDTH;
  }

  capitalize(text: string) {
    return text.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  getEntityDisplayName(data: any) {
    let name = data.localId ? `${data.localId}` : '';
    if (name && data.displayName && data.metaData === META_ASSETS) {
      name = `${name}, ${data.displayName}`;
    } else if (data.displayName && data.metaData === META_ASSETS) {
      name = `${data.displayName}`;
    } else {
      const n = name.trim();
      const fname = data.firstName?.trim();
      const lname = data.lastName?.trim();
      name = `${n}${(n && lname) ? ` - ${lname}` : (lname || '')}${(fname && (lname || (n && !lname))) ? `, ${fname}` : (fname || '')}`;
    }
    return name;
  }

  getEntityShortDisplayName(data: any) {
    let name = data.localId ? `${data.localId}` : '';
    const n = name.trim();
    const fname = data.firstName?.trim().charAt(0).toUpperCase();
    const lname = data.lastName?.trim().charAt(0).toUpperCase();
    name = `${n}${(n && lname) ? ` - ${lname}` : (lname || '')}${(fname && (lname || (n && !lname))) ? `,${fname}` : (fname || '')}`;
    return name;
  }

  getAssetDisplayName(data: any) {
    let name = data.assetLocalId ? `${data.assetLocalId}` : '';
    if (name && data.assetName) {
      name = `${name}, ${data.assetName}`;
    } else if (data.assetName) {
      name = `${data.assetName}`;
    }
    return name;
  }
}
