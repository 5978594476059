<ion-header mode="ios">
  <ion-toolbar mode="ios">
    <ion-buttons>
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" class="close-icon" src="assets/svg/close.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="title">Message Drawer</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="clearAll()" class="clear-btn" [disabled]="!messages.length">Clear
        All</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list class="list">
    <ion-item class="list-item" *ngFor="let message of messages">

      <ion-label class="list-content">
        <h3>
          <ion-icon *ngIf="message.isError" class="error-icon" src="assets/svg/warning.svg"></ion-icon>
          <span [ngClass]="{'error': message.isError, 'app-link': part === '{residentID}'}"
            *ngFor="let part of message.parts" (click)="onLinkClick(part === '{residentID}', message)">{{part ===
            '{residentID}' ? message.wearerName : part}}</span>
        </h3>
        <p>{{ message.subText }}</p>
      </ion-label>
      <ion-note slot="end">{{ message.timestamp }}</ion-note>
    </ion-item>
    <ion-item *ngIf="!loading && !messages.length">
      No messages
    </ion-item>
  </ion-list>
</ion-content>
<ion-footer>
  <div class="message-footer" *ngIf="isLastUpdateLoading">
    Loading...
  </div>
  <div class="message-footer" *ngIf="lastUpdated !== '' && !isLastUpdateLoading">Last updated at
    {{lastUpdated}}</div>
</ion-footer>