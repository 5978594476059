export class RoomDetails {
  bridges = [];
  assets = [];
  roomDetail = new RoomDetail();
  devices = [];
  beds = [];
  residents: any = [];
}

export class RoomDetail {
  floorName = '';
  id = '';
  floorId = '';
  roomTypeName = '';
  subTypeName = '';
  roomName = '';
  startDate = '';
  endDate = '';
  createdBy = '';
  createDate = '';
  updateDate = '';
  updatedBy = '';
}
