import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { UnifiedFilterComponent } from '../unified-filter/unified-filter.component';
import { SettingsService } from 'src/app/features/settings/services/settings.service';

@Component({
  standalone: true,
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
  imports: [IonicModule, CommonModule]
})
export class SubHeaderComponent implements OnInit, OnDestroy {
  @Output() onFilterApply = new EventEmitter<any>();
  @Output() onFilterCancel = new EventEmitter<any>();
  @Output() onFilterReset = new EventEmitter<any>();
  @Input() groups: any[] = [];
  isFilterApplied: boolean = false;
  floorLabel: any[] = [];
  floorTitle = 'All Floor';
  floorList: any;
  selectedFloors: any[] = [];
  allFloorSelected: boolean = true;

  constructor(
    private modalCtrl: ModalController, private settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.getFloorList();
  }

  async openFilter() {
    const modal = await this.modalCtrl.create({
      component: UnifiedFilterComponent,
      componentProps: {
        groups: this.groups,
      },
    });
    modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'confirm') {
      this.selectedFloors = [];
      this.floorLabel = [];
      this.isFilterApplied = data?.filter((item: any) => item.filterApplied).length > 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].property == 'floorId') {
          for (let j = 0; j < data[i].value.length; j++) {
            this.floorLabel.push(data[i].value[j]);
          }
          this.checkSelectedFloors();
        }
      }
      this.onFilterApply.emit(data);
    } else if (role === 'reset') {
      this.isFilterApplied = false;
      this.selectedFloors = [];
      this.allFloorSelected = true;
      this.onFilterReset.emit(data);
    } else if (data) {
      this.onFilterCancel.emit(data);
    }
  }

  ngOnDestroy(): void {
  }

  getFloorList() {
    this.settingsService.getFloorListDetails().subscribe((response) => {
      if (response.success) {
        const floors = response.data.floors;
        this.floorList = response.data.floors;
      }
    });
  }

  checkSelectedFloors() {
    this.selectedFloors = [];
    for (let i = 0; i < this.floorList.length; i++) {
      for (let j = 0; j < this.floorLabel.length; j++) {
        if (this.floorList[i].floorID == this.floorLabel[j]) {
          this.selectedFloors.push(this.floorList[i].floorName);
        }
      }
    }
    if (this.selectedFloors.length == this.floorList.length) {
      this.allFloorSelected = true;
    } else {
      this.allFloorSelected = false;
    }
    this.selectedFloors = [...this.selectedFloors];
  }
}
