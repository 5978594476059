<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons>
      <ion-back-button (click)="goBack()"></ion-back-button>
    </ion-buttons>
    <ion-title>Select Room</ion-title>
    <ion-buttons slot="end">
      <ion-button [disabled]="!isSaveEnabled" (click)="associateRoomToResident()"> Save </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-searchbar (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar" animated
    placeholder="Type to search...">
  </ion-searchbar>
</ion-header>


<ion-content>
  <ion-list *ngIf="roomList.length; else noRoomsAvailable">
    <ion-radio-group [formControl]="selectRoomControl" (ionChange)="isSaveEnabled = true">
      <ion-item *ngFor="let room of roomList">
        <ion-radio mode="md" color="controls" justify="start" labelPlacement="end" [value]="room.id">{{room.roomName}}
          <span *ngIf="room.id === queryParams?.roomId">(Current)</span></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
  <ng-template #noRoomsAvailable>
    <div class="ion-text-center">
      <p>No rooms available right now.</p>
    </div>
  </ng-template>
</ion-content>