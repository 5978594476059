export enum StorageEnum {
    facilityWifi = 'FACILITY_WIFI',
    bridgeList = 'BridgeList',
    facilityList = 'FacilityList',
    userList = 'UserList',
    bridgeDetails = 'BridgeDetails',
    selectedFacility = 'SelectedFacility',
    loginData = 'LoginData',
    authToken = 'AuthToken',
    loginCredentials = 'LoginCredentials',
    filteredGroups = 'FilteredGroups',
    sortingType = 'SortingType',
    compTemperatureMode = 'tempUnit',
    pushNotificationToken = 'PushNotificationToken',
    refreshToken = 'refreshToken',
    cst = 'cst',
    notifications = 'notifications'
}
