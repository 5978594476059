export const BED_IN = "bed-in";
export const BED_INDETERMINATE = "bed-indeterminate";
export const BED_OUT = "bed-out";
export const HEART_RATE_BASELINE_ALERT = "heart-rate-baseline-alert";
export const HEART_RATE_THRESHOLD_ALERT = "heart-rate-threshold-alert";
export const RESPIRATION_RATE_BASELINE_ALERT = "respiration-rate-baseline-alert";
export const RESPIRATION_RATE_THRESHOLD_ALERT = "respiration-rate-threshold-alert";
export const BED_PRESENCE = "bed-presence";

export const NOTABLE_ALERTS = [HEART_RATE_BASELINE_ALERT, HEART_RATE_THRESHOLD_ALERT, RESPIRATION_RATE_BASELINE_ALERT, RESPIRATION_RATE_THRESHOLD_ALERT];

export const CASPAR_ICON_ORDER: any = {
  [BED_IN]: {
    displayName: "In Bed",
    icon: "bed_in.svg"
  },
  [BED_INDETERMINATE]: {
    displayName: 'Indeterminate Bed State',
    icon: "bed_indeterminate.svg"
  },
  [BED_OUT]: {
    displayName: 'Out of Bed',
    icon: "bed_out.svg"
  },
  [HEART_RATE_BASELINE_ALERT]: {
    displayName: 'Heart Rate Deviation',
    icon: "heartrate_warning.svg"
  },
  [HEART_RATE_THRESHOLD_ALERT]: {
    displayName: 'High Heart Rate',
    icon: "heartrate_warning.svg"
  },
  [RESPIRATION_RATE_BASELINE_ALERT]: {
    displayName: 'Respiration Rate Deviation',
    icon: "respiratory_warning.svg"
  },
  [RESPIRATION_RATE_THRESHOLD_ALERT]: {
    displayName: 'High Respiration Rate',
    icon: "respiratory_warning.svg"
  },
  [BED_PRESENCE]: {
    displayName: 'Resident in a Caspar-enabled bed',
    icon: "caspar_enabled_bed.svg"
  }
};