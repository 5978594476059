import { FILTER_SORT_TYPE, FILTER_TOGGLE_TYPE } from "../config/app.constant";
import { bandStates } from "../enums/band-states";
import { LocationStateDescription, LocationStateText } from "../enums/location-message.enum";
import { notableStateMessage } from "../enums/notable-state-message";

export const dashboardFilterData = [{
  controlType: 'radio',
  label: 'Sort by',
  type: FILTER_SORT_TYPE,
  value: 'ID_ASC',
  fields: [{
    label: 'ID ascending',
    value: 'ID_ASC',
    property: 'localId',
    order: 1
  }, {
    label: 'ID descending',
    value: 'ID_DESC',
    property: 'localId',
    order: -1
  }, {
    label: 'Name ascending',
    value: 'NAME_ASC',
    property: 'displayName',
    order: 1,
    fallbackProperty: [{ filterKey: 'metaData', filterValue: 'Assets', property: 'displayName' }]
  }, {
    label: 'Name descending',
    value: 'NAME_DESC',
    property: 'displayName',
    order: -1,
    fallbackProperty: [{ filterKey: 'metaData', filterValue: 'Assets', property: 'displayName' }]
  }]
}];

export const wearerFilterData = [{
  controlType: 'radio',
  label: 'Sort by',
  type: 'sort',
  value: 'ID_ASC',
  fields: [{
    label: 'ID ascending',
    value: 'ID_ASC',
    property: 'localId',
    order: 1
  }, {
    label: 'ID descending',
    value: 'ID_DESC',
    property: 'localId',
    order: -1
  }, {
    label: 'Name ascending',
    value: 'NAME_ASC',
    property: 'firstName',
    order: 1
  }, {
    label: 'Name descending',
    value: 'NAME_DESC',
    property: 'firstName',
    order: -1
  }]
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Wearer Group',
  type: 'filter',
  value: [],
  fields: [],
  userData: {
    property: 'wearerGroupId',
    label: 'label',
    value: 'id',
    id: 'wearerGroupDefinition'
  }
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Floors',
  type: 'filter',
  value: [],
  fields: [],
  dynamicData: [{
    action: 'getFloorList',
    property: 'floorId',
    isUnassigned: true
  }]
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Wearer Status',
  type: 'filter',
  value: [false, true],
  fields: [{
    label: 'Active wearers',
    property: 'isDeleted',
    value: false
  }, {
    label: 'Inactive wearers',
    property: 'isDeleted',
    value: true
  }]
}];

export const assetFilterData = [{
  controlType: 'radio',
  label: 'Sort by',
  type: 'sort',
  value: 'ID_ASC',
  fields: [{
    label: 'ID ascending',
    value: 'ID_ASC',
    property: 'assetLocalId',
    order: 1
  }, {
    label: 'ID descending',
    value: 'ID_DESC',
    property: 'assetLocalId',
    order: -1
  }, {
    label: 'Name ascending',
    value: 'NAME_ASC',
    property: 'assetName',
    order: 1
  }, {
    label: 'Name descending',
    value: 'NAME_DESC',
    property: 'assetName',
    order: -1
  }]
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Asset Type',
  type: 'filter',
  value: [],
  fields: [],
  dynamicData: [{
    action: 'getAssetType',
    property: 'assetTypeId'
  }]
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Floors',
  type: 'filter',
  value: [],
  fields: [],
  dynamicData: [{
    action: 'getFloorList',
    property: 'floorId',
    isUnassigned: true
  }]
}];


export const roomsFilterData = [{
  controlType: 'radio',
  label: 'Sort by',
  type: 'sort',
  value: 'NAME_ASC',
  fields: [{
    label: 'Room name ascending',
    value: 'NAME_ASC',
    property: 'roomName',
    order: 1
  }, {
    label: 'Room name descending',
    value: 'NAME_DESC',
    property: 'roomName',
    order: -1
  }]
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Floors',
  type: 'filter',
  value: [],
  fields: [],
  dynamicData: [{
    action: 'getFloorList',
    property: 'floorId'
  }]
}, {
  controlType: FILTER_TOGGLE_TYPE,
  label: 'Room Type',
  type: 'filter',
  value: [],
  fields: [],
  dynamicData: [{
    action: 'getRoomType',
    property: 'roomTypeName'
  }]
}];

export const STATES_INFO = [{
  class: "state-red",
  description: "LOCATION WARNING",
  assetDescription: LocationStateDescription.assetWarning,
  icon: "location-warning.png",
  svgIcon: "pin_warning.svg",
  label: LocationStateText.warning,
  state: 1,
  type: 'locationState'
}, {
  class: "state-yellow",
  description: "LOCATION CAUTION",
  assetDescription: LocationStateDescription.assetCaution,
  icon: "location-caution.png",
  svgIcon: "pin_caution.svg",
  label: LocationStateText.caution,
  state: 6,
  type: 'locationState'
}, {
  class: "state-yellow",
  description: notableStateMessage.lowBattery,
  assetDescription: notableStateMessage.lowBattery,
  icon: null,
  svg: null,
  label: bandStates.lowBattery,
  state: 4,
  type: 'bandState'
}, {
  class: "state-offline",
  description: notableStateMessage.offline,
  assetDescription: notableStateMessage.assetOffline,
  icon: null,
  svg: null,
  label: bandStates.offline,
  state: 8,
  type: 'bandState'
}, {
  class: "state-offline",
  description: notableStateMessage.offline,
  assetDescription: notableStateMessage.assetOffline,
  icon: null,
  svg: null,
  label: bandStates.offline,
  state: 9,
  type: 'bandState'
}, {
  class: "state-no",
  description: notableStateMessage.noStatus,
  assetDescription: notableStateMessage.noStatus,
  icon: null,
  svg: null,
  label: bandStates.noStatus,
  state: 11,
  type: 'bandState'
}];