<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Asset Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddAsset()" [nAccess]="'postAsset'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <app-sub-header [groups]="groups" (onFilterApply)="onFilterApply($event)" (onFilterCancel)="onFilterCancel($event)"
    (onFilterReset)="onFilterReset()"></app-sub-header>
  <div *ngIf="assetList?.length || searchAssetList?.length">
    <ion-searchbar [(ngModel)]="searchQuery" (ionInput)="handleChange($event)" [debounce]="100" class="items-searchbar"
      animated placeholder="Type to search...">
    </ion-searchbar>
  </div>
</ion-header>

<ion-content>
  <div *ngIf="assetList?.length || searchAssetList?.length">
    <ion-item *ngFor="let asset of assetList" (click)="navigateToDetailsPage(asset)" detail class="list-with-name">
      <ion-label>
        <h3>{{asset.displayName}}</h3>
        <!-- <p>{{asset.assetType?.assetTypeName}}</p> -->
      </ion-label>
      <ion-label slot="end" class="no-margin-right end-name-max" *ngIf="asset.room?.roomName">
        <h3>
          <span>{{asset.room.roomName}}</span>
        </h3>
      </ion-label>
    </ion-item>
  </div>
  <div class="no-asset" *ngIf="!assetList?.length">
    No assets found in the selected facility.
  </div>
</ion-content>