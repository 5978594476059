import { Injectable } from '@angular/core';
import { DataService } from 'src/app/core/services/api/data.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CasparService {
  constructor(
    private dataService: DataService<any>,
    private storageService: StorageService,
    private headerService: HeaderService
  ) { }

  getCasparActiveAlerts() {
    const fid = this.headerService.getFacilityId();
    const userId = this.storageService.getLocalStorage(
      StorageEnum.loginData,
      true
    )?.userId;
    const params = { userId, client: 'ondo' };
    const url = `${environment.apiUrl.caspar}${fid}/facilityCasparActiveAlertList`;
    return this.dataService.getData(url, params);
  }

  getCasparBedPresenseList() {
    const fid = this.headerService.getFacilityId();
    const userId = this.storageService.getLocalStorage(
      StorageEnum.loginData,
      true
    )?.userId;
    const params = { userId, client: 'ondo' };
    const url = `${environment.apiUrl.caspar}${fid}/facilityCasparBedPresenseList`;
    return this.dataService.getData(url, params);
  }

  getCasparIFrameParams(params: any = {}) {
    const userId = this.storageService.getLocalStorage(
      StorageEnum.loginData,
      true
    )?.userId;
    const token = this.storageService.getLocalStorage(
      StorageEnum.cst
    );
    const result = { userId, token, client: 'ondo', tab: 'alerts' };
    const obj = { ...result, ...params };
    const fid = this.headerService.getFacilityId();
    const paramsString = Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
    return `ond001?${paramsString}`;
  }
}
