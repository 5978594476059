// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientCode: 'unified',
  baseUrl: 'https://unified-qa-api.ondosystem.com/',
  apiUrl: {
    dashboard: 'dashboard/v4/',
    unifiedDashboard: 'unifiedDashboard/v2/',
    corporate: 'corporate/v1/',
    roomtype: 'roomManagement/v1/',
    managefloors: 'managefloors/v1/',
    roomManagement: 'roomManagement/v1/',
    pccRoomManagement: 'pccRoomManagement/v1/',
    residentManagement: 'residentManagement/v1/',
    wearer: 'wearer/v1/',
    bridge: 'bridge/v1/',
    band: 'band/v1/',
    auth: 'auth/',
    user: 'users/v1/',
    assetManagement: 'assets/v1/',
    bedManagement: 'bedManagement/v1/',
    roles: 'roles/v1/',
    bridgeManagement: 'bridge/v1/',
    location: 'location/v5/',
    simulator: 'simulator/v1/',
    caspar: 'caspar/v1/',
    graph: 'graph/v1/'
  },
  casparIframeURL: 'https://care-test.caspar.ai/'
  // ports: {
  //   dashboard: '8041/',
  //   wearer: '8051/',
  //   corporate: '8046/',
  //   bridge:'8047/',
  //   band: '8048/'
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
