import { ErrorHandler, Injectable } from '@angular/core';
import { format } from "date-fns/format";
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

@Injectable({
  providedIn: 'root',
})
export class LoggerService implements ErrorHandler {
  handleError(error: any): void {
    // Log the error to the console
    let date = '';
    try {
      date = format(new Date(), 'M/d/yyyy h:mm:ss a');
    } catch (e) {

    }
    try {
      console.error('Global Error Handler:', error);
      this.saveErrorToFile(`${JSON.stringify(error)} - ${date}`);
    } catch (e) {
      console.error('Global Error Handler:', error);
      this.saveErrorToFile(`${error} - ${date}`);
    }
    // Reload the app after getting the error
    // window.location.reload();
  }

  async saveErrorToFile(errorDetails: string) {
    try {
      const fileName = 'error-log.txt';
      await Filesystem.appendFile({
        path: fileName,
        data: errorDetails + '\n',
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      });
      console.log('Error saved to file');
    } catch (e) {
      console.error('Failed to save error to file', e);
    }
  }
}
