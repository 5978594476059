import { Validators } from '@angular/forms';
import {
  UnifiedInput,
  ControlType,
} from 'src/app/shared/components/unified-custom-input/unified-input.types';

export const addAccountDetailsInputConfig: UnifiedInput[] = [
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'User ID',
    label: 'User ID',
    value: '',
    detail: false,
    name: 'userName',
    showNone: true,
    validator: 'userId',
    required: true
  },
  {
    controlType: ControlType.TEXT,
    type: 'tel',
    placeholder: 'User Phone',
    label: 'User Phone',
    value: '',
    detail: false,
    name: 'userPhone',
    showNone: true,
    validator: 'userPhone',
    required: true
  },
  {
    controlType: ControlType.TEXT,
    type: 'email',
    placeholder: 'User Email',
    label: 'User Email',
    value: '',
    detail: false,
    name: 'userEmail',
    showNone: true,
    helpText: 'This email address is the primary contact for your corporate Account, and may not be the same as your admin user accounts',
    validator: 'userEmail',
    required: true
  },
  {
    controlType: ControlType.TEXT,
    type: 'email',
    placeholder: 'User Title',
    label: 'User Title',
    value: '',
    detail: false,
    name: 'userTitle',
    showNone: true
  }
];
