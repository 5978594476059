import { Injectable } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AuthService } from './auth.service';
import { InputEnum } from 'src/app/shared/enums/input-field.enum';
import { RegexConfigModel } from 'src/app/shared/models/regex.model';

export interface InputValidator {
  type: InputEnum;
  validators: ValidatorFn[];
  config: RegexConfigModel;
}

@Injectable({
  providedIn: 'root',
})
export class InputConfigService {
  constructor(private authService: AuthService) { }

  /** retrieve single input field by config name */
  getValidator(type: InputEnum): InputValidator | null {
    const config = this.authService.getLoginData.validationConfigList.find(
      (config) => config.fieldName === type
    );
    return config
      ? {
        type,
        validators: [
          Validators.required,
          Validators.minLength(config.minLength),
          Validators.maxLength(config.maxLength),
          Validators.pattern(config.pattern),
        ],
        config,
      }
      : null;
  }

  getValidatorForPrompt(inputConfig: any, data: any = {}) {
    let validators = null;
    const validator = inputConfig.validator;
    if (inputConfig.required) {
      validators = [Validators.required];
    } else if (inputConfig.atleastOne?.length) {
      let isRequired = [];
      for (const item of inputConfig.atleastOne) {
        if (!data[item]) {
          isRequired.push(item);
        }
      }
      if (isRequired.length === inputConfig.atleastOne.length) {
        validators = [Validators.required];
      }
    }
    if (!validator) {
      return validators;
    }
    const configObj: any = this.authService.getLoginData.validationConfigList.find((item: any) => item[validator]);
    const config = configObj && configObj[validator];
    if (config) {
      const validations = [Validators.minLength(config.minlength), Validators.maxLength(config.maxlength), Validators.pattern(config.pattern)];
      validators = validators?.length ? validators.concat(validations) : validations;
    }
    return validators;
  }

  getValidationMessage(inputConfig: any) {
    let message = '';
    const validator = inputConfig?.validator;
    if (!validator) {
      return message;
    }
    const configObj: any = this.authService.getLoginData.validationConfigList.find((item: any) => item[validator]);
    const config = configObj && configObj[validator];
    if (config) {
      message = config.errorMessage;
    }
    return message;
  }

  /** retrieve array of input field  by config name array
   * array length must be one
   */
  getValidatorList(types: InputEnum[]): InputValidator[] {
    const validators: InputValidator[] = [];
    const list = this.authService.getLoginData.validationConfigList;
    types.forEach((type) => {
      const config = list.find(
        (config: RegexConfigModel) => config.fieldName === type
      );
      if (config) {
        validators.push({
          type: type,
          validators: [
            Validators.required,
            Validators.minLength(config?.minLength),
            Validators.maxLength(config?.maxLength),
            Validators.pattern(config?.pattern),
          ],
          config,
        });
      }
    });
    return validators;
  }

  atLeastOneValidator(controlNames: string[]) {
    return (formGroup: FormGroup) => {
      const controls = controlNames.map(name => formGroup.get(name));
      const hasAtLeastOne = controls.some(control => control?.value && (typeof control.value !== 'string' || (typeof control.value === 'string' && control.value.trim() !== '')));
      return hasAtLeastOne ? null : { atLeastOneRequired: true };
    };
  }
}
