import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { AuthRequestModel } from 'src/app/shared/models/auth-request.model';
import { RouterEnum } from 'src/app/shared/enums/router.enum';
import { LoginModel } from 'src/app/shared/models/login.model';
import { LoginService } from '../../services/login.service';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Device } from '@capacitor/device';
import { TEMPORARY_PASSWORD, TENANT } from 'src/app/shared/config/app.constant';
import { DashboardService } from 'src/app/features/dashboard/services/dashboard.service';
import { PushNotificationService } from 'src/app/core/services/push-notification.service';

@Component({
  standalone: true,
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  imports: [CommonModule, FormsModule, IonicModule, RouterLink, ReactiveFormsModule],
})
export class LoginPage implements OnInit {
  appVersion = '';
  submitted = false;
  loginForm: FormGroup | any;
  contactUs = '';
  tenant = TENANT;
  passwordType = 'password';
  passwordIcon = 'eye-off';

  constructor(
    private router: Router,
    private loginService: LoginService,
    private uiUtilityService: UiUtilityService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dashboardService: DashboardService,
    private pushNotificationService: PushNotificationService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(() => {
      this.buildForm();
    });
    this.getDeviceInfo();
    if (this.uiUtilityService.isNativePlatform()) {
      this.contactUs = `${TENANT} Mobile App is designed to work with ${TENANT}'s customer solutions. If you do not know your account details, please contact your administrator.`;
    } else {
      this.contactUs = `${TENANT} Web App is designed to work with ${TENANT}'s customer solutions. If you do not know your account details, please contact your administrator.`;
    }
  }

  async getDeviceInfo() {
    const deviceInfo = await Device.getInfo();
    this.appVersion = this.uiUtilityService.getVersion();
    // this.appVersion = '2.3.4'; // deviceInfo.appVersion ||
  }

  clearData() {
    this.dashboardService.dashboardData = [];
  }

  ionViewWillEnter() {
    this.clearData();
    const data = this.storageService.getLocalStorage(StorageEnum.loginCredentials, true) ||
      new AuthRequestModel();
    if (this.loginForm) {
      this.f.userid.setValue(data.userid);
    }
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      userid: [null, [Validators.required]],
      password: ["", [Validators.required]]
    });
  }

  onLogIn() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.loginService.authenticate(this.loginForm.value).subscribe((response: ApiResponseModel<LoginModel>) => {
      if (response.success) {
        this.storageService.setLocalStorage(StorageEnum.authToken, response.data?.accessToken);
        this.storageService.setLocalStorage(StorageEnum.refreshToken, response.data?.refreshToken);
        this.storageService.setLocalStorage(StorageEnum.cst, response.data?.cst);
        this.storageService.setLocalStorage(StorageEnum.loginCredentials, { userid: response.data?.userName }, true);
        if (response.data?.loginResponse?.wearerAlertsMetadata) {
          for (const alert of response.data.loginResponse.wearerAlertsMetadata) {
            for (const alert1 of alert.alerts) {
              for (const dsc of alert1.datasetcsv) {
                if (alert.key === 'bedHeight' && dsc.key !== 'NA') {
                  dsc.displayName = `${dsc.displayName}\"`;
                } else if (alert.key === 'bedAngle' && dsc.key !== 'NA') {
                  dsc.displayName = `${dsc.displayName}\xB0`;
                }
              }
              alert1.dataset = alert1.datasetcsv.reduce((acc: any, cur: any) => ({
                ...acc,
                [cur.key]: cur.displayName
              }), {});
            }
          }
        }
        const { emailId, ...rest } = response.data?.loginResponse;
        this.storageService.setLocalStorage(StorageEnum.loginData, { ...rest, timeout: response.data?.timeout }, true);
        if (response.data?.loginResponse?.isPsswdChanged === TEMPORARY_PASSWORD) {
          this.router.navigate([RouterEnum.resetPassword], { replaceUrl: true });
        } else {
          this.router.navigate([RouterEnum.dashboard], { replaceUrl: true });
          this.uiUtilityService.updateMenu.next(false);
          setTimeout(() => {
            const pnToken = this.storageService.getLocalStorage(
              StorageEnum.pushNotificationToken
            );
            this.pushNotificationService.saveTokenOnServer(pnToken);
          }, 1500);
        }
      } else {
        const header = 'Authentication failed';
        this.uiUtilityService.showAlert(response.errorMessage || '', header);
      }
    });
  }

  handleKeyEnter(event: any) {
    event.preventDefault();
  }

  onPasswordBlur() {
    this.passwordType = 'password';
    this.passwordIcon = 'eye-off';
  }

  togglePasswordVisibility() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  get f() { return this.loginForm.controls; }
}
