import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule, ModalController } from "@ionic/angular";
import { ControlType } from "../unified-custom-input/unified-input.types";
import { cloneDeep } from 'lodash-es';
import { FilterService } from "src/app/core/services/filter.service";
import { SettingsService } from "src/app/features/settings/services/settings.service";
import { FILTER_SORT_TYPE } from "../../config/app.constant";
import { AuthService } from "src/app/core/services/auth.service";
import { HeaderService } from "src/app/core/services/header.service";

interface FilterDynamicActions {
  getRoomType: string;
  getFloorList: string;
}

@Component({
  standalone: true,
  selector: 'app-unified-filter',
  templateUrl: './unified-filter.component.html',
  styleUrls: ['./unified-filter.component.scss'],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class UnifiedFilterComponent implements OnInit, OnDestroy {
  readonly controlType = ControlType;
  @Input() groups: any[] = [];
  groupsCopy: any[] = [];
  loadingCounter = 0;

  constructor(private modalCtrl: ModalController, private filterService: FilterService, private settingsService: SettingsService, private authService: AuthService, private headerService: HeaderService) { }

  ngOnInit(): void {
    this.groupsCopy = cloneDeep(this.groups);
    for (const group of this.groups) {
      if (!group.fields.length && group.dynamicData?.length) {
        for (const field of group.dynamicData) {
          this.loadingCounter++;
          const action = field.action as keyof FilterDynamicActions;
          this[action](group, field);
        }
      }
      if (!group.fields.length && group.userData?.id === 'wearerGroupDefinition') {
        const loginData: any = this.authService.getLoginData;
        const facilityId = this.headerService.getFacilityId();
        const groups = loginData.facility.find((item: any) => item.facilityId === facilityId)?.wearerGroupDefinition || [];
        group.fields = groups.map((item: any) => ({ property: group.userData.property, value: item[group.userData.value], label: item[group.userData.label] }));
        group.value = group.fields.map((item: any) => item.value);
      }
    }
  }

  getRoomType(group: any, field: any) {
    this.settingsService.getRoomTypeDetails().subscribe((response) => {
      this.loadingCounter--;
      if (response.success) {
        const roomTypes = response.data;
        group.fields = roomTypes.map((item: any) => ({ property: field.property, value: item.roomTypeName, label: item.roomTypeName }));
        group.value = group.fields.map((item: any) => item.value);
      }
    });
  }

  getAssetType(group: any, field: any) {
    this.settingsService.getAssetTypes().subscribe((response) => {
      this.loadingCounter--;
      if (response.success) {
        const roomTypes = response.data;
        group.fields = roomTypes.map((item: any) => ({ property: field.property, value: item.assetTypeId, label: item.assetTypeName }));
        group.value = group.fields.map((item: any) => item.value);
      }
    });
  }

  getFloorList(group: any, field: any) {
    this.loadingCounter--;
    this.settingsService.getFloorListDetails().subscribe((response) => {
      if (response.success) {
        const floors = response.data.floors;
        group.fields = floors.map((item: any) => ({ property: field.property, value: item.floorID, label: item.floorName }));
        group.value = group.fields.map((item: any) => item.value);
        if (field.isUnassigned) {
          group.fields.push({ property: field.property, value: null, label: 'Unassigned' });
          group.value.push(null);
        }
      }
    });
  }

  onChange(event: any, group: any, field?: any) {
    let val = event.target.value;
    if (group.controlType === ControlType.TOGGLE) {
      if (event.detail.checked) {
        group.value.push(field.value);
      } else {
        group.value = group.value.filter((item: any) => item !== field.value);
      }
    } else {
      group.value = val;
    }
    if (group.type !== FILTER_SORT_TYPE) {
      group.filterApplied = true;
    }
  }

  save() {
    const filters = this.filterService.getFilterData(this.groups);
    return this.modalCtrl.dismiss(filters, 'confirm');
  }

  reset() {
    return this.modalCtrl.dismiss('', 'reset');
  }

  close() {
    return this.modalCtrl.dismiss(this.groupsCopy, 'cancel');
  }

  ngOnDestroy(): void {

  }
}