import {
  UnifiedInput,
  ControlType,
} from 'src/app/shared/components/unified-custom-input/unified-input.types';

export const assetDetailsInputConfig: UnifiedInput[] = [
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Name',
    label: 'Name',
    value: '',
    detail: false,
    name: 'assetName',
    validator: 'abstractName',
    required: true
  },
  {
    controlType: ControlType.TEXT,
    type: 'text',
    placeholder: 'Asset ID',
    label: 'Asset ID',
    value: '',
    detail: false,
    name: 'assetLocalId',
    showNone: true,
    validator: 'localId',
  }
];
