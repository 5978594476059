import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { BedService } from '../../services/bed.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BedModel } from 'src/app/shared/models/bed-modal';
import { BedDetailsPage } from '../bed-details/bed-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  standalone: true,
  selector: 'app-bed-list',
  templateUrl: './bed-list.page.html',
  styleUrls: ['./bed-list.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, AccessControlDirective],
})
export class BedListPage {
  bedList: BedModel[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private bedService: BedService,
    private headerService: HeaderService,
    private uiUtilityService: UiUtilityService,
    private nav: IonNav,
    private authService: AuthService
  ) { }

  ionViewWillEnter() {
    this.subscriptions.push(
      this.headerService
        .getFacility()
        .subscribe((facility: FacilityModel) => this.onFacilityChange(facility))
    );
    this.getBedList();
  }

  async getBedList() {
    await this.uiUtilityService.showLoader();
    this.bedService.getBedList().subscribe((response: ApiResponseModel<BedModel[]>) => {
      if (response && response.success && response.data) {
        const formatted = response.data?.map((item: any) => {
          item.appResidentName = this.uiUtilityService.getEntityShortDisplayName(item);
          return item;
        });
        this.bedList = this.uiUtilityService.sortList(formatted, 'bedName', 1);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateToDetailsPage(bed: BedModel) {
    if (this.authService.isFeatureEnabled('getBedDetail')) {
      this.nav.push(BedDetailsPage, { bedId: bed.id, mode: 'detail' });
    }
  }

  onFacilityChange(facility: FacilityModel) {
    const facilityId = this.headerService.getFacilityId();
    if (facility.facilityId !== facilityId) {
      this.getBedList();
    }
  }

  navigateToAddBed() {
    this.nav.push(BedDetailsPage);
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
