import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { HeaderService } from 'src/app/core/services/header.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { FacilityModel } from 'src/app/shared/models/login-response.model';
import { BedService } from '../../services/bed.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BedModel } from 'src/app/shared/models/bed-modal';
import { BedDetailsPage } from '../bed-details/bed-details.page';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { AuthService } from 'src/app/core/services/auth.service';
import { FormsModule } from '@angular/forms';
import { FilterService } from 'src/app/core/services/filter.service';
import cloneDeep from 'lodash-es/cloneDeep';
import { bedFilterData } from 'src/app/shared/data/filter.data';
import { SubHeaderComponent } from 'src/app/shared/components/sub-header/sub-header.component';

@Component({
  standalone: true,
  selector: 'app-bed-list',
  templateUrl: './bed-list.page.html',
  styleUrls: ['./bed-list.page.scss'],
  imports: [IonicModule, CommonModule, FormsModule, ScrollingModule, RouterModule, SubHeaderComponent, AccessControlDirective],
})
export class BedListPage {
  bedListCopy: BedModel[] = [];
  bedList: BedModel[] = [];
  searchBedList: BedModel[] = [];
  subscriptions: Subscription[] = [];
  groups = cloneDeep(bedFilterData);
  filters: any = {};
  searchQuery = '';
  isBedSlotEnabled = false;

  constructor(
    private bedService: BedService,
    private headerService: HeaderService,
    private uiUtilityService: UiUtilityService,
    private nav: IonNav,
    private authService: AuthService,
    private filterService: FilterService
  ) { }

  ionViewWillEnter() {
    this.isBedSlotEnabled = this.headerService.isBedSlotEnabled;
    this.subscriptions.push(
      this.headerService
        .getFacility()
        .subscribe((facility: FacilityModel) => this.onFacilityChange(facility))
    );
    this.getBedList();
    this.sortBedList();
  }

  sortBedList() {
    const result = this.filterService.getFilterData(this.groups);
    this.filters = this.filterService.setSortFilter(result);
  }

  didSortBedList() {
    const result = this.filterService.filteredData(
      this.bedListCopy,
      this.filters
    );
    this.bedList = result;
    this.searchBedList = result;
  }

  async getBedList() {
    await this.uiUtilityService.showLoader();
    this.bedService.getBedList().subscribe((response: ApiResponseModel<BedModel[]>) => {
      if (response && response.success && response.data) {
        this.bedListCopy = response.data?.map((item: any) => {
          const appResidentName = this.uiUtilityService.getEntityShortDisplayName(item);
          if (appResidentName) {
            item.appResidentName = appResidentName;
          } else if (item.roomName) {
            item.appResidentNamePlc = 'No resident';
          }
          if (item.roomName) {
            item.isAssigned = 'A';
            item.appRoomName = `${item.roomName}${item.slotName && this.isBedSlotEnabled ? `-${item.slotName}` : ''}`;
          } else {
            item.isAssigned = 'UA';
          }
          return item;
        });
        this.didSortBedList();
        // const result = this.uiUtilityService.sortList(formatted, 'bedName', 1);
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  navigateToDetailsPage(bed: BedModel) {
    if (this.authService.isFeatureEnabled('getBedDetail')) {
      this.nav.push(BedDetailsPage, { bedId: bed.id, mode: 'detail' });
    }
  }

  onFacilityChange(facility: FacilityModel) {
    const facilityId = this.headerService.getFacilityId();
    if (facility.facilityId !== facilityId) {
      this.getBedList();
    }
  }

  navigateToAddBed() {
    this.nav.push(BedDetailsPage);
  }

  onFilterApply(data: any) {
    this.searchQuery = '';
    this.filters = this.filterService.setSortFilter(data);
    this.didSortBedList();
  }

  onFilterCancel(data: any) {
    this.groups = data;
  }

  onFilterReset() {
    this.searchQuery = '';
    this.groups = cloneDeep(bedFilterData);
    this.sortBedList();
    this.didSortBedList();
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  handleChange(event: any) {
    this.bedList = this.searchBedList;
    this.searchQuery = event.target.value;
    if (this.searchQuery && this.searchQuery.trim() !== '') {
      this.bedList = this.bedList.filter((term: any) => {
        const query = this.searchQuery.trim().toLowerCase();
        return term.bedName?.toLowerCase().indexOf(query) !== -1 || term.appResidentName?.toLowerCase().indexOf(query) > -1 || term.appRoomName?.toLowerCase().indexOf(query) > -1 || term.appResidentNamePlc?.toLowerCase().indexOf(query) > -1;
      });
    } else if (this.searchQuery === '') {
      this.bedList = this.searchBedList;
    }
  }

  ionViewWillLeave(): void {
    this.subscriptions.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
