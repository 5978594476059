import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiResponseModel } from 'src/app/core/services/api/api-response.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { FacilityModel } from 'src/app/shared/models/facility.model';
import { FacilityService } from './services/facility.service';
import { IonNav, IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AddFacilityPage } from './add-facility/add-facility.page';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { HeaderService } from 'src/app/core/services/header.service';
@Component({
  standalone: true,
  selector: 'app-facility-management',
  templateUrl: './facility-management.page.html',
  styleUrls: ['./facility-management.page.scss'],
  imports: [IonicModule, CommonModule, ScrollingModule, RouterModule, AccessControlDirective],
})
export class FacilityManagementPage {
  facilityList: FacilityModel[] = [];
  EndPoints = EndPointsEnum;
  loading = false;

  constructor(
    private facilityService: FacilityService,
    private storageService: StorageService,
    private uiUtilityService: UiUtilityService,
    private ionNav: IonNav,
    private headerService: HeaderService
  ) { }

  ionViewWillEnter() {
    this.loading = true;
    this.facilityService
      .getFacilityList()
      .subscribe((response: ApiResponseModel<any>) => {
        this.loading = false;
        if (response && response.success) {
          this.facilityList = this.uiUtilityService.sortList(response.data, 'facilityName');
        } else {
          const header = 'Error';
          this.uiUtilityService.showAlert(response.errorMessage!, header);
        }
      });
  }

  ionViewWillLeave(): void { }

  navigateToAddFacility() {
    this.ionNav.push(AddFacilityPage);
  }

  navigateToDetailsPage(facility: FacilityModel) {
    this.ionNav.push(AddFacilityPage, { facilityId: facility.facilityId });
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }
}
