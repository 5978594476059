import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { StorageService } from 'src/app/core/services/storage.service';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import {
  tempUnit,
  tempUnitIds,
} from 'src/app/shared/config/temp-unit.constant';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { AlertButtonModel } from 'src/app/shared/models/alertButtonModel';
import { CorporateDetailsModel } from 'src/app/shared/models/corporate-details.model';
import { SettingsService } from '../services/settings.service';
import { AlertController, IonicModule } from '@ionic/angular';
import { InputErrorComponent } from 'src/app/shared/components/input-error/input-error.component';
import { ValidationConfig } from 'src/app/shared/models/login-response.model';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';
import { AccessControlDirective } from 'src/app/shared/directives/access-control.directive';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { InputConfigService } from 'src/app/core/services/input-config.service';
import { ControlType, FormError } from 'src/app/shared/components/unified-custom-input/unified-input.types';
@Component({
  standalone: true,
  selector: 'app-corporate-account',
  templateUrl: './corporate-account.page.html',
  styleUrls: ['./corporate-account.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    InputErrorComponent,
    UnifiedHeaderComponent,
    AccessControlDirective
  ],
})
export class CorporateAccountPage implements OnInit {
  corporateFormData: CorporateDetailsModel = new CorporateDetailsModel();
  wearerGroups: { [key: number]: string } = {
    1: 'Wearer',
    2: 'Caregiver',
    3: 'Other',
    4: 'Management',
  };
  wearerGroupIds: string[] = Object.keys(this.wearerGroups);
  wearerNameFormat: { [key: number]: string } = {
    1: 'NAME',
    2: 'ID',
  };
  wearerNameFormatIds: string[] = Object.keys(this.wearerNameFormat);
  tempUnit = tempUnit;
  tempUnitIds = tempUnitIds;
  editModeEnable: boolean = false;
  corporateForm: FormGroup = this.fb.group({
    corporateId: [''],
    corporateName: [''],
    corporateOwnerName: [''],
    corporatePhone: [''],
    corporateOwnerTitle: [''],
    corporateEmail: [''],
    tempUnitId: [''],
  });
  formConfig = {
    abstractName: {} as ValidationConfig,
    ownerName: {} as ValidationConfig,
    corpPhone: {} as ValidationConfig,
    email: {} as ValidationConfig,
  };
  corpId = null;
  title = '';
  isSaveDisabled: boolean = true;
  EndPoints = EndPointsEnum;
  fieldConfig: any = {
    corporateName: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Corporate Name',
      label: 'Corporate Name',
      value: '',
      detail: false,
      name: 'corporateName',
      showNone: true,
      validator: 'abstractName',
      required: true
    },
    corporatePhone: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Corporate Phone',
      label: 'Corporate Phone',
      value: '',
      detail: false,
      name: 'corporatePhone',
      showNone: true,
      validator: 'userPhone',
      required: true
    },
    corporateEmail: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Corporate Email',
      label: 'Corporate Email',
      value: '',
      detail: false,
      name: 'corporateEmail',
      showNone: true,
      validator: 'userEmail',
      required: true
    },
    corporateOwnerName: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Name',
      label: 'Name',
      value: '',
      detail: false,
      name: 'corporateOwnerName',
      showNone: true,
      validator: 'humanName',
      required: true
    },
    corporateOwnerTitle: {
      controlType: ControlType.TEXT,
      type: 'text',
      placeholder: 'Title',
      label: 'Title',
      value: '',
      detail: false,
      name: 'corporateOwnerTitle',
      showNone: true,
      validator: 'abstractName'
    }
  };

  constructor(
    private settingsService: SettingsService,
    private uiUtilityService: UiUtilityService,
    private storageService: StorageService,
    private fb: FormBuilder,
    private alertController: AlertController,
    private inputConfigService: InputConfigService
  ) { }

  ngOnInit() {
    this.corporateForm = this.fb.group({
      corporateId: [null, Validators.required],
      corporateName: [null, Validators.required],
      corporateOwnerName: [null, Validators.required],
      corporatePhone: [null, Validators.required],
      corporateOwnerTitle: [null, Validators.required],
      corporateEmail: [null, Validators.required],
      tempUnitId: [null, Validators.required],
    });
    this.getDetails();
    this.corporateForm.valueChanges.subscribe((value) => {
      if (this.corporateForm.valid) {
        this.isSaveDisabled = false;
      } else {
        this.isSaveDisabled = true;
      }
    });
  }

  getDetails() {
    this.settingsService.getCorpAccountDetails().subscribe((response) => {
      if (response.success) {
        this.corporateFormData = response.data!;
        this.corporateForm.patchValue(this.corporateFormData);
        this.corporateForm.updateValueAndValidity();
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage!, header);
      }
    });
  }

  async saveDetails() {
    this.settingsService
      .editCorpAccountDetails(this.corporateForm.value)
      .subscribe((response) => {
        const loginData = this.storageService.getLocalStorage(
          StorageEnum.loginData,
          true
        );
        loginData.tempUnit = response.data.tempUnitId;
        this.storageService.setLocalStorage(
          StorageEnum.loginData,
          { ...loginData },
          true
        );
      });
    const header = 'Account updated';
    const message =
      'Other devices will not see the updated temperature change, if any, until you sign out and back in on those devices.';
    const buttons = [
      {
        text: 'ok',
        handler: () => {
          this.editModeEnable = !this.editModeEnable;
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  get(controlName: string): AbstractControl {
    return this.corporateForm.get(controlName)!;
  }

  backClick() {
    if (this.editModeEnable) {
      if (!this.corporateForm?.pristine) {
        const header = 'Confirm';
        const message =
          'It looks like you have been editing something. ' +
          'If you leave before saving, your changes will be lost.';
        this.showAlert(header, message);
      } else {
        this.editModeEnable = !this.editModeEnable;
      }
    } else {
      this.navigateBack();
    }
  }

  async showAlert(header: string, message: string) {
    const buttons: AlertButtonModel[] = [
      {
        text: 'cancel',
      },
      {
        text: 'ok',
        handler: () => {
          this.editModeEnable = !this.editModeEnable;
          this.corporateForm.patchValue(this.corporateFormData);
        },
      },
    ];
    this.uiUtilityService.showAlert(message, header, buttons);
  }

  navigateBack() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal.dismiss();
    }
  }

  editDetails() {
    this.editModeEnable = !this.editModeEnable;
  }



  async presentAlert(
    operation: any
  ) {
    const config = this.fieldConfig[operation];
    const initValue = this.corporateForm?.get(operation)?.value;
    const formControl = new FormControl(initValue, {
      validators: this.inputConfigService.getValidatorForPrompt(this.fieldConfig[operation])
    });
    const alert = await this.alertController.create({
      header: config.label,
      buttons: [
        {
          text: 'Cancel',
          role: 'dismiss',
        },
        {
          text: 'Ok',
          handler: (data: any) => {
            if (!data) return false;
            let errorMsg = '';
            const value = data[0];
            formControl.patchValue(value);
            const errorType = formControl.errors as FormError;
            const errMsg = this.inputConfigService.getValidationMessage(config);
            if (errorType) {
              if (errorType.required) {
                errorMsg = `Please ${config.controlType == 'text' ? 'Enter' : 'select'} ${config.title || config.label}.`;
              } else if (
                errorType.maxlength ||
                errorType.minlength ||
                errorType.pattern
              ) {
                errorMsg = `Please enter valid ${config.title || config.label}${errMsg ? `, ${errMsg}.` : '.'}`
              }
            }
            this.corporateForm?.markAsDirty();
            if (errorMsg) {
              alert.message = errorMsg;
              return false;
            } else {
              return this.corporateForm.patchValue({ [operation]: value, });;
            }
          },
        },
      ],
      backdropDismiss: false,
      inputs: [
        {
          placeholder: config.placeholder,
          value: initValue,
        },
      ],
      cssClass: `notable-state-alert unified-custom-input-alert`,
      animated: false
    });
    await alert.present();
  }
}
