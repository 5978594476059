import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, from, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UiUtilityService } from '../ui-utility.service';
import { finalize } from 'rxjs/operators';
import { ApiResponseModel } from './api-response.model';
import { LoggerService } from '../log.service';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class DataService<T> {
  readonly headers: any = { headers: { 'Content-Type': 'application/json' } };
  // private readonly apiURL: string = 'assets/data/data1.json';

  constructor(
    public http: HttpClient,
    private uiUtilityService: UiUtilityService,
    private loggerService: LoggerService,
    private platform: Platform
  ) { }

  handleError = (error: HttpErrorResponse) => {
    const errorLog = `${error.url}-${error.status}-${error.statusText}`;
    this.loggerService.saveErrorToFile(errorLog);
    this.uiUtilityService.showAlert('An error occurred while processing your request.', 'Error');
    return throwError(error);
  }

  isMobile(): boolean {
    return false; //this.platform.is('hybrid');
  }

  private async httpNative<T>(url: string, props?: any): Promise<T> {
    const options: HttpOptions = {
      url,
      ...props
    };
    const response: HttpResponse = await CapacitorHttp.request(options);
    return response.data as T;
  }

  getData<T>(url: string, params = {}): Observable<ApiResponseModel<T>> {
    const fullUrl: string = environment.baseUrl + url;
    if (this.isMobile()) {
      return from(this.httpNative<ApiResponseModel<T>>(fullUrl, { method: 'GET', params })).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    } else {
      return this.http.get<ApiResponseModel<T>>(fullUrl, { params }).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    }
  }

  getBlob(url: string, params = {}): Observable<any> {
    const fullUrl: string = environment.baseUrl + url;
    if (this.isMobile()) {
      return from(this.httpNative(fullUrl, { method: 'GET', params, responseType: 'blob' })).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    } else {
      return this.http.get(fullUrl, { ...params, responseType: 'blob' }).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    }
  }

  post<T>(
    url: string,
    payload: any,
    headers: any = { headers: { 'Content-Type': 'application/json' } }
  ): Observable<any> {
    const fullUrl: string = environment.baseUrl + url;
    if (this.isMobile()) {
      return from(this.httpNative<T>(fullUrl, { method: 'POST', data: payload, headers: headers.headers })).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    } else {
      return this.http.post<T>(fullUrl, payload, headers)
        .pipe(catchError(this.handleError), finalize(() => this.uiUtilityService.closeLoader()));
    }
  }

  put<T>(url: string, payload: any): Observable<any> {
    const headers: any = { headers: { 'Content-Type': 'application/json' } };
    const fullUrl: string = environment.baseUrl + url;
    if (this.isMobile()) {
      return from(this.httpNative<T>(fullUrl, { method: 'PUT', data: payload, headers: headers.headers })).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    } else {
      return this.http
        .put<T>(fullUrl, payload, headers)
        .pipe(catchError(this.handleError), finalize(() => this.uiUtilityService.closeLoader()));
    }
  }

  delete<T>(
    url: string,
    payload: any
  ): Observable<any> {
    const headers: any = {
      headers: { 'Content-Type': 'application/json' },
      body: payload,
    };
    const fullUrl: string = environment.baseUrl + url;
    if (this.isMobile()) {
      return from(this.httpNative<T>(fullUrl, { method: 'DELETE', headers: headers.headers })).pipe(
        catchError(this.handleError),
        finalize(() => this.uiUtilityService.closeLoader())
      );
    } else {
      return this.http
        .delete<T>(fullUrl, headers)
        .pipe(catchError(this.handleError), finalize(() => this.uiUtilityService.closeLoader()));
    }
  }
}
