import { CSP_NONCE, ErrorHandler, enableProdMode, importProvidersFrom } from '@angular/core';
import { HammerModule, bootstrapApplication, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import {
  RouteReuseStrategy,
  provideRouter,
  withComponentInputBinding,
} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { HttpTokenInterceptor } from './app/core/services/api/http-token.intercepter';
import { HttpErrorInterceptor } from './app/core/errors/http-error.interceptor';
import * as Hammer from 'hammerjs';
import { LoggerService } from './app/core/services/log.service';
import { setNonce } from '@ionic/core/loader';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

if (environment.production) {
  enableProdMode();
}

const didBootstrap = () => {
  bootstrapApplication(AppComponent, {
    providers: [
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      importProvidersFrom(IonicModule.forRoot({ innerHTMLTemplatesEnabled: true })),
      importProvidersFrom(BrowserAnimationsModule),
      importProvidersFrom(NoopAnimationsModule),
      provideRouter(routes, withComponentInputBinding()),
      importProvidersFrom(
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient],
          },
          defaultLanguage: 'en',
        })
      ),
      provideHttpClient(withInterceptorsFromDi()),
      importProvidersFrom(HammerModule),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpTokenInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true,
      },
      {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: MyHammerConfig,
      },
      { provide: ErrorHandler, useClass: LoggerService },
      provideAnimations(),
      {
        provide: CSP_NONCE,
        useValue: (globalThis as any).cspNonceValue
      },
    ],
  });
};

const willBootstrap = () => {
  const styleNonce = document.querySelector('meta[name="ondo-nonce"]')?.getAttribute('content') || '';
  console.debug('Meta Tag Value:', styleNonce);
  // const styleSrcNonceRegex = /style-src [^;]*'nonce-([^']+)'/;
  // const match = nonceVal.match(styleSrcNonceRegex);
  // const styleNonce = match ? match[1] : '';
  setNonce(styleNonce);
  (globalThis as any).cspNonceValue = styleNonce;
  didBootstrap();
};
willBootstrap();

defineCustomElements(window);
