export class FacilityModel {
  facilityId = '';
  facilityName = '';
  facilityPhone? = '';
  facilityEmail? = '';
  facilityRepresentativeName? = '';
  facilityResponseTitle? = '';
  corporateId? = '1';
  timeZone = 'US/Central';
  isDisabled = false;
  enableCaspar = false;
}
