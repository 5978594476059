import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule, MenuController } from '@ionic/angular';
import { UiUtilityService } from 'src/app/core/services/ui-utility.service';
import { CommonModule } from '@angular/common';
import { NOTIFICATION_SETTINGS } from 'src/app/shared/config/app.constant';
import { environment } from 'src/environments/environment';
import { EndPointsEnum } from 'src/app/shared/enums/end-points';
import { DataService } from 'src/app/core/services/api/data.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { StorageEnum } from 'src/app/shared/enums/storage.enum';
import { AuthService } from 'src/app/core/services/auth.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UnifiedHeaderComponent } from 'src/app/shared/components/unified-header/unified-header.component';

@Component({
  standalone: true,
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.page.html',
  styleUrls: ['./notification-settings.page.scss'],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UnifiedHeaderComponent
  ],
})
export class NotificationSettingsPage implements OnInit, OnDestroy {
  notificationForm: FormGroup | null = null;
  notificationSettingArr: any[] = NOTIFICATION_SETTINGS;
  isDisable = false;
  isSaveDisabled = true;
  destroyRef = inject(DestroyRef);

  constructor(
    private uiUtilityService: UiUtilityService,
    private dataService: DataService<any>,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private menuCtrl: MenuController,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getNotificationPreferences();
    const { userRole } = this.authService.getLoginData;
    const disabledRoles = ['1'];
    if (disabledRoles.indexOf(userRole) !== -1) {
      this.isDisable = true;
    }
  }

  getNotificationPreferences() {
    const userId = this.storageService.getLocalStorage(StorageEnum.loginData, true)?.userId
    const url = `${environment.apiUrl.user}${userId}/${EndPointsEnum.getNotificationPreferences}`;
    this.dataService.getData(url).subscribe((response: any) => {
      if (response && response.success) {
        this.createForm(response.data);
        this.subscribeForm();
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage || '', header);
      }
    });
  }

  createForm(settingsData: any = {}) {
    let temp: any = {};
    if (this.notificationSettingArr?.length) {
      for (const setting of this.notificationSettingArr) {
        temp[setting.key] = [settingsData[setting.key] || false]
      }
    }
    this.notificationForm = this.formBuilder.group({
      ...temp
    });
  }

  subscribeForm() {
    this.notificationForm?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.notificationForm) {
          this.isSaveDisabled =
            this.notificationForm.invalid || !this.notificationForm.dirty;
        }
      });
  }

  saveSettings() {
    const url = `${environment.apiUrl.user}${EndPointsEnum.updateNotificationPreferences}`;
    const payload = this.notificationForm?.getRawValue();
    payload.userId = this.storageService.getLocalStorage(StorageEnum.loginData, true)?.userId;
    this.dataService.put(url, payload).subscribe((response: any) => {
      if (response && response.success) {
        this.uiUtilityService.showToast(
          'The notification settings saved successfully.'
        );
        this.dismiss();
      } else {
        const header = 'Error';
        this.uiUtilityService.showAlert(response.errorMessage || '', header);
      }
    });
  }

  dismiss() {
    if (this.uiUtilityService.pageModal) {
      this.uiUtilityService.pageModal
        .dismiss()
        .then(() => this.menuCtrl.close());
      this.uiUtilityService.pageModal = null;
    }
  }

  onInfo(msg: string) {
    this.uiUtilityService.showAlert(msg, 'Info');
  }

  ngOnDestroy() {
  }
}
