export const SETTINGS_ITEMS = [{
  menuId: 'wearer-list',
  components: ['app-wearer-list', 'app-wearer-details']
}, {
  menuId: 'asset-list',
  components: ['app-asset-list', 'app-asset-details']
}, {
  menuId: 'bed-list',
  components: ['app-bed-list', 'app-bed-details']
}, {
  menuId: 'facility-management',
  components: ['app-facility-management', 'app-add-facility',]
}, {
  menuId: 'user-accounts',
  components: ['app-user-accounts', 'app-add-account',]
}, {
  menuId: 'bridge-list',
  components: ['app-bridge-list', 'app-bridge-details',]
}, {
  menuId: 'room-list',
  components: ['app-room-list', 'app-create-room', 'app-room-details']
}, {
  menuId: 'notification-settings',
  components: ['app-notification-settings']
}];


// {
//   menuId: 'band-intro',
//   components: ['app-p', 'app-band-scan', 'app-band-completion', 'app-assign-device']
// }