<ion-header>
  <ion-toolbar mode="ios">
    <ion-buttons slot="start">
      <ion-button class="header-modal-close-btn" (click)="dismiss()">Close</ion-button>
      <ion-back-button class="header-modal-back-btn" defaultHref="/dashboard" (click)="dismiss()"></ion-back-button>
    </ion-buttons>
    <ion-title mode="ios">Bed Management</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="navigateToAddBed()" [nAccess]="'postBed'">Add</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="bedList?.length">
    <ion-item *ngFor="let bed of bedList" (click)="navigateToDetailsPage(bed)" detail class="list-with-name">
      <ion-label>
        <h3>{{bed.bedName}}</h3>
      </ion-label>
      <ion-label slot="end" class="no-margin-right end-name-max" *ngIf="bed.roomName || bed.appResidentName">
        <h3>
          <span [ngClass]="{'offline-text': !bed.appResidentName}">{{bed.appResidentName || 'No resident'}}</span><span
            class="list-name-separator">||</span><span class="end-name-right"
            [ngClass]="{'offline-text': !bed.roomName}">{{bed.roomName
            || 'No room'}}</span>
        </h3>
      </ion-label>
    </ion-item>
  </div>
  <div class="no-bed" *ngIf="!bedList?.length">
    No beds found in the selected facility.
  </div>
</ion-content>